import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import { LoadingAnimation } from '../../../components/LoadingAnimation/LoadingAnimation'
import { urls } from '../../../config/constants'
import withRedux from '../../../hoc/withRedux'
import { userService } from '../../../services/UserService'

const LogoutView = () => {
  const navigate = useNavigate()

  const signOut = async () => {
    await userService.signOut()
    navigate(urls.HOME_VIEW)
  }

  useEffect(() => {
    signOut()
  }, [])

  return (
    <ElementContainer>
      <LoadingAnimation />
    </ElementContainer>
  )
}

export default withRedux(LogoutView)
