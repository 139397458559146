import '../../FormElements/_dropdown.scss'

import { SubmitHandler, useForm } from 'react-hook-form'

import { ReactComponent as UpDownArrowSvg } from '../../../assets/svg/up-and-down-arrow.svg'
import { getInputTypeByConfiguration } from '../../../modules/getInputTypeByConfiguration'
import { ApiPayloadNewConnection, ApiPayloadNewConnectionValue } from '../../../types/APITypes/APIPayloadNewConnection'
import { NewProviderConnectionProps } from '../../../types/NewProviderConnectionProps'
import { KlarlyFormInput } from '../../FormElements/ReactHookForm/KlarlyFormInput'
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton'

const NewDatabaseConnection = ({ onSave, provider }: NewProviderConnectionProps) => {
  const { register, handleSubmit, getValues } = useForm()

  const onFormSubmit: SubmitHandler<any> = (data, event) => {
    event?.preventDefault()
  }

  const onSaveClick = () => {
    const values: ApiPayloadNewConnectionValue[] = provider.setup.map((configEntry) => {
      let value = getValues(configEntry.name)

      if (configEntry.type.name === 'ENUM') {
        value = configEntry.values.find((dropdownOption) => dropdownOption.name === value)
      }

      return {
        configurationKeyId: configEntry.id,
        type: configEntry.type,
        value,
      }
    })

    const payload: ApiPayloadNewConnection = {
      providerId: provider.id,
      values,
    }

    onSave(payload)
  }

  return (
    <form className="existing-connections__form" onSubmit={handleSubmit(onFormSubmit)}>
      <p className="mb-3">
        <strong>{provider.name}</strong>
      </p>

      {provider.setup.map((configuration) => {
        const inputType = getInputTypeByConfiguration(configuration.type.name)

        if (inputType === 'select') {
          return (
            <div key={`form-data-${configuration.id}`} className="form-field__dropdown-wrapper mb-3">
              <select {...register(configuration.name, { required: true })} className="form-field__dropdown">
                {configuration.values.map((connectionType) => (
                  <option value={connectionType.name} key={`connection-type-for-${configuration.id}-value-${connectionType.id}`}>
                    {connectionType.name}
                  </option>
                ))}
              </select>

              <UpDownArrowSvg className="form-field__dropdown-icon" />
            </div>
          )
        }

        return (
          <KlarlyFormInput
            key={`form-data-${configuration.id}`}
            id={configuration.name}
            type={inputType}
            name={configuration.name}
            label={configuration.label}
            register={register}
            rules={{ required: 'You must enter this.' }}
          />
        )
      })}

      <PrimaryButton tiny onClick={onSaveClick}>
        Save New Connection
      </PrimaryButton>
    </form>
  )
}

export default NewDatabaseConnection
