import { ButtonHTMLAttributes } from 'react'
import { useNavigate } from 'react-router-dom'

import { PrimaryButton } from '../PrimaryButton/PrimaryButton'

type OwnProps = {
  path?: string
}

type Props = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

const OnboardingBackButton = ({ path, className, ...rest }: Props) => {
  const navigate = useNavigate()
  const classNames = `button ${className}`

  const onBackButtonClick = () => {
    if (path) {
      navigate(path)
    } else {
      navigate(-1)
    }
  }

  return (
    <PrimaryButton variation="secondary" medium className={classNames} onClick={onBackButtonClick} {...rest}>
      Back
    </PrimaryButton>
  )
}

export default OnboardingBackButton
