import './_reportExamplesView.scss'
import '../../../components/DashboardsOverview/_dashboards-overview.scss'

import { Link } from 'react-router-dom'

import { GetInTouch } from '../../../components/GetInTouch/GetInTouch'
import { HeroSection } from '../../../components/HeroSection/HeroSection'
import { HeroTitle } from '../../../components/Text/HeroTitle/HeroTitle'
import { PageSubtitle } from '../../../components/Text/PageSubtitle/PageSubtitle'
import { EXAMPLE_REPORTS } from '../../../config/constants'
import withRedux from '../../../hoc/withRedux'
import { AppProps } from '../../../types/AppProps'

const ReportExamplesView = ({ settings: { isDarkMode } }: AppProps) => {
  return (
    <>
      <HeroSection>
        <HeroTitle>Ready, set, report!</HeroTitle>
        <PageSubtitle centered>As part of our onboarding process we work with you, to produce the KPIs and dashboards you need.</PageSubtitle>
      </HeroSection>

      <section className="dashboard-list report-example-list">
        {EXAMPLE_REPORTS.map(({ id, title, thumbnail, thumbnailWhite }) => (
          <div className="dashboard-item" key={`report-example-${id}`}>
            <Link to={`/report/${id}`} className="dashboard-item__wrapper" style={{ backgroundImage: `url(${isDarkMode ? thumbnail : thumbnailWhite})` }}>
              <article className="dashboard-item__title-wrapper">
                <h4 className="dashboard-item__title">{title}</h4>
              </article>
            </Link>
          </div>
        ))}
      </section>

      <GetInTouch />
    </>
  )
}

export default withRedux(ReportExamplesView)
