type OwnProps = {
  link: string
  token: string
}
const TableauReport = ({ link, token }: OwnProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <klarly-viz id="klarly-viz" src={link} device="default" toolbar="hidden" token={token} hide-tabs height="950" />
}

export default TableauReport
