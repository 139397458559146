import './_onboarding-button.scss'

import { FunctionComponent, SVGProps } from 'react'

import { OnboardingData } from '../../types/OnboardingData'
import { CopyText16Pt } from '../Text/CopyText16Pt/CopyText16Pt'

type Props = {
  option: OnboardingData
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  isActive: boolean
  onClick: () => void
}

const OnboardingButton = ({ option, icon, isActive, onClick, ...rest }: Props) => {
  const Icon = icon

  return (
    <div className={`card ob ${isActive ? 'ob--active' : ''}`} onClick={onClick} {...rest} role="button">
      <div className="ob-icon__wrapper">
        <Icon className="ob-icon__icon" />
      </div>

      <CopyText16Pt className="card__content ob__label">{option.label}</CopyText16Pt>
    </div>
  )
}

export default OnboardingButton
