import './_getInTouch.scss'

import { FunctionComponent, HTMLAttributes } from 'react'

import { PrimaryButton } from '../PrimaryButton/PrimaryButton'
import { PageSubtitle } from '../Text/PageSubtitle/PageSubtitle'
import { PageTitle } from '../Text/PageTitle/PageTitle'

type OwnProps = {
  onButtonClick?(): void
}

type Props = HTMLAttributes<HTMLDivElement> & OwnProps

export const GetInTouch: FunctionComponent<Props> = ({ onButtonClick }) => (
  <section className="get-in-touch">
    <PageTitle>Get in touch</PageTitle>
    <PageSubtitle className="get-in-touch__subtitle">
      Whether you have a questions about a demo, features, trials, pricing or anything else. Our team is happy to answer all your questions.
    </PageSubtitle>
    <PrimaryButton onClick={onButtonClick}>Get in touch</PrimaryButton>
  </section>
)
