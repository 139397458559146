import './_heroTitle.scss'

import { FunctionComponent, HTMLAttributes } from 'react'

import joinCssClassNames from '../../../modules/joinCssClassNames'

export const HeroTitle: FunctionComponent<HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...rest }) => {
  const classNames = joinCssClassNames('hero-title', className)

  return (
    <h1 className={classNames} {...rest}>
      {children}
    </h1>
  )
}
