import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import { CopyText16Pt } from '../../../components/Text/CopyText16Pt/CopyText16Pt'
import ViewSubtitle from '../../../components/Text/ViewSubtitle/ViewSubtitle'
import ViewTitle from '../../../components/Text/ViewTitle/ViewTitle'
import withAuthentication from '../../../hoc/withAuthentication'
import withRedux from '../../../hoc/withRedux'

const WelcomeView = () => {
  return (
    <ElementContainer>
      <ViewTitle>Welcome To Klarly</ViewTitle>
      <ViewSubtitle>What you can expect to see here very soon</ViewSubtitle>

      <CopyText16Pt>
        Sit back and relax, we are working on grabbing your data and building your reports. We will send a notification when your new reporting world is ready
        to use.
      </CopyText16Pt>

      <CopyText16Pt>Happy analysing.</CopyText16Pt>
    </ElementContainer>
  )
}

export default withRedux(withAuthentication(WelcomeView))
