import '../OnboardingContactInfoView/OnboardingContactInfoView.scss'

import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import { OnboardingSubtitle } from '../../../components/Onboarding/OnboardingSubtitle/OnboardingSubtitle'
import { OnboardingTitle } from '../../../components/Onboarding/OnboardingTitle/OnboardingTitle'
import { CopyText12Pt } from '../../../components/Text/CopyText12Pt/CopyText12Pt'
import { ONBOARDING_ACCOUNT_CONFIRMATION_VIEW } from '../../../config/constants'
import { getOnboardingViewProgress } from '../../../modules/getNextOnboardingViewUrl'

const OnboardingAccountConfirmationView = () => {
  return (
    <ElementContainer>
      <div className="ob-progress">
        <CopyText12Pt>{getOnboardingViewProgress(ONBOARDING_ACCOUNT_CONFIRMATION_VIEW)}</CopyText12Pt>
      </div>

      <OnboardingTitle>You are all set.</OnboardingTitle>
      <OnboardingSubtitle style={{ margin: '0 auto' }}>We will get in touch with you soon.</OnboardingSubtitle>
    </ElementContainer>
  )
}

export default OnboardingAccountConfirmationView
