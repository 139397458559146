import _snakeCase from 'lodash/snakeCase'
import { useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'

import withRedux from '../../../hoc/withRedux'
import { getInputTypeByConfiguration } from '../../../modules/getInputTypeByConfiguration'
import { getMultiEntryConfigs, getSingleEntryConfigs } from '../../../modules/userConfigurationFn'
import { APIPayloadUpdateConnection } from '../../../types/APITypes/APIPayloadUpdateConnection'
import { ListExistingProviderConfigProps } from '../../../types/ListExistingProviderConfigProps'
import { KlarlyFormInput } from '../../FormElements/ReactHookForm/KlarlyFormInput'
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton'
import ProgressBar from '../../ProgressBar/ProgressBar'

const ExistingSingleAndMultiStringConnection = ({
  connectionData,
  onDeleteConnection,
  onDeleteConnectionEntry,
  onUpdateConnection,
  status,
}: ListExistingProviderConfigProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const MULTI_ENTRIES_REACT_FORM_NAME = `${_snakeCase(connectionData.provider.name)}_multiString`

  const singleEntryConfigs = getSingleEntryConfigs(connectionData)
  const multiEntryConfigs = getMultiEntryConfigs(connectionData)
  const multiEntryName = multiEntryConfigs[0]?.key.name
  const multiEntryType = multiEntryConfigs[0]?.key.type
  const multiEntryKeyId = multiEntryConfigs[0]?.key.id
  const multiEntryDefaultValues = multiEntryConfigs.map((entry) => ({ [multiEntryName]: entry.value, id: entry.id || null }))

  const { register, handleSubmit, getValues, control } = useForm({
    defaultValues: {
      [MULTI_ENTRIES_REACT_FORM_NAME]: multiEntryDefaultValues,
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: MULTI_ENTRIES_REACT_FORM_NAME, // unique name for the field array
  })

  const onFormSubmit: SubmitHandler<any> = (data, event) => {
    event?.preventDefault()
  }

  const onUpdateClick = async () => {
    setIsFetching(true)

    const singleEntries = singleEntryConfigs.map((configEntry) => {
      return {
        id: configEntry.id,
        type: configEntry.key.type,
        value: getValues(configEntry.key.name) as unknown as string,
        configurationKeyId: configEntry.key.id,
      }
    })

    const multiEntries = getValues(MULTI_ENTRIES_REACT_FORM_NAME).map((configEntry) => ({
      id: configEntry.id,
      value: configEntry[multiEntryName],
      type: multiEntryType,
      configurationKeyId: multiEntryKeyId,
    }))

    const payload = {
      customerConfigurationMetaId: connectionData.id,
      providerId: connectionData.provider.id,
      // @ts-ignore
      values: singleEntries.concat(multiEntries),
    } as APIPayloadUpdateConnection

    await onUpdateConnection(payload)
    setIsFetching(false)
  }

  const onDeleteClick = async () => {
    setIsFetching(true)

    await onDeleteConnection(connectionData.id)
    setIsFetching(false)
  }

  const onDeleteMultiEntryClick = async (index: number) => {
    setIsFetching(true)

    const entry = getValues(MULTI_ENTRIES_REACT_FORM_NAME)[index]
    const configurationId = entry.id

    if (configurationId && onDeleteConnectionEntry) {
      const isDeleted = await onDeleteConnectionEntry(configurationId)

      if (isDeleted) {
        remove(index)
      }
    }

    setIsFetching(false)
  }

  const onAddAnotherEntryClick = () => {
    // id = null only for newly added fields
    append({ id: null })
  }

  return (
    <form className="existing-connections__form" onSubmit={handleSubmit(onFormSubmit)}>
      <p className="mb-3">
        <strong>Deployment Status</strong>
      </p>

      <ProgressBar progress={status} />

      {singleEntryConfigs.map((singleEntryConfig) => (
        <KlarlyFormInput
          key={`form-data-${singleEntryConfig.id}`}
          id={`existing-${singleEntryConfig.key.name}-${singleEntryConfig.id}`}
          type={getInputTypeByConfiguration(singleEntryConfig.key.type.name)}
          name={singleEntryConfig.key.name}
          label={singleEntryConfig.key.label}
          register={register}
          defaultValue={singleEntryConfig.value}
          rules={{ required: 'You must enter this.' }}
        />
      ))}

      {fields.map(({ id }, index) => {
        return (
          <KlarlyFormInput
            key={`multi-entry-input-${id}`}
            id={`lbl-${id}-${multiEntryConfigs[0].id}`}
            name={`${MULTI_ENTRIES_REACT_FORM_NAME}.${index}.${multiEntryName}`}
            label={multiEntryConfigs[0].key.label}
            register={register}
            rules={{ required: 'You must enter this.' }}
            onDeleteClick={() => onDeleteMultiEntryClick(index)}
          />
        )
      })}

      <div style={{ marginTop: '1rem', marginBottom: '2rem' }}>
        <PrimaryButton tiny onClick={onAddAnotherEntryClick} disabled={isFetching}>
          Add {multiEntryConfigs[0].key.label}
        </PrimaryButton>
      </div>

      <PrimaryButton tiny onClick={onUpdateClick} className="mr-3" disabled={isFetching}>
        Update
      </PrimaryButton>
      <PrimaryButton tiny onClick={onDeleteClick} disabled={isFetching}>
        Delete Connection
      </PrimaryButton>
    </form>
  )
}

export default withRedux(ExistingSingleAndMultiStringConnection)
