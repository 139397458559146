import './_messages.scss'

import { ComponentType } from 'react'

type MessageProps = {
  message: string
  align?: 'left' | 'center' | 'right'
  MessageComponent?: ComponentType
}

export const ErrorMessage = ({ message, align = 'center', MessageComponent }: MessageProps) => {
  const messageElement = MessageComponent ? <MessageComponent>{message}</MessageComponent> : <p>{message}</p>

  return <div className={`message message--error message--${align}`}>{messageElement}</div>
}

export const SuccessMessage = ({ message, align = 'center', MessageComponent }: MessageProps) => {
  const messageElement = MessageComponent ? <MessageComponent>{message}</MessageComponent> : <p>{message}</p>

  return <div className={`message message--success message--${align}`}>{messageElement}</div>
}
