import { apiService } from '../../../services/APIService'
import { ApiPayloadNewConnection } from '../../../types/APITypes/APIPayloadNewConnection'
import { APIResponseConfigurationEntry } from '../../../types/APITypes/APIResponseConfiguration'
import ViewSubtitle from '../../Text/ViewSubtitle/ViewSubtitle'
import NewDatabaseConnection from './NewDatabaseConnection'
import NewGoogleAdsConnection from './NewGoogleAdsConnection'
import NewSingleAndMultiStringConnection from './NewSingleAndMultiStringConnection'

type OwnProps = {
  provider: APIResponseConfigurationEntry
}

const NewConnectionConfiguration = ({ provider }: OwnProps) => {
  const onSave = async (payload: ApiPayloadNewConnection) => {
    await apiService.dispatchMeConfiguration(payload)
  }

  const renderNewConnection = () => {
    const { id } = provider

    const componentProps = {
      provider,
      onSave,
    }

    if (id === 1 || id === 3) {
      return <NewSingleAndMultiStringConnection {...componentProps} />
    }

    if (id === 2) {
      return <NewDatabaseConnection {...componentProps} />
    }

    if (id === 4) {
      return <NewGoogleAdsConnection {...componentProps} />
    }
  }

  return (
    <div className="add-config__wrapper">
      <ViewSubtitle>New Connection</ViewSubtitle>

      {renderNewConnection()}
    </div>
  )
}

export default NewConnectionConfiguration
