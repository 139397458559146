import './_progress-bar.scss'

import classNames from 'classnames'
import _inRange from 'lodash/inRange'
import { HTMLAttributes } from 'react'

type OwnProps = {
  progress: number
} & HTMLAttributes<HTMLDivElement>

const progressColorMap = {
  RED: '#cb5b66',
  ORANGE: '#f39800',
  YELLOW: '#ffc759',
  GREEN: '#1b998b',
}

const ProgressBar = ({ className, progress = 0 }: OwnProps) => {
  const getColorOfProgress = () => {
    if (_inRange(progress, 0, 24)) {
      return progressColorMap.RED
    }

    if (_inRange(progress, 25, 49)) {
      return progressColorMap.ORANGE
    }

    if (_inRange(progress, 50, 74)) {
      return progressColorMap.YELLOW
    }

    return progressColorMap.GREEN
  }

  return (
    <div className={classNames('progress-bar', className)}>
      <div className="progress-bar__fill-bar" style={{ width: `${progress}%`, backgroundColor: getColorOfProgress() }} />
      <span className="progress-bar__status">{progress}%</span>
    </div>
  )
}

export default ProgressBar
