import { connectReduxModules } from '../redux/modules'
import AppStoreProvider from '../redux/Provider'

function withRedux(Component: any) {
  const ConnectedComponent = connectReduxModules()(Component)

  return function inject(props: any) {
    return (
      <AppStoreProvider>
        <ConnectedComponent {...props} />
      </AppStoreProvider>
    )
  }
}

export default withRedux
