import { PureComponent, ReactNode } from 'react'
import { Provider } from 'react-redux'
import { Store } from 'redux'

import initializeStore from '../store/store'

let store: Store

type AppStoreProviderProps = {
  children: ReactNode
}

class AppStoreProvider extends PureComponent<AppStoreProviderProps> {
  constructor(props: AppStoreProviderProps) {
    super(props)

    if (!store) {
      const { store: newStore } = initializeStore()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      store = newStore
    }
  }

  render() {
    const { children } = this.props

    return <Provider store={store}>{children}</Provider>
  }
}

export default AppStoreProvider
