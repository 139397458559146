import './_view-title.scss'

import { FunctionComponent, HTMLAttributes } from 'react'

import joinCssClassNames from '../../../modules/joinCssClassNames'

const ViewTitle: FunctionComponent<HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...rest }) => {
  return (
    <h3 className={joinCssClassNames('h3 view-title', className)} {...rest}>
      {children}
    </h3>
  )
}

export default ViewTitle
