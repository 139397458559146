import './_input.scss'

import { ChangeEvent, InputHTMLAttributes } from 'react'

import { ReactComponent as BinDeleteSvg } from '../../assets/svg/bin-delete.svg'
import joinCssClassNames from '../../modules/joinCssClassNames'
import SearchbarClearButton from '../SearchbarClearButton/SearchbarClearButton'

type InputType = InputHTMLAttributes<HTMLInputElement> & {
  isError?: boolean
  label?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onClearButtonClick?: () => void
  onDeleteButtonClick?: () => void
  showClearButton?: boolean
  showDeleteButton?: boolean
}

const Input = ({
  className = 'is-small',
  id,
  isError = false,
  label,
  name,
  onChange,
  onClearButtonClick,
  onDeleteButtonClick,
  placeholder,
  showClearButton = false,
  showDeleteButton = false,
  step,
  type = 'text',
  value,
  ...rest
}: InputType) => {
  const classNames = joinCssClassNames(
    'form-field__input',
    showClearButton ? 'form-field__input--with-close' : undefined,
    isError ? 'form-field__input--error' : undefined,
    className
  )

  const InputElement = (
    <div className={`form-field__input-wrapper ${showDeleteButton ? 'form-field__input-wrapper--with-delete' : ''}`}>
      <input
        id={id}
        name={name ? name : id}
        type={type}
        placeholder={placeholder}
        className={classNames}
        value={value}
        onChange={onChange}
        step={step}
        {...rest}
      />

      {showClearButton && <SearchbarClearButton onClearButtonClick={onClearButtonClick} />}
      {showDeleteButton && <BinDeleteSvg className="form-field__input-delete" onClick={onDeleteButtonClick} />}
    </div>
  )

  if (label) {
    return (
      <div className="form-field">
        <label htmlFor={id} className="form-field__label">
          {label}
        </label>

        {InputElement}
      </div>
    )
  }

  return InputElement
}

export default Input
