import './_existingConnection.scss'

import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { ReactComponent as UpDownArrowSvg } from '../../../assets/svg/up-and-down-arrow.svg'
import withRedux from '../../../hoc/withRedux'
import { getInputTypeByConfiguration } from '../../../modules/getInputTypeByConfiguration'
import { APIPayloadUpdateConnection } from '../../../types/APITypes/APIPayloadUpdateConnection'
import { ListExistingProviderConfigProps } from '../../../types/ListExistingProviderConfigProps'
import { KlarlyFormInput } from '../../FormElements/ReactHookForm/KlarlyFormInput'
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton'
import ProgressBar from '../../ProgressBar/ProgressBar'

const DB_CONNECTION_TYPE_IDENTIFIER = 'db_connection_type'

const ExistingDatabaseConnection = ({ connectionData, configurations, onDeleteConnection, onUpdateConnection, status }: ListExistingProviderConfigProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const { register, handleSubmit, getValues } = useForm()

  const defaultConfig = configurations.data?.find((provider) => provider.id === connectionData.provider.id)?.setup
  const defaultConnectionTypes = defaultConfig?.find((config) => config.name === DB_CONNECTION_TYPE_IDENTIFIER)

  const onFormSubmit: SubmitHandler<any> = (data, event) => {
    event?.preventDefault()
  }

  const onUpdateClick = async () => {
    setIsFetching(true)

    const customerConfigurationMetaId = connectionData.id
    const connectionType = defaultConnectionTypes?.values.find((connectionType) => connectionType.name === getValues()[DB_CONNECTION_TYPE_IDENTIFIER])

    // iterate over the form data, Object.entries will contain data like ['db_connection_type', 'MySQL'] for each form field,
    // so we now have to pick the database entry ids (customer_configuration table) for each configuration
    const values = Object.entries(getValues()).map((configField) => {
      const [key, value] = configField

      const storedValues = connectionData.configuration.find((entry) => entry.key.name === key)
      const payloadConfigValues = {
        id: storedValues?.id,
        type: storedValues?.key.type,
        value,
        configurationKeyId: storedValues?.key.id,
      }

      // for a dropdown we have to send the id of the new connection type and the actual value
      if (storedValues?.key.name === DB_CONNECTION_TYPE_IDENTIFIER) {
        payloadConfigValues.value = {
          id: connectionType?.id,
          value: connectionType?.name,
        }
      }

      return payloadConfigValues
    })

    const payload = {
      customerConfigurationMetaId,
      providerId: connectionData.provider.id,
      values,
    } as APIPayloadUpdateConnection

    await onUpdateConnection(payload)
    setIsFetching(false)
  }

  const onDeleteClick = async () => {
    setIsFetching(true)

    await onDeleteConnection(connectionData.id)
    setIsFetching(false)
  }

  return (
    <form className="existing-connections__form" onSubmit={handleSubmit(onFormSubmit)}>
      <p className="mb-3">
        <strong>Deployment Status</strong>
      </p>

      <ProgressBar progress={status} />

      {connectionData.configuration.map((configuration) => {
        const inputType = getInputTypeByConfiguration(configuration.key.type.name)

        if (inputType === 'select') {
          return (
            <div key={`form-data-${configuration.id}`} className="form-field__dropdown-wrapper mb-3">
              <select {...register(configuration.key.name)} defaultValue={configuration.value} className="form-field__dropdown">
                {defaultConnectionTypes?.values.map((connectionType) => (
                  <option value={connectionType.name} key={`connection-type-for-${connectionData.id}-value-${connectionType.id}`}>
                    {connectionType.name}
                  </option>
                ))}
              </select>

              <UpDownArrowSvg className="form-field__dropdown-icon" />
            </div>
          )
        }

        return (
          <KlarlyFormInput
            key={`db-input-key-${configuration.id}`}
            id={`db-input-${configuration.id}`}
            type={inputType}
            name={configuration.key.name}
            label={configuration.key.label}
            register={register}
            defaultValue={inputType === 'password' ? undefined : configuration.value}
            rules={{ required: 'You must enter this.' }}
          />
        )
      })}

      {/* Attention: whenever ENTER is hit on any form input, the callback of the button that comes first here
       * will be executed!
       **/}
      <PrimaryButton tiny onClick={onUpdateClick} className="mr-3" disabled={isFetching}>
        Update
      </PrimaryButton>
      <PrimaryButton tiny onClick={onDeleteClick} disabled={isFetching}>
        Delete
      </PrimaryButton>
    </form>
  )
}

export default withRedux(ExistingDatabaseConnection)
