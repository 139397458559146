import './_searchbarClearButton.scss'

import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg'

type OwnProps = {
  onClearButtonClick?: () => void
}

const SearchbarClearButton = ({ onClearButtonClick }: OwnProps) => (
  <div className="searchbar-clear" onClick={onClearButtonClick} role="button">
    <CloseIcon className="searchbar-clear__icon" />
  </div>
)

export default SearchbarClearButton
