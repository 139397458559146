import { truncate as _truncate } from 'lodash'
import { cloneDeep as _cloneDeep } from 'lodash'

import { isTestOrLocalHostEnvironment } from '../../modules/environmentUtils'
import { GenericObject } from '../../types/GenericObject'
import { TrackingServiceInterface } from '../../types/TrackingServiceInterface'

class GoogleTrackingProvider implements TrackingServiceInterface {
  readonly dataLayer = window.dataLayer || []

  async init(): Promise<void> {
    if (isTestOrLocalHostEnvironment()) {
      console.log('GoogleTrackingProvider initialized', { dataLayer: this.dataLayer })
    }
  }

  async trackView(viewName: string, viewParameter?: GenericObject) {
    await this.trackEvent('virtualPageView', {
      ...viewParameter,
    })
  }

  async trackEvent(eventName: string, eventParameter?: GenericObject) {
    // https://support.google.com/firebase/answer/9237506?hl=en
    const eventNameTruncated = _truncate(eventName, {
      length: 40,
      omission: '',
    })

    // parameters are weirdly be deleted in TrackingService map() function
    const clonedEventParameter = _cloneDeep(eventParameter)
    const dataLayerObject = {
      event: eventNameTruncated,
      ...clonedEventParameter,
    }

    if (isTestOrLocalHostEnvironment()) {
      console.group('GoogleTrackingService.trackEvent')
      console.log('Event payload', { eventName, eventNameTruncated, dataLayerObject })
      console.groupEnd()
    }

    this.dataLayer.push(dataLayerObject)
  }
}

export default GoogleTrackingProvider
