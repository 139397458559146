import axios from 'axios'

import { API_GATEWAY_NAME, ENDPOINT_API_DATA_WAREHOUSE, SLACK_WEBHOOK_URL } from '../config/constants'
import { APIServiceError } from '../modules/ApiServiceError'
import User from '../modules/User'
import { AllowedHTTPRequestMethodsByAmplify } from '../types/AllowedHTTPRequestMethodsByAmplify'
import { APIPayloadDeleteMeConfigurationEntry } from '../types/APITypes/APIPayloadDeleteMeConfigurationEntry'
import { APIPayloadDeleteMeConnection } from '../types/APITypes/APIPayloadDeleteMeConnection'
import { ApiPayloadNewConnection } from '../types/APITypes/APIPayloadNewConnection'
import { APIPayloadUpdateConnection } from '../types/APITypes/APIPayloadUpdateConnection'
import { APIResponseConfiguration } from '../types/APITypes/APIResponseConfiguration'
import { APIResponseGetCustomerConfigurationStatus } from '../types/APITypes/APIResponseGetCustomerConfigurationStatus'
import { APIResponseGETProviderHierarchy } from '../types/APITypes/APIResponseGETProviderHierarchy'
import { APIResponseMeConfiguration } from '../types/APITypes/APIResponseMeConfiguration'
import { APIResponsePOSTCreateUser } from '../types/APITypes/APIResponsePOSTCreateUser'
import { APIResponseUser } from '../types/APITypes/APIResponseUser'
import { HubSpotCreateContact } from '../types/APITypes/HubSpotCreateContact'
import { POSTPayloadContact } from '../types/APITypes/POSTPayloadContact'
import { GenericObject } from '../types/GenericObject'
import { SlackPostMessage } from '../types/SlackPostMessage'
import { API } from './AmplifyKlarlyBackendService'
import { userService } from './UserService'

const axiosDataWarehouse = axios.create()

axiosDataWarehouse.interceptors.request.use(async (config) => {
  const idToken = await userService.getUserIdToken()

  if (config && config.headers) {
    config.headers['Authorization'] = `Bearer ${idToken}`
  }

  return config
})

async function invokeApi<T>(method: AllowedHTTPRequestMethodsByAmplify, path: string, init?: GenericObject | null): Promise<T> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return API[method](API_GATEWAY_NAME, path, init)
}

async function makeAuthenticatedRequest<T>(method: AllowedHTTPRequestMethodsByAmplify, path: string, body?: GenericObject): Promise<T> {
  const idToken = await userService.getUserIdToken()

  if (!idToken) {
    throw new APIServiceError('USER_ID_TOKEN_INVALID', 'User, idToken is null.')
  }

  try {
    return invokeApi<T>(method, path, {
      body,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
  } catch (error: any) {
    const { message } = error

    if (message && message.length > 0) {
      throw new APIServiceError('GENERAL_ERROR', `${message}. Check your connection and reload the page, please`)
    }

    const statusCode = error.response.status || JSON.stringify(error)
    throw new APIServiceError('API_404_REQUEST_ERROR', `API responded with ${statusCode}`, statusCode)
  }
}

async function makePOSTRequest<T>(path: string, body: GenericObject): Promise<T> {
  return invokeApi<T>('post', path, {
    body,
  })
}

async function makeAuthenticatedPOSTRequest<T>(path: string, body: GenericObject): Promise<T> {
  return makeAuthenticatedRequest<T>('post', path, body)
}

async function makeAuthenticatedPUTRequest<T>(path: string, body: GenericObject): Promise<T> {
  return makeAuthenticatedRequest<T>('put', path, body)
}

async function makeAuthenticatedDELETERequest<T>(path: string, body?: GenericObject): Promise<T> {
  return makeAuthenticatedRequest<T>('del', path, body)
}

async function makeAuthenticatedGETRequest<T>(path: string): Promise<T> {
  return makeAuthenticatedRequest<T>('get', path)
}

class APIService {
  async fetchMe(): Promise<User> {
    const apiResponse = await makeAuthenticatedGETRequest<APIResponseUser>('/me')
    return new User(apiResponse)
  }

  async createContact({ email, externalId, firstName, lastName }: POSTPayloadContact): Promise<APIResponsePOSTCreateUser> {
    return makeAuthenticatedPOSTRequest('/me', { email, externalId, firstName, lastName })
  }

  async fetchMeConfiguration(): Promise<APIResponseMeConfiguration> {
    return makeAuthenticatedGETRequest('/me/configuration')
  }

  async dispatchMeConfiguration(payload: ApiPayloadNewConnection): Promise<never> {
    return makeAuthenticatedPOSTRequest('/me/configuration', payload)
  }

  async dispatchMeConfigurationUpdate(payload: APIPayloadUpdateConnection): Promise<never> {
    return makeAuthenticatedPUTRequest('/me/configuration', payload)
  }

  async deleteMeConfiguration(payload: APIPayloadDeleteMeConnection): Promise<never> {
    return makeAuthenticatedDELETERequest('/me/configuration', payload)
  }

  async deleteMeConfigurationEntryById(payload: APIPayloadDeleteMeConfigurationEntry): Promise<never> {
    return makeAuthenticatedDELETERequest(`/me/configuration/${payload.configurationDBId}`)
  }

  async createHubSpotContact(payload: HubSpotCreateContact) {
    return makePOSTRequest('/hubspot/contact', payload)
  }

  async createProjectOnDataWarehouse() {
    try {
      await axiosDataWarehouse.post(`${ENDPOINT_API_DATA_WAREHOUSE}/init_project`)
    } catch (e) {
      throw new Error('Could not create project. Please try again or reach out to the support team.')
    }
  }

  async fetchTableauFreeToken() {
    try {
      const {
        data: { data },
      } = await axios.get(`${ENDPOINT_API_DATA_WAREHOUSE}/tableau_token_free`)
      return { data }
    } catch (e) {
      throw new Error('Could not retrieve Token')
    }
  }

  async fetchTableauToken() {
    try {
      const {
        data: { data },
      } = await axiosDataWarehouse.get(`${ENDPOINT_API_DATA_WAREHOUSE}/tableau_token_free`)
      return { data }
    } catch (e) {
      throw new Error('Could not retrieve Token')
    }
  }

  async fetchConfigurations(): Promise<APIResponseConfiguration> {
    return makeAuthenticatedGETRequest('/configuration')
  }

  async fetchProviderHierarchyByDashboardId(id: number): Promise<APIResponseGETProviderHierarchy> {
    return makeAuthenticatedGETRequest(`/provider/hierarchy/${id}`)
  }

  async fetchProviderConfigurationStatus(customerConfigurationMetaIds: number[]): Promise<APIResponseGetCustomerConfigurationStatus> {
    return makeAuthenticatedGETRequest(`/me/configuration/status/?id=${customerConfigurationMetaIds.join('&id=')}`)
  }

  async sendSlackMessage({ channel, text }: SlackPostMessage) {
    const data = { channel, text }

    return axios.post(SLACK_WEBHOOK_URL, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          // @ts-ignore
          delete headers.post['Content-Type']
          return data
        },
      ],
    })
  }
}

export const apiService = new APIService()
