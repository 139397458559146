import { useEffect, useState } from 'react'

import useAsyncPolling from '../../../customHooks/useAsyncPolling'
import useMountedState from '../../../customHooks/useMountedState'
import withRedux from '../../../hoc/withRedux'
import { apiService } from '../../../services/APIService'
import { APIPayloadUpdateConnection } from '../../../types/APITypes/APIPayloadUpdateConnection'
import { APIResponseConfigurationEntry } from '../../../types/APITypes/APIResponseConfiguration'
import { APIResponseGetCustomerConfigurationStatus } from '../../../types/APITypes/APIResponseGetCustomerConfigurationStatus'
import { APIResponseMeConfigurationEntry } from '../../../types/APITypes/APIResponseMeConfiguration'
import { AppProps } from '../../../types/AppProps'
import { PageSubtitle } from '../../Text/PageSubtitle/PageSubtitle'
import ViewSubtitle from '../../Text/ViewSubtitle/ViewSubtitle'
import ExistingDatabaseConnection from './ExistingDatabaseConnection'
import ExistingGoogleAdsConnection from './ExistingGoogleAdsConnection'
import ExistingSingleAndMultiStringConnection from './ExistingSingleAndMultiStringConnection'

type OwnProps = {
  provider: APIResponseConfigurationEntry
} & AppProps

type ConfigurationStatusDictionary = Record<number, number>

const POLLING_INTERVAL = 5000

const ListExistingConfigurations = ({ userConfiguration, deleteMeConnection, deleteMeConfigurationEntry, provider, fetchMeConfigurations }: OwnProps) => {
  const [existingUserConfigsByProvider, setExistingUserConfigsByProvider] = useState<APIResponseMeConfigurationEntry[]>()
  const [configurationStatus, setConfigurationStatus] = useState<ConfigurationStatusDictionary>()
  const isMounted = useMountedState()
  const [pollingCount, setPollingCount] = useState(0)

  // we have to use useEffect here, otherwise the existing connection components won't update
  useEffect(() => {
    const config = userConfiguration.data?.filter((storedConfig) => storedConfig.provider.id === provider.id) || []
    const initialStatus = transformAPIConfigurationStatus({
      data: config.map(({ id, provider, progressInPercentage, customLabel }) => ({ id, provider, progressInPercentage, customLabel })),
    })

    setPollingCount(0)
    setExistingUserConfigsByProvider(config)
    setConfigurationStatus(initialStatus)
  }, [provider, userConfiguration])

  useEffect(() => {
    if (pollingCount >= 5) {
      endPolling()
    }
  }, [pollingCount])

  const transformAPIConfigurationStatus = (response: APIResponseGetCustomerConfigurationStatus) => {
    return response.data.reduce<ConfigurationStatusDictionary>((previousValue, currentValue) => {
      return Object.assign(previousValue, {
        [currentValue.id]: currentValue.progressInPercentage,
      })
    }, {})
  }

  const updateConfigurationStatus = async () => {
    if (!existingUserConfigsByProvider) {
      return
    }

    if (!isMounted()) {
      return
    }

    const customerConfigurationMetaIds = existingUserConfigsByProvider.map((entry) => entry.id)

    if (customerConfigurationMetaIds.length === 0) {
      return
    }

    const statusResponse = await apiService.fetchProviderConfigurationStatus(customerConfigurationMetaIds)
    const status = transformAPIConfigurationStatus(statusResponse)

    setPollingCount((previousCount) => previousCount + 1)
    setConfigurationStatus(status)
  }

  const endPolling = useAsyncPolling(updateConfigurationStatus, POLLING_INTERVAL)

  const onDeleteCompleteConnectionClick = async (customerConfigurationMetaId: number) => {
    try {
      await deleteMeConnection({ customerConfigurationMetaId })
      return true
    } catch (error: any) {
      console.warn('Error deleting configuration', { error, customerConfigurationMetaId })
      return false
    }
  }

  const onDeleteConnectionConfigClick = async (configurationDBId: number) => {
    try {
      await deleteMeConfigurationEntry({ configurationDBId })
      return true
    } catch (error: any) {
      console.warn('Error deleting configuration entry', { error, configurationDBId })
      return false
    }
  }

  const onUpdateConnection = async (payload: APIPayloadUpdateConnection) => {
    try {
      await apiService.dispatchMeConfigurationUpdate(payload)
      await fetchMeConfigurations()
      return true
    } catch (error: any) {
      console.warn('Error updating configuration', { error, payload })
      return false
    }
  }

  const renderExistingConnections = () => {
    if (existingUserConfigsByProvider?.length === 0) {
      return <PageSubtitle>No existing connections found</PageSubtitle>
    }

    const componentProps = {
      onDeleteConnection: onDeleteCompleteConnectionClick,
      onDeleteConnectionEntry: onDeleteConnectionConfigClick,
      onUpdateConnection: onUpdateConnection,
    }

    return (
      <>
        {existingUserConfigsByProvider?.map((connection) => {
          const key = `existing-connection-${connection.id}`
          const { provider, id } = connection
          const status = (configurationStatus && configurationStatus[id]) || 0

          if (provider.id === 1 || provider.id === 3) {
            return <ExistingSingleAndMultiStringConnection connectionData={connection} {...componentProps} status={status} key={key} />
          }

          if (provider.id === 2) {
            return <ExistingDatabaseConnection connectionData={connection} key={key} {...componentProps} status={status} />
          }

          if (provider.id === 4) {
            return <ExistingGoogleAdsConnection connectionData={connection} key={key} {...componentProps} status={status} />
          }
        })}
      </>
    )
  }

  return (
    <div className="add-config__wrapper">
      <ViewSubtitle>Existing Connections</ViewSubtitle>

      <div className="existing-connections">{renderExistingConnections()}</div>
    </div>
  )
}

export default withRedux(ListExistingConfigurations)
