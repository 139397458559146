import { ConfirmSignUpParameter } from '../types/APITypes/ConfirmSignUpParameter'
import { PasswordResetCodeDelivery } from '../types/APITypes/PasswordResetCodeDelivery'
import { SignInParameter } from '../types/APITypes/SignInParameter'
import { SignUpParameter } from '../types/APITypes/SignUpParameter'
import { SignUpUserResponse } from '../types/APITypes/SignUpUserResponse'
import { CognitoUser } from '../types/CognitoUser'
import { Auth } from './AmplifyKlarlyBackendService'

class UserService {
  async confirmSignUp({ username, code }: ConfirmSignUpParameter) {
    return Auth.confirmSignUp(username, code)
  }

  async signUp({ username, password }: SignUpParameter): Promise<SignUpUserResponse> {
    return Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
      },
    })
  }

  async getUserIdToken(): Promise<string | null> {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const { jwtToken } = user.signInUserSession.idToken
      return jwtToken
    } catch (e) {
      // not logged in
      console.warn('Not logged in', e)
    }

    return null
  }

  async isAuthenticated({ bypassCache = false }: { bypassCache?: boolean } = {}): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser({
        // Optional, by default is false.
        // If set to true, this call will send a request to
        // Cognito to get the latest user data
        bypassCache,
      })

      return true
    } catch (e: unknown) {
      return false
    }
  }

  async resendConfirmationCode(username: string) {
    return Auth.resendSignUp(username)
  }

  async signIn({ username, password }: SignInParameter): Promise<CognitoUser> {
    return Auth.signIn(username, password)
  }

  async signOut() {
    return Auth.signOut()
  }

  async requestPasswordResetCode(email: string): Promise<PasswordResetCodeDelivery> {
    return Auth.forgotPassword(email)
  }

  async confirmNewPassword({ email, code, newPassword }: { email: string; code: string; newPassword: string }) {
    return Auth.forgotPasswordSubmit(email, code, newPassword)
  }
}

export const userService = new UserService()
