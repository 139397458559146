import DashboardsOverview from '../../../components/DashboardsOverview/DashboardsOverview'
import { CopyText16Pt } from '../../../components/Text/CopyText16Pt/CopyText16Pt'
import ViewSubtitle from '../../../components/Text/ViewSubtitle/ViewSubtitle'
import ViewTitle from '../../../components/Text/ViewTitle/ViewTitle'
import withAuthentication from '../../../hoc/withAuthentication'
import withRedux from '../../../hoc/withRedux'
import { AppProps } from '../../../types/AppProps'

const ReportOverviewView = ({ user }: AppProps) => {
  const { project } = user
  const pageHeadline = <ViewTitle>Your Reports Overview</ViewTitle>

  if (!project) {
    return (
      <div className="report-view">
        {pageHeadline}

        <CopyText16Pt>There are no projects available for you at the moment.</CopyText16Pt>
      </div>
    )
  }

  return (
    <div className="report-view">
      {pageHeadline}

      <section className="report-overview">
        <article className="report">
          <ViewSubtitle>
            {project.name} (<code>{project.projectIdentifier}</code>)
          </ViewSubtitle>

          <DashboardsOverview />
        </article>
      </section>
    </div>
  )
}

export default withRedux(withAuthentication(ReportOverviewView))
