import './_copyText12Pt.scss'

import { FunctionComponent, HTMLAttributes } from 'react'

import joinCssClassNames from '../../../modules/joinCssClassNames'

export const CopyText12Pt: FunctionComponent<HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...rest }) => (
  <p className={joinCssClassNames('copy-text-12', className)} {...rest}>
    {children}
  </p>
)
