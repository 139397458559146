import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import GearLoadingAnimation from '../../../components/LoadingAnimation/GearLoadingAnimation'
import ViewTitle from '../../../components/Text/ViewTitle/ViewTitle'
import { urls } from '../../../config/constants'

const DemoPageLoadingView = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate(urls.DEMO_PAGE_REPORT_VIEW)
    }, 3000)
  })

  return (
    <>
      <ViewTitle>Please wait...</ViewTitle>
      <GearLoadingAnimation />
    </>
  )
}

export default DemoPageLoadingView
