import './_inlineLink.scss'

import { AnchorHTMLAttributes, FunctionComponent } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import joinCssClassNames from '../../../modules/joinCssClassNames'

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps

const InlineLink: FunctionComponent<Props> = ({ to, children, className, ...rest }) => {
  return (
    <Link to={to} className={joinCssClassNames('inline-link', className)} {...rest}>
      {children}
    </Link>
  )
}

export default InlineLink
