import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { LoadingAnimation } from '../../../components/LoadingAnimation/LoadingAnimation'
import TableauReport from '../../../components/TableauReport/TableauReport'
import ViewSubtitle from '../../../components/Text/ViewSubtitle/ViewSubtitle'
import ViewTitle from '../../../components/Text/ViewTitle/ViewTitle'
import { urls } from '../../../config/constants'
import withAuthentication from '../../../hoc/withAuthentication'
import withRedux from '../../../hoc/withRedux'
import { apiService } from '../../../services/APIService'
import { AppProps } from '../../../types/AppProps'

const ReportDetailView = ({ user }: AppProps) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [tableauToken, setTableauToken] = useState<string>('')
  const { id: dashboardIdFromURL } = useParams()

  if (!dashboardIdFromURL) {
    return <Navigate to={urls.REPORTS_OVERVIEW_VIEW} />
  }

  const dashboard = user.project?.dashboards.find((dashboard) => dashboard.externalId === dashboardIdFromURL)

  if (!dashboard) {
    return (
      <section className="dashboard-view">
        <ViewSubtitle>Dashboard with ID {dashboardIdFromURL} was not found.</ViewSubtitle>
      </section>
    )
  }

  useEffect(() => {
    setIsFetching(true)
    fetchTableauToken()
  }, [])

  const fetchTableauToken = async () => {
    const { data: token } = await apiService.fetchTableauToken()
    setTableauToken(token)
    setIsFetching(false)
  }

  if (isFetching) {
    return (
      <section className="dashboard-view">
        <LoadingAnimation />
      </section>
    )
  }

  return (
    <section className="dashboard-view">
      <ViewTitle>{dashboard.name}</ViewTitle>
      <TableauReport link={dashboard.link} token={tableauToken} />
    </section>
  )
}

export default withRedux(withAuthentication(ReportDetailView))
