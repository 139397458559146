import { useCallback, useEffect, useRef } from 'react'

interface AsyncPollingProps {
  id: number
}

const clearPollingTimeout = (handle?: AsyncPollingProps | null) => {
  if (handle) {
    cancelAnimationFrame(handle.id)
  }
}

const setPollingTimeout = (callback: () => void, timeout = 0) => {
  const interval = timeout < 0 ? 0 : timeout
  const handle: AsyncPollingProps = { id: 0 }

  const startTime = Date.now()

  const pollingLoop = () => {
    if (Date.now() - startTime >= interval) {
      callback()
    } else {
      handle.id = requestAnimationFrame(pollingLoop)
    }
  }

  handle.id = requestAnimationFrame(pollingLoop)
  return handle
}

const useAsyncPolling = (fn: () => Promise<void>, timeout?: number) => {
  const timerRef = useRef<AsyncPollingProps>()
  const endedRef = useRef(false)

  const fnRef = useRef(fn)
  fnRef.current = fn

  const end = useCallback(() => {
    endedRef.current = true
    clearPollingTimeout(timerRef.current)
  }, [])

  useEffect(() => {
    void (async function run() {
      await fnRef.current()

      if (!endedRef.current) {
        timerRef.current = setPollingTimeout(run, timeout)
      }
    })()
  }, [timeout])

  return end
}

export default useAsyncPolling
