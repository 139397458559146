import { ReduxAction, SettingsSetIsDarkModeAction, SettingsSetIsLoadingAction } from '../../../types/redux/ReduxAction'
import { SettingsState } from '../../../types/redux/SettingsState'
import { SETTING_SET_DARK_MODE, SETTINGS_IS_LOADING } from './actions'

export const makeDefaultSettingsState = (): SettingsState => ({
  isLoading: false,
  isDarkMode: true,
})

export default function data(state = makeDefaultSettingsState(), action: ReduxAction): SettingsState {
  const { type } = action

  switch (type) {
    case SETTING_SET_DARK_MODE: {
      const { payload: isDarkMode } = action as SettingsSetIsDarkModeAction

      return {
        ...state,
        isDarkMode,
      }
    }

    case SETTINGS_IS_LOADING: {
      const { payload: isLoading } = action as SettingsSetIsLoadingAction

      return {
        ...state,
        isLoading,
      }
    }

    default:
      return state
  }
}
