import './_gearLoadingAnimation.scss'
const GearLoadingAnimation = () => (
  <div className="loading-gears">
    <div className="loading-gears__blur" />
    <div className="load_gears">
      <div className="gear">
        <div className="center" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
      <div className="gear gear-two">
        <div className="center" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
      <div className="gear gear-three">
        <div className="center" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
    </div>
  </div>
)

export default GearLoadingAnimation
