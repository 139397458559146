import { CognitoUser } from '../../../types/CognitoUser'
import { AmplifyUserSignedInAction, AmplifyUserSignedOutAction, AmplifyUserSignedUpAction } from '../../../types/redux/ReduxAction'

export const AMPLIFY_USER_SIGNED_IN = 'amplify/USER_SIGNED_IN'
export const AMPLIFY_USER_SIGNED_OUT = 'amplify/USER_SIGNED_OUT'
export const AMPLIFY_USER_SIGNED_UP = 'amplify/USER_SIGNED_UP'

export const amplifyActionCreators: {
  amplify_signIn: (action: CognitoUser) => AmplifyUserSignedInAction
  amplify_signOut: (action: CognitoUser) => AmplifyUserSignedOutAction
  amplify_signUp: (actoin: CognitoUser) => AmplifyUserSignedUpAction
} = {
  amplify_signIn: (payload: CognitoUser) => ({
    type: AMPLIFY_USER_SIGNED_IN,
    payload,
  }),

  amplify_signOut: (payload: CognitoUser) => ({
    type: AMPLIFY_USER_SIGNED_OUT,
    payload,
  }),

  amplify_signUp: (payload: CognitoUser) => ({
    type: AMPLIFY_USER_SIGNED_UP,
    payload,
  }),
}
