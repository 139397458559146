import { JS } from 'aws-amplify'
import _cloneDeep from 'lodash/cloneDeep'
import _remove from 'lodash/remove'

import {
  ReduxAction,
  UserConfigurationDeleteAction,
  UserConfigurationDeleteSingleEntryAction,
  UserConfigurationReceivedAction,
} from '../../../types/redux/ReduxAction'
import { UserConfigurationState } from '../../../types/redux/UserConfigurationState'
import {
  USER_CONFIGURATION_DELETE_ACTION,
  USER_CONFIGURATION_DELETE_SINGLE_ENTRY_ACTION,
  USER_CONFIGURATION_FETCH_ERROR_ACTION,
  USER_CONFIGURATION_RECEIVED_ACTION,
} from './actions'

const makeDefaultState = (): UserConfigurationState => ({
  data: null,
})

export default function data(state = makeDefaultState(), action: ReduxAction): UserConfigurationState {
  switch (action.type) {
    case USER_CONFIGURATION_RECEIVED_ACTION: {
      const { payload } = action as UserConfigurationReceivedAction
      return payload
    }

    case USER_CONFIGURATION_FETCH_ERROR_ACTION: {
      return {
        data: null,
      }
    }

    case USER_CONFIGURATION_DELETE_ACTION: {
      const { payload } = action as UserConfigurationDeleteAction
      const { data } = state
      const updatedUserConfigurations = _cloneDeep(data)

      if (updatedUserConfigurations) {
        _remove(updatedUserConfigurations, { id: payload.customerConfigurationMetaId })
      }

      return {
        ...state,
        data: updatedUserConfigurations,
      }
    }

    case USER_CONFIGURATION_DELETE_SINGLE_ENTRY_ACTION: {
      const { payload } = action as UserConfigurationDeleteSingleEntryAction
      const { data } = state
      const updatedUserConfigurations = _cloneDeep(data)

      if (updatedUserConfigurations) {
        const elementFoundIndexes = updatedUserConfigurations.reduce<number[]>((prev, curr, idx) => {
          const indexInConfigurations = curr.configuration.findIndex((entry) => entry.id === payload.configurationDBId)

          if (indexInConfigurations > -1) {
            return [idx, indexInConfigurations]
          }

          return prev
        }, [])

        if (elementFoundIndexes.length > 0) {
          const [providerIndex, configIndex] = elementFoundIndexes

          // do not use delete here, because delete will keep "empty" in the array at the index of the deleted
          // object and thus delete does not change the length of the array
          updatedUserConfigurations[providerIndex].configuration.splice(configIndex, 1)
        }
      }

      return {
        ...state,
        data: updatedUserConfigurations,
      }
    }

    default:
      return state
  }
}
