import { ONBOARDING_VIEWS } from '../config/constants'
import { POSTPayloadContact } from '../types/APITypes/POSTPayloadContact'
import { OnboardingData } from '../types/OnboardingData'

const FIRST_NAME_KEY = 'ONBOARDING_FIRST_NAME_KEY'
const LAST_NAME_KEY = 'ONBOARDING_LAST_NAME_KEY'
const EMAIL_KEY = 'ONBOARDING_EMAIL_KEY'
const PROJECT_NAME = 'ONBOARDING_PROJECT_NAME'
const IS_SIGNUP_CONFIRMED = 'ONBOARDING_IS_SIGNUP_CONFIRMED'

type AllowedOnboardingDataKeys = typeof FIRST_NAME_KEY | typeof LAST_NAME_KEY | typeof EMAIL_KEY | typeof PROJECT_NAME | typeof IS_SIGNUP_CONFIRMED

// do not use sessionStorage, because the user might user another tab for confirming the onboarding
const store = localStorage

const returnStoreValueOrNull = (valueFromStore: any | null) => {
  if (!valueFromStore) {
    return null
  }

  return JSON.parse(valueFromStore)
}

export const getOnboardingDataByViewName = (viewName: string) => {
  return returnStoreValueOrNull(store.getItem(viewName))
}

export const getOnboardingDataByKey = (key: AllowedOnboardingDataKeys): any | null => {
  return returnStoreValueOrNull(store.getItem(key))
}

export const setOnboardingDataByViewName = (viewName: string, value: OnboardingData | OnboardingData[]) => {
  store.setItem(viewName, JSON.stringify(value))
}

export const setOnboardingDataByKey = (key: AllowedOnboardingDataKeys, value: any) => {
  store.setItem(key, JSON.stringify(value))
}

type OnboardingContactData = Omit<POSTPayloadContact, 'password' | 'externalId'>

export const setOnboardingContactData = ({ firstName, lastName, email }: OnboardingContactData) => {
  // do not set the password!
  store.setItem(FIRST_NAME_KEY, firstName)
  store.setItem(LAST_NAME_KEY, lastName)
  store.setItem(EMAIL_KEY, email)
}

export const getOnboardingContactData = () => {
  return {
    firstName: store.getItem(FIRST_NAME_KEY),
    lastName: store.getItem(LAST_NAME_KEY),
    email: store.getItem(EMAIL_KEY),
  }
}

export const clearOnboardingData = (viewName: string) => {
  store.removeItem(viewName)
}

export const clearOnboardingStorageData = () => {
  ONBOARDING_VIEWS.map(clearOnboardingData)

  store.removeItem(EMAIL_KEY)
  store.removeItem(FIRST_NAME_KEY)
  store.removeItem(IS_SIGNUP_CONFIRMED)
  store.removeItem(LAST_NAME_KEY)
  store.removeItem(PROJECT_NAME)
}
