import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ECommerceIcon1 } from '../../../assets/svg/ecommerce-1.svg'
import { ReactComponent as ECommerceIcon2 } from '../../../assets/svg/ecommerce-2.svg'
import { ReactComponent as ECommerceIcon3 } from '../../../assets/svg/ecommerce-3.svg'
import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import { OnboardingTitle } from '../../../components/Onboarding/OnboardingTitle/OnboardingTitle'
import OnboardingBackButton from '../../../components/OnboardingBackButton/OnboardingBackButton'
import OnboardingButton from '../../../components/OnboardingButton/OnboardingButton'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { CopyText12Pt } from '../../../components/Text/CopyText12Pt/CopyText12Pt'
import { ONBOARDING_CONTACT_INFO_VIEW, ONBOARDING_SHOP_SOFTWARE_VIEW } from '../../../config/constants'
import { getNextOnboardingViewUrl, getOnboardingViewProgress, getOnboardingViewUrlByViewName } from '../../../modules/getNextOnboardingViewUrl'
import { getOnboardingDataByViewName, setOnboardingDataByViewName } from '../../../modules/onboardingDataHandler'
import { OnboardingData } from '../../../types/OnboardingData'

const OPTIONS: OnboardingData[] = [
  { id: 1, label: 'Shopify', value: 1 },
  { id: 2, label: 'Shopware', value: 2 },
  { id: 3, label: 'Other', value: 3 },
]
const ICONS = [ECommerceIcon1, ECommerceIcon2, ECommerceIcon3]

const OnboardingECommerceView = () => {
  const navigate = useNavigate()
  const viewName = ONBOARDING_SHOP_SOFTWARE_VIEW
  const [activeOption, setActiveOption] = useState<null | OnboardingData>(() => getOnboardingDataByViewName(viewName) as OnboardingData)

  const onOptionClick = (option: OnboardingData) => {
    setActiveOption(option)
    setOnboardingDataByViewName(viewName, option)

    goToNextStep()
  }

  const goToNextStep = () => {
    const nextOnboardingUrl = getNextOnboardingViewUrl(viewName)
    navigate(nextOnboardingUrl)
  }

  return (
    <ElementContainer>
      <div className="ob-progress">
        <CopyText12Pt>{getOnboardingViewProgress(viewName)}</CopyText12Pt>
      </div>

      <OnboardingTitle>Select the eCommerce software you are using.</OnboardingTitle>

      <section>
        <div className="ob-options">
          {OPTIONS.map((option) => (
            <OnboardingButton
              key={`option-id-${option.id}`}
              onClick={() => onOptionClick(option)}
              option={option}
              isActive={option.id === (activeOption && activeOption.id)}
              icon={ICONS[option.value - 1]}
            />
          ))}
        </div>

        <div className="ob-cta-line">
          <OnboardingBackButton />

          <PrimaryButton medium onClick={goToNextStep}>
            Continue
          </PrimaryButton>
        </div>
      </section>
    </ElementContainer>
  )
}

export default OnboardingECommerceView
