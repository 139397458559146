import '../LoginView/_loginView.scss'
import './_passwordReset.scss'

import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import Input from '../../../components/FormElements/Input'
import { ErrorMessage } from '../../../components/Messages/Messages'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { LOCAL_STORAGE_PW_RESET_DESTINATION_KEY, LOCAL_STORAGE_PW_RESET_EMAIL_KEY, urls } from '../../../config/constants'
import { isValidEmailFormat } from '../../../modules/isValidEmailFormat'
import { userService } from '../../../services/UserService'

const RequestPasswordResetView = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setEmail(value)
  }

  const onSubmitButtonClick = async () => {
    setIsLoading(true)

    try {
      const {
        CodeDeliveryDetails: { Destination: passwordResetSentTo },
      } = await userService.requestPasswordResetCode(email)

      localStorage.setItem(LOCAL_STORAGE_PW_RESET_DESTINATION_KEY, passwordResetSentTo)
      localStorage.setItem(LOCAL_STORAGE_PW_RESET_EMAIL_KEY, email)
      setIsLoading(false)

      return navigate(urls.CONFIRM_NEW_PASSWORD_VIEW)
    } catch (e: any) {
      if (e && e.message) {
        setError(e.message)
      } else {
        setError('There was an error with your email / account requesting a password reset.')
      }
      setIsLoading(false)
    }
  }

  const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      await onSubmitButtonClick()
    }
  }

  const isSubmitButtonDisabled = () => isLoading || !isValidEmailFormat(email)

  return (
    <ElementContainer>
      <div className="reset-pw-title">
        <h3>Please enter your email to reset your password.</h3>
      </div>

      <fieldset className="login-fieldset">
        <div className="login-fieldset__element">
          <Input id="email" type="email" value={email} required placeholder="Enter email..." label="Email" onChange={onEmailChange} onKeyDown={onKeyDown} />
        </div>

        {error && <ErrorMessage message={error} />}

        <div className="button-line button-line--single">
          <PrimaryButton medium onClick={onSubmitButtonClick} disabled={isSubmitButtonDisabled()}>
            Submit
          </PrimaryButton>
        </div>
      </fieldset>
    </ElementContainer>
  )
}

export default RequestPasswordResetView
