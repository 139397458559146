import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react'

export type KlarlyInputProps = {
  id: string
  name: string
  label: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

// eslint-disable-next-line react/display-name
export const KlarlyInput = forwardRef<HTMLInputElement, KlarlyInputProps>(
  ({ id, name, label, type = 'text', className, placeholder, ...props }: KlarlyInputProps, ref) => (
    <input id={id} ref={ref} name={name} type={type} aria-label={label} placeholder={placeholder} className={className} {...props} />
  )
)
