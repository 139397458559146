import { Hub } from 'aws-amplify'
import { applyMiddleware, createStore, Store } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import { debugSettings } from '../../config/constants'
import { CognitoUser } from '../../types/CognitoUser'
import { amplifyActionCreators } from '../modules/amplify/actions'
import rootReducer from './reducers'

type HubListenerData = {
  payload: {
    event: string
    data: CognitoUser
  }
}

const makeAmplifyHubListener = (store: Store) => (data: HubListenerData) => {
  const amplifyEventData = data.payload.data

  switch (data.payload.event) {
    case 'signIn':
      store.dispatch(amplifyActionCreators.amplify_signIn(amplifyEventData))
      break

    case 'signOut': {
      store.dispatch(amplifyActionCreators.amplify_signOut(amplifyEventData))
      break
    }

    case 'signUp':
      store.dispatch(amplifyActionCreators.amplify_signUp(amplifyEventData))
      break
  }
}

export default function initializeStore() {
  const logger = createLogger({ diff: true })
  const middlewares = [thunk.withExtraArgument(null)].filter(Boolean)

  if (debugSettings.ENABLE_REDUX_LOGGER) {
    middlewares.push(logger)
  }

  const store = createStore(rootReducer, applyMiddleware(...middlewares))
  // TODO figure out the correct typing for the makeAmplifyHubListener
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Hub.listen('auth', makeAmplifyHubListener(store))

  return { store }
}
