import './_report-configuration-view.scss'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DAGSugiyama from '../../../components/D3/DAGSugiyama'
import ListExistingConfigurations from '../../../components/ProviderConfiguration/ExistingConnection/ListExistingConfigurations'
import NewConnectionConfiguration from '../../../components/ProviderConfiguration/NewConnection/NewConnectionConfiguration'
import ViewSubtitle from '../../../components/Text/ViewSubtitle/ViewSubtitle'
import ViewTitle from '../../../components/Text/ViewTitle/ViewTitle'
import withAuthentication from '../../../hoc/withAuthentication'
import withRedux from '../../../hoc/withRedux'
import { transformAPIDashboardDataToD3, transformUserConfigurationsToD3 } from '../../../modules/transformAPIDashboardDataToD3'
import { apiService } from '../../../services/APIService'
import { APIResponseConfigurationEntry } from '../../../types/APITypes/APIResponseConfiguration'
import { AppProps } from '../../../types/AppProps'
import { D3DagNodeType } from '../../../types/D3/D3DagNodeType'
import { SugiyamaTypes } from '../../../types/D3/SugiyamaTypes'

const ReportConfigurationView = ({ user, fetchMeConfigurations, userConfiguration, configurations, fetchConfigurations }: AppProps) => {
  const [providerToShow, setProviderToShow] = useState<APIResponseConfigurationEntry>()
  const [dagData, setDagData] = useState<SugiyamaTypes>()
  const [storedNodes, setStoredNodes] = useState<SugiyamaTypes>([])
  const [hasConfigurations, setHasConfigurations] = useState(false)
  const [hasUserConfigurations, setHasUserConfigurations] = useState(false)

  const { id } = useParams() // read the uuid from the url
  const { project } = user

  if (!id || !project) {
    return (
      <>
        <ViewTitle>No Dashboard found</ViewTitle>
        <ViewSubtitle>A dashboard with the given ID {id} cannot be found. Please go back, try again or reach out to use.</ViewSubtitle>
      </>
    )
  }

  useEffect(() => {
    fetchData()
  }, [])

  /**
   * We have to wait until all the data is set, otherwise we're running into racing conditions
   */
  useEffect(() => {
    if (configurations.data) {
      setHasConfigurations(true)
    }

    if (userConfiguration.data) {
      setHasUserConfigurations(true)
    }
  }, [configurations, userConfiguration])

  useEffect(() => {
    if (hasConfigurations && hasUserConfigurations) {
      prepareDAGSugiyamaData()
    }
  }, [hasConfigurations, hasUserConfigurations])

  const prepareDAGSugiyamaData = async () => {
    const dashboard = project.dashboards.find((dashboard) => dashboard.externalId === id)

    if (!dashboard) {
      return false
    }

    const { data } = await apiService.fetchProviderHierarchyByDashboardId(dashboard?.dashboardId)
    const d3DagData = transformAPIDashboardDataToD3(data)
    const alreadyStoredUserNodes = transformUserConfigurationsToD3(userConfiguration, d3DagData)

    setDagData(d3DagData)
    setStoredNodes(alreadyStoredUserNodes)
  }

  const fetchData = async () => {
    await fetchConfigurations()
    await fetchMeConfigurations()
  }

  const onNodeClick = (pointerEvent: PointerEvent, nodeData: D3DagNodeType) => {
    const configurationToAdd = configurations?.data?.find((entry) => entry.name === nodeData.data.id)

    if (configurationToAdd) {
      // @ts-ignore
      setProviderToShow(configurationToAdd)
    } else {
      setProviderToShow(undefined)
    }
  }

  return (
    <section className="contact-view">
      <ViewTitle>Configure Your Dashboard</ViewTitle>

      <div className="config-wrapper">
        {dagData && <DAGSugiyama d3Data={dagData} storedNodes={storedNodes} onNodeClick={onNodeClick} />}

        {providerToShow && (
          <div className="config-wrapper__configs">
            <NewConnectionConfiguration provider={providerToShow} />
          </div>
        )}
      </div>

      {providerToShow && <ListExistingConfigurations provider={providerToShow} />}
    </section>
  )
}

export default withRedux(withAuthentication(ReportConfigurationView))
