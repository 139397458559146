import './_exampleReportView.scss'

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { HeroSection } from '../../../components/HeroSection/HeroSection'
import { LoadingAnimation } from '../../../components/LoadingAnimation/LoadingAnimation'
import TableauReport from '../../../components/TableauReport/TableauReport'
import { HeroTitle } from '../../../components/Text/HeroTitle/HeroTitle'
import InlineLink from '../../../components/Text/InlineLink/InlineLink'
import { PageSubtitle } from '../../../components/Text/PageSubtitle/PageSubtitle'
import { EXAMPLE_REPORTS, urls } from '../../../config/constants'
import withRedux from '../../../hoc/withRedux'
import { apiService } from '../../../services/APIService'
import { AppProps } from '../../../types/AppProps'
import { ExampleReport } from '../../../types/ExampleReport'

const ExampleReportView = ({ settings: { isDarkMode } }: AppProps) => {
  const { id: reportIdString } = useParams()
  const navigate = useNavigate()
  const id = parseInt(reportIdString || '1', 10)

  if (!id) {
    navigate(urls.REPORT_EXAMPLES_VIEW)
  }

  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [tableauToken, setTableauToken] = useState<string>('')
  const report = EXAMPLE_REPORTS.find(({ id: reportId }: ExampleReport) => reportId === id)

  useEffect(() => {
    fetchTableauToken()
  }, [])

  const fetchTableauToken = async () => {
    setIsFetching(true)
    const { data: token } = await apiService.fetchTableauFreeToken()
    setTableauToken(token)
    setIsFetching(false)
  }

  if (isFetching || !report) {
    return (
      <>
        <HeroSection>
          <LoadingAnimation />
        </HeroSection>
      </>
    )
  }

  return (
    <>
      <HeroSection>
        <HeroTitle>Example Report</HeroTitle>
        <PageSubtitle centered>
          <strong>{report.title}</strong>
        </PageSubtitle>

        <div className="example-report-description" dangerouslySetInnerHTML={{ __html: report.description }} />
      </HeroSection>

      <div className="tableau-wrapper">
        <TableauReport link={isDarkMode ? report.tableauLink : report.tableauLinkWhite} token={tableauToken} />
      </div>

      <div style={{ textAlign: 'center', marginTop: 32 }}>
        <InlineLink to={urls.REPORT_EXAMPLES_VIEW}>Back to overview</InlineLink>
      </div>
    </>
  )
}

export default withRedux(ExampleReportView)
