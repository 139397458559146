import _uniqBy from 'lodash/uniqBy'

import { APIProviderHierarchyEntry } from '../types/APITypes/APIResponseGETProviderHierarchy'
import { APIResponseMeConfiguration } from '../types/APITypes/APIResponseMeConfiguration'
import { SugiyamaItemType, SugiyamaTypes } from '../types/D3/SugiyamaTypes'

export const transformAPIDashboardDataToD3 = (data: APIProviderHierarchyEntry[]): SugiyamaTypes => {
  /**
   * The Sugiyama layout expects a data structure that works with parentIds, not nextNodeIds. Therefore, we
   * have to transform our backend data into a proper data structure.
   */
  const directNodesToReport = data.filter((entry) => !entry.nextNodeId)
  const nodesWithNextNode = data.filter((entry) => !!entry.nextNodeId)
  const reportNode = {
    id: data[0].dashboard.name,
    parentIds: directNodesToReport.map((entry) => entry.provider.name),
  }

  const nodes = directNodesToReport.map((entry) => {
    const parentNodes = nodesWithNextNode.filter((parentNodeEntry) => parentNodeEntry.nextNodeId === entry.id)

    return {
      id: entry.provider.name,
      parentIds: parentNodes.map((parentNode) => parentNode.provider.name),
    }
  })

  const parentNodes = nodesWithNextNode.map((entry) => ({
    id: entry.provider.name,
    parentIds: [],
  }))

  return [
    // first entry is always the desired report
    reportNode,
    ...nodes,
    ...parentNodes,
  ]
}

export const transformUserConfigurationsToD3 = (userConfigurations: APIResponseMeConfiguration, d3DagData: SugiyamaTypes) => {
  const alreadyStoredUserNodesD3RedundantEntries = userConfigurations.data?.reduce<SugiyamaItemType[]>((prev, current) => {
    const elementInD3Data = d3DagData.find((entry) => entry.id === current.provider.name)

    if (elementInD3Data) {
      prev.push(elementInD3Data)
    }

    return prev
  }, [])

  return _uniqBy(alreadyStoredUserNodesD3RedundantEntries, 'id')
}
