import './ToggleSwitch.scss'

import { CSSProperties, FunctionComponent, ReactNode, SVGProps, useState } from 'react'

import { CopyText16Pt } from '../Text/CopyText16Pt/CopyText16Pt'

type ToggleSwitchType = {
  disabled?: boolean
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  id: string
  isActive?: boolean
  isSmall?: boolean
  label?: string | ReactNode
  name?: string // fallback is the
  onSwitch?: (isActive: boolean) => void
  style?: CSSProperties
}

const ToggleSwitch = ({ id, label, isActive = false, isSmall = false, onSwitch, disabled, name = id, style = {}, icon }: ToggleSwitchType) => {
  const [isActiveState, setIsActiveState] = useState(() => isActive)
  const Icon = icon

  const onLabelClick = () => {
    if (disabled) {
      return false
    }

    const newState = !isActiveState

    if (onSwitch) {
      onSwitch(newState)
    }

    setIsActiveState(newState)
    return false
  }

  return (
    <div className={`toggle-switch ${isSmall ? 'toggle-switch--small' : ''} ${disabled ? 'toggle-switch--disabled' : ''} `} style={style}>
      <label htmlFor={id} className={`toggle-switch__label ${isActiveState ? 'toggle-switch__label--active' : ''}`}>
        <CopyText16Pt>
          {Icon && <Icon className="toggle-switch__icon" />}
          {label && <strong>{label}</strong>}
        </CopyText16Pt>
      </label>

      <div
        className={`toggle-switch__toggle ${isActiveState ? 'toggle-switch__toggle--active' : ''}`}
        onClick={onLabelClick}
        role="switch"
        aria-checked={isActiveState}
      >
        <input type="checkbox" id={id} name={name} className="toggle-switch__input" disabled={disabled} checked={isActiveState} readOnly />
      </div>
    </div>
  )
}

export default ToggleSwitch
