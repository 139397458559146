import './styles/main.scss'

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import AutoScrollTop from './components/AutoScrollTop/AutoScrollTop'
import CookieConsent from './components/CookieConsent/CookieConsent'
import Footer from './components/Footer/Footer'
import { MaxWidthPageContainer } from './components/MaxWidthPageContainer/MaxWidthPageContainer'
import TopNavigation from './components/TopNavigation/TopNavigation'
import { LOCAL_STORAGE_IS_LIGHT_MODE_KEY, pageTitles, urls } from './config/constants'
import { trackingService } from './services/TrackingService'
import Routing from './views/Routing'

const ApplicationRouting = () => {
  const [title, setTitle] = useState('')
  const location = useLocation()
  const isLightModeActive = localStorage.getItem(LOCAL_STORAGE_IS_LIGHT_MODE_KEY) === 'true' || false

  // page view on every location change
  useEffect(() => {
    const { URL: pageLocation } = document
    const pageTitle = pageTitles[location?.pathname] || pageTitles[urls.HOME_VIEW]

    setTitle(pageTitle)
    trackingService.trackView(pageTitle, { pageLocation, pageTitle })
  }, [location])

  useEffect(() => {
    if (isLightModeActive) {
      document.body.classList.add('light-mode')
    } else {
      document.body.classList.remove('light-mode')
    }
  }, [])

  return (
    <div id="app" className="app">
      <TopNavigation />
      <AutoScrollTop />

      <Helmet>
        <title>{title}</title>
      </Helmet>

      <MaxWidthPageContainer id="main">
        <Routing />
        <Footer />
      </MaxWidthPageContainer>

      <CookieConsent />
    </div>
  )
}

export default ApplicationRouting
