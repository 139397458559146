import './_pageSubtitle.scss'

import { FunctionComponent, HTMLAttributes } from 'react'

import joinCssClassNames from '../../../modules/joinCssClassNames'

type OwnProps = {
  centered?: boolean
}

type Props = OwnProps & HTMLAttributes<HTMLHeadingElement>

export const PageSubtitle: FunctionComponent<Props> = ({ centered = false, className, children, ...rest }) => {
  const classNames = joinCssClassNames('page-subtitle', className, `${centered ? 'page-title--centered' : ''}`)

  return (
    <h2 className={classNames} {...rest}>
      {children}
    </h2>
  )
}
