import * as d3 from 'd3'
import * as d3dag from 'd3-dag'
import { Dag } from 'd3-dag'
import { useEffect, useState } from 'react'

import { D3_ANIMATION_DURATION } from '../../config/constants'
import withRedux from '../../hoc/withRedux'
import { makeD3ElementClassName } from '../../modules/makeD3NodeIdByProviderName'
import { AppProps } from '../../types/AppProps'
import { D3DagNodeType } from '../../types/D3/D3DagNodeType'
import { SugiyamaTypes } from '../../types/D3/SugiyamaTypes'

type OwnProps = {
  d3Data: SugiyamaTypes
  isHorizontal?: boolean
  onNodeClick?: (pointerEvent: PointerEvent, nodeData: D3DagNodeType) => void
  storedNodes: SugiyamaTypes
} & AppProps

const CLASS_NAMES = {
  isArrow: 'is-arrow',
  isFillCircle: 'is-fill-circle',
  isMainCircle: 'is-main-circle',
  isStored: 'is-stored',
  isText: 'is-text',
  selectedByUser: 'selected-by-user',
}

const DAGSugiyama = ({ d3Data, isHorizontal, onNodeClick, storedNodes, settings }: OwnProps) => {
  const [canvasHeight, setCanvasHeight] = useState(100)
  const [canvasWidth, setCanvasWidth] = useState(300)
  const [, setDagData] = useState<SugiyamaTypes>()

  const activeSelectionColor = '#009ae9'
  const nodeRadius = 40
  const base = nodeRadius * 3.5
  const bgColor = settings.isDarkMode ? '#151B22' : '#faf9f6'
  const inactiveSelectionColor = settings.isDarkMode ? '#faf9f6' : '#151B22'

  useEffect(() => {
    clearChart()
    drawDag()
  }, [d3Data, settings.isDarkMode])

  useEffect(() => {
    markNodesAsStored()
  }, [storedNodes, settings.isDarkMode])

  const clearChart = () => d3.select('#chart').selectAll('*').remove()

  const getParentNode = (elementInput: d3.BaseType) => {
    return d3.select(elementInput).select(function () {
      // @ts-ignore
      return this.parentNode
    })
  }

  const markNodesAsStored = () => {
    if (storedNodes.length === 0) {
      return
    }

    storedNodes.forEach((storedNode) => {
      const cssSelector = makeD3ElementClassName(storedNode.id)

      d3.selectAll(`.${cssSelector}`).each(function () {
        const color = '#1b998b'
        const element = d3.select(this)
        const classNames = element.attr('class')
        const strokeWidth = 2
        const transitionDuration = D3_ANIMATION_DURATION
        const transitionDelay = Math.max(transitionDuration - 350, 0)
        const ease = d3.easeCubicOut

        element.classed(CLASS_NAMES.isStored, true)

        if (classNames.includes(CLASS_NAMES.isMainCircle)) {
          getParentNode(this)
            .select(`.${CLASS_NAMES.isFillCircle}`)
            .attr('fill', color)
            .transition()
            .ease(ease)
            .duration(transitionDuration)
            .attr('r', nodeRadius)
            .attr('fill', color)
        }

        if (!classNames.includes(CLASS_NAMES.isText)) {
          element.transition().delay(transitionDelay).attr('stroke', color).attr('stroke-width', strokeWidth)
        }

        // only fill the arrow path element
        if (classNames.includes(CLASS_NAMES.isArrow)) {
          element.transition().delay(transitionDelay).attr('fill', color)
        }
      })
    })
  }

  const makeElementActiveOrInactive = (elementInput: d3.BaseType, makeActive = true, cssClassName = CLASS_NAMES.selectedByUser) => {
    /**
     * Important: if you want to change the text color, you must only use the fill attribute! (at the moment)
     */
    const color = makeActive ? activeSelectionColor : inactiveSelectionColor
    const element = d3.select(elementInput)
    const classNames = element.attr('class')
    const strokeWidth = makeActive ? 2 : 1
    const transitionDuration = makeActive ? 350 : 0

    if (classNames.includes(CLASS_NAMES.isStored)) {
      return
    }

    element.classed(cssClassName, makeActive)

    if (!classNames.includes(CLASS_NAMES.isText)) {
      element.transition().duration(transitionDuration).attr('stroke', color).attr('stroke-width', strokeWidth)
    }

    // only fill the arrow path element
    if (classNames.includes(CLASS_NAMES.isArrow)) {
      element.transition().duration(transitionDuration).attr('fill', color).attr('stroke', bgColor)
    }
  }

  const graphClickHandler = (pointerEvent: PointerEvent, nodeData: D3DagNodeType) => {
    const activeClass = CLASS_NAMES.selectedByUser
    const className = makeD3ElementClassName(nodeData.data.id)

    // make previously selected elements inactive
    d3.selectAll(`.${activeClass}`).each(function () {
      makeElementActiveOrInactive(this, false)
    })

    // make selected elements active
    d3.selectAll(`.${className}`).each(function () {
      makeElementActiveOrInactive(this, true)
    })

    if (onNodeClick) {
      onNodeClick(pointerEvent, nodeData)
    }
  }

  const drawDag = () => {
    setDagData(d3Data)

    const xAccessor = (d: any) => d.x
    const yAccessor = (d: any) => d.y

    // dag
    const dag = d3dag.dagStratify()(d3Data)

    // layout
    const layout = d3dag
      .sugiyama()
      .layering(d3dag.layeringSimplex())
      .nodeSize(() => [base, base])

    // flip horizontal
    const horizontal = (dag: Dag) => {
      const { width: w, height: h } = layout(dag)

      for (const node of dag) {
        ;[node.x, node.y] = [node.y, node.x]
      }

      for (const { points } of dag.ilinks()) {
        for (const point of points) {
          // @ts-ignore
          ;[point.x, point.y] = [point.y, point.x]
        }
      }

      return { width: h, height: w }
    }

    // draw
    const { width, height } = isHorizontal ? horizontal(dag) : layout(dag)
    const svgSelection = d3.select('#chart').attr('height', height).attr('width', width)
    const arrowSize = (nodeRadius * nodeRadius) / 15.0
    const arrow = d3.symbol().type(d3.symbolTriangle).size(arrowSize)
    const arrowLen = Math.sqrt((4 * arrowSize) / Math.sqrt(3))
    const lines = d3
      .line()
      .curve(isHorizontal ? d3.curveMonotoneX : d3.curveMonotoneY)
      .x(xAccessor)
      .y(yAccessor)

    // needed to dynamically adjust chart dimension based on layout()
    setCanvasHeight(height)
    setCanvasWidth(width)

    // Plot edges
    svgSelection
      .append('g')
      .attr('data-type', 'edge')
      .selectAll('path')
      .data(dag.links())
      .enter()
      .append('path')
      // @ts-ignore
      .attr('d', ({ points }) => lines(points))
      .attr('fill', 'none')
      .attr('stroke-width', 2)
      .attr('stroke', inactiveSelectionColor)
      .attr('class', (d) => makeD3ElementClassName(d.source.data.id))

    // Select nodes
    const nodes = svgSelection
      .append('g')
      .attr('data-type', 'nodes-parent')
      .selectAll('g')
      .data(dag.descendants())
      .enter()
      .append('g')
      .attr('data-type', 'nodes-child')
      .attr('transform', ({ x, y }) => `translate(${x}, ${y})`)

    // Plot node circles
    nodes
      .append('circle')
      .attr('r', nodeRadius)
      .attr('stoke-width', 1)
      .attr('stroke', inactiveSelectionColor)
      .attr('fill', bgColor)
      .attr('class', (d) => `${makeD3ElementClassName(d.data.id)} ${CLASS_NAMES.isMainCircle}`)
      .on('click', (pointerEvent, nodeData) => graphClickHandler(pointerEvent, nodeData))

    nodes
      .insert('circle')
      .attr('r', 0)
      .attr('class', (d) => `${makeD3ElementClassName(d.data.id)} ${CLASS_NAMES.isFillCircle}`)
      .on('click', (pointerEvent, nodeData) => graphClickHandler(pointerEvent, nodeData))

    // arrow
    svgSelection
      .append('g')
      .attr('data-type', 'arrow')
      .selectAll('path')
      .data(dag.links())
      .enter()
      .append('path')
      .attr('class', (d) => `${makeD3ElementClassName(d.source.data.id)} ${CLASS_NAMES.isArrow}`)
      .attr('d', arrow)
      .attr('transform', ({ points }) => {
        const [end, start] = points.slice().reverse()

        // This sets the arrows to the node radius (20) away from the node center, on the last line
        // segment of the edge. This means that edges that only span ine level will work perfectly, but if the edge
        // bends, this will be a little off.
        const dx = start.x - end.x
        const dy = start.y - end.y

        const scale = (nodeRadius * 1.15) / Math.sqrt(dx * dx + dy * dy)

        // This is the angle of the last line segment
        const angle = (Math.atan2(-dy, -dx) * 180) / Math.PI + 90
        return `translate(${end.x + dx * scale}, ${end.y + dy * scale}) rotate(${angle})`
      })
      .attr('fill', inactiveSelectionColor)
      .attr('stroke', bgColor)
      .attr('stroke-width', 1.5)
      .attr('stroke-dasharray', `${arrowLen},${arrowLen}`)

    // append text
    nodes
      .append('text')
      .text((d) => d.data.id)
      .attr('class', (d) => `${makeD3ElementClassName(d.data.id)} ${CLASS_NAMES.isText}`)
      .attr('font-family', 'sans-serif')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('fill', inactiveSelectionColor)
      .style('font-size', '1rem')
      .on('click', (pointerEvent, nodeData) => graphClickHandler(pointerEvent, nodeData))
  }

  return <svg id="chart" className="config-wrapper__chart" height={canvasHeight} width={canvasWidth} />
}

export default withRedux(DAGSugiyama)
