import './_pageTitle.scss'

import { FunctionComponent, HTMLAttributes } from 'react'

import joinCssClassNames from '../../../modules/joinCssClassNames'

type OwnProps = {
  centered?: boolean
}

type Props = OwnProps & HTMLAttributes<HTMLHeadingElement>

export const PageTitle: FunctionComponent<Props> = ({ className, children, centered = false, ...rest }) => {
  const classNames = joinCssClassNames('page-title', className, `${centered ? 'page-title--centered' : ''}`)

  return (
    <h1 className={classNames} {...rest}>
      {children}
    </h1>
  )
}
