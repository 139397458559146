import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as FactorySize1 } from '../../../assets/svg/factory-size-1.svg'
import { ReactComponent as FactorySize2 } from '../../../assets/svg/factory-size-2.svg'
import { ReactComponent as FactorySize3 } from '../../../assets/svg/factory-size-3.svg'
import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import { OnboardingTitle } from '../../../components/Onboarding/OnboardingTitle/OnboardingTitle'
import OnboardingButton from '../../../components/OnboardingButton/OnboardingButton'
import { CopyText12Pt } from '../../../components/Text/CopyText12Pt/CopyText12Pt'
import { ONBOARDING_COMPANY_SIZE_VIEW } from '../../../config/constants'
import { getNextOnboardingViewUrl, getOnboardingViewProgress } from '../../../modules/getNextOnboardingViewUrl'
import { getOnboardingDataByViewName, setOnboardingDataByViewName } from '../../../modules/onboardingDataHandler'
import { OnboardingData } from '../../../types/OnboardingData'

const OPTIONS: OnboardingData[] = [
  { id: 1, label: '< 20', value: 1 },
  { id: 2, label: '< 50', value: 2 },
  { id: 3, label: '50+', value: 3 },
]

const ICONS = [FactorySize1, FactorySize2, FactorySize3]

const OnboardingCompanySizeView = () => {
  const navigate = useNavigate()
  const viewName = ONBOARDING_COMPANY_SIZE_VIEW
  const [activeOption, setActiveOption] = useState<null | OnboardingData>(() => getOnboardingDataByViewName(viewName) as OnboardingData)

  const onOptionClick = (option: OnboardingData) => {
    setActiveOption(option)
    setOnboardingDataByViewName(viewName, option)

    const nextOnboardingUrl = getNextOnboardingViewUrl(viewName)
    navigate(nextOnboardingUrl)
  }

  return (
    <ElementContainer>
      <div className="ob-progress">
        <CopyText12Pt>{getOnboardingViewProgress(viewName)}</CopyText12Pt>
      </div>

      <OnboardingTitle>How many people work in your company?</OnboardingTitle>

      <section className="ob-options">
        {OPTIONS.map((option) => (
          <OnboardingButton
            key={`option-id-${option.id}`}
            onClick={() => onOptionClick(option)}
            option={option}
            isActive={option.id === (activeOption && activeOption.id)}
            icon={ICONS[option.value - 1]}
          />
        ))}
      </section>
    </ElementContainer>
  )
}

export default OnboardingCompanySizeView
