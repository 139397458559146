import { Dispatch } from 'react'

import { apiService } from '../../../services/APIService'
import { APIPayloadDeleteMeConfigurationEntry } from '../../../types/APITypes/APIPayloadDeleteMeConfigurationEntry'
import { APIPayloadDeleteMeConnection } from '../../../types/APITypes/APIPayloadDeleteMeConnection'
import {
  UserConfigurationDeleteAction,
  UserConfigurationDeleteSingleEntryAction,
  UserConfigurationFetchErrorAction,
  UserConfigurationReceivedAction,
} from '../../../types/redux/ReduxAction'

export const USER_CONFIGURATION_DELETE_ACTION = 'userConfiguration/DELETE'
export const USER_CONFIGURATION_FETCH_ERROR_ACTION = 'userConfiguration/FETCH_ERROR'
export const USER_CONFIGURATION_RECEIVED_ACTION = 'userConfiguration/RECEIVED'
export const USER_CONFIGURATION_DELETE_SINGLE_ENTRY_ACTION = 'userConfiguration/DELETE_SINGLE_ENTRY'

type AllowedFetchUserConfigurationsDispatchTypes = UserConfigurationReceivedAction | UserConfigurationFetchErrorAction

type ActionCreatorsType = {
  deleteMeConnection: (payload: APIPayloadDeleteMeConnection) => (action: Dispatch<UserConfigurationDeleteAction>) => Promise<void>
  deleteMeConfigurationEntry: (payload: APIPayloadDeleteMeConfigurationEntry) => (action: Dispatch<UserConfigurationDeleteSingleEntryAction>) => Promise<void>
  fetchMeConfigurations: () => (action: Dispatch<AllowedFetchUserConfigurationsDispatchTypes>) => Promise<void>
}

export const userConfigurationActionCreators: ActionCreatorsType = {
  deleteMeConnection: (payload) => async (dispatch) => {
    await apiService.deleteMeConfiguration(payload)

    dispatch({
      type: USER_CONFIGURATION_DELETE_ACTION,
      payload,
    })
  },

  deleteMeConfigurationEntry: (payload) => async (dispatch) => {
    await apiService.deleteMeConfigurationEntryById(payload)

    dispatch({
      type: USER_CONFIGURATION_DELETE_SINGLE_ENTRY_ACTION,
      payload,
    })
  },

  fetchMeConfigurations: () => async (dispatch) => {
    try {
      dispatch({
        type: USER_CONFIGURATION_RECEIVED_ACTION,
        payload: await apiService.fetchMeConfiguration(),
      })
    } catch (error: any) {
      dispatch({
        type: USER_CONFIGURATION_FETCH_ERROR_ACTION,
        payload: {
          error,
        },
      })
    }
  },
}
