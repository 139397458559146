import { ComponentType, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import { LoadingAnimation } from '../components/LoadingAnimation/LoadingAnimation'
import { urls } from '../config/constants'
import ConfigurationOverviewView from './postlogin/ConfigurationOverviewView/ConfigurationOverviewView'
import ContactView from './postlogin/ContactView/ContactView'
import ReportConfigurationView from './postlogin/ReportConfigurationView/ReportConfigurationView'
import ReportDetailView from './postlogin/ReportDetailView/ReportDetailView'
import ReportView from './postlogin/ReportOverviewView/ReportOverviewView'
import WelcomeView from './postlogin/WelcomeView/WelcomeView'
import CookiePolicyView from './prelogin/CookiePolicyView/CookiePolicyView'
import DemoPageLoadingView from './prelogin/DemoPage/DemoPageLoadingView'
import DemoPageReportView from './prelogin/DemoPage/DemoPageReportView'
import DemoPageView from './prelogin/DemoPage/DemoPageView'
import UserTableauDocumentation from './prelogin/Documentation/UserTableauDocumentation'
import ExampleReportView from './prelogin/ExampleReportView/ExampleReportView'
import HomePageView from './prelogin/HomePageView/HomePageView'
import ImprintView from './prelogin/ImprintView/ImprintView'
import { LoginView } from './prelogin/LoginView/LoginView'
import LogoutView from './prelogin/LogoutView/LogoutView'
import OnboardingAccountConfirmationView from './prelogin/OnboardinAccountConfirmationView/OnboardingAccountConfirmationView'
import OnboardingBusinessModelView from './prelogin/OnboardingBusinessModelView/OnboardingBusinessModelView'
import OnboardingCompanySizeView from './prelogin/OnboardingCompanySizeView/OnboardingCompanySizeView'
import OnboardingContactConfirmationMVPView from './prelogin/OnboardingContactConfirmationMVPView/OnboardingContactConfirmationMVPView'
import OnboardingContactInfoView from './prelogin/OnboardingContactInfoView/OnboardingContactInfoView'
import OnboardingECommerceView from './prelogin/OnboardingECommerceView/OnboardingECommerceView'
import OnboardingPlatformsView from './prelogin/OnboardingPlatformsView/OnboardingPlatformsView'
import ConfirmNewPasswordView from './prelogin/PasswordResetView/ConfirmNewPasswordView'
import RequestPasswordResetView from './prelogin/PasswordResetView/RequestPasswordResetView'
import PlansPricingView from './prelogin/PlansPricingView/PlansPricingView'
import ReportExamplesView from './prelogin/ReportExamplesView/ReportExamplesView'
import PageNotFoundView from './special/PageNotFoundView'

const Routing = () => {
  const renderWithSuspense = (Component: ComponentType) => (
    <Suspense fallback={<LoadingAnimation />}>
      <Component />
    </Suspense>
  )

  return (
    <Routes>
      {/* PRE LOGIN ROUTES */}
      <Route path={urls.CONFIRM_NEW_PASSWORD_VIEW} element={renderWithSuspense(ConfirmNewPasswordView)} />
      <Route path={urls.COOKIE_POLICY_VIEW} element={renderWithSuspense(CookiePolicyView)} />
      <Route path={urls.DEMO_PAGE_LOADING_VIEW} element={renderWithSuspense(DemoPageLoadingView)} />
      <Route path={urls.DEMO_PAGE_REPORT_VIEW} element={renderWithSuspense(DemoPageReportView)} />
      <Route path={urls.DEMO_PAGE_VIEW} element={renderWithSuspense(DemoPageView)} />
      <Route path={urls.EXAMPLE_REPORT_VIEW} element={renderWithSuspense(ExampleReportView)} />
      <Route path={urls.HOME_VIEW} element={renderWithSuspense(HomePageView)} />
      <Route path={urls.IMPRINT_VIEW} element={renderWithSuspense(ImprintView)} />
      <Route path={urls.LOGIN_VIEW} element={renderWithSuspense(LoginView)} />
      <Route path={urls.PLANS_PRICING_VIEW} element={renderWithSuspense(PlansPricingView)} />
      <Route path={urls.REPORT_EXAMPLES_VIEW} element={renderWithSuspense(ReportExamplesView)} />
      <Route path={urls.REQUEST_PASSWORD_RESET_VIEW} element={renderWithSuspense(RequestPasswordResetView)} />
      <Route path={urls.DOCUMENTATION_TABLEAU} element={renderWithSuspense(UserTableauDocumentation)} />

      {/* ONBOARDING ROUTES */}
      <Route path={urls.ONBOARDING_ACCOUNT_CONFIRMATION_VIEW} element={renderWithSuspense(OnboardingAccountConfirmationView)} />
      <Route path={urls.ONBOARDING_BUSINESS_MODEL_VIEW} element={renderWithSuspense(OnboardingBusinessModelView)} />
      <Route path={urls.ONBOARDING_COMPANY_SIZE_VIEW} element={renderWithSuspense(OnboardingCompanySizeView)} />
      <Route path={urls.ONBOARDING_CONTACT_CONFIRMATION_MVP_VIEW} element={renderWithSuspense(OnboardingContactConfirmationMVPView)} />
      <Route path={urls.ONBOARDING_CONTACT_INFO_VIEW} element={renderWithSuspense(OnboardingContactInfoView)} />
      <Route path={urls.ONBOARDING_PLATFORMS_VIEW} element={renderWithSuspense(OnboardingPlatformsView)} />
      <Route path={urls.ONBOARDING_SHOP_SOFTWARE_VIEW} element={renderWithSuspense(OnboardingECommerceView)} />

      {/* POST LOGIN ROUTES */}
      <Route path={urls.CONFIGURATION_OVERVIEW_VIEW} element={renderWithSuspense(ConfigurationOverviewView)} />
      <Route path={urls.CONTACT_VIEW} element={renderWithSuspense(ContactView)} />
      <Route path={urls.LOGOUT_VIEW} element={renderWithSuspense(LogoutView)} />
      <Route path={urls.REPORT_CONFIGURATION_VIEW} element={renderWithSuspense(ReportConfigurationView)} />
      <Route path={urls.REPORT_DETAIL_VIEW_WITHOUT_ID} element={renderWithSuspense(ReportDetailView)} />
      <Route path={urls.REPORT_DETAIL_VIEW} element={renderWithSuspense(ReportDetailView)} />
      <Route path={urls.REPORTS_OVERVIEW_VIEW} element={renderWithSuspense(ReportView)} />
      <Route path={urls.WELCOME_VIEW} element={renderWithSuspense(WelcomeView)} />

      {/* SPECIAL ROUTES */}
      <Route path="*" element={renderWithSuspense(PageNotFoundView)} />
    </Routes>
  )
}

export default Routing
