import { Helmet } from 'react-helmet-async'

import { HubSpotCalendarWidgetProps } from '../../types/APITypes/HubSpotCalendarWidgetProps'

const HubSpotCalendarWidget = ({ email, firstName, lastName }: HubSpotCalendarWidgetProps) => {
  const contactQueryParameter = `firstName=${firstName}&lastName=${lastName}&email=${email}`

  return (
    <>
      <div className="meetings-iframe-container" data-src={`https://meetings-eu1.hubspot.com/nathan-gleeson?embed=true&${contactQueryParameter}`} />

      <Helmet>
        <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
      </Helmet>
    </>
  )
}

export default HubSpotCalendarWidget
