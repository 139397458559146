import { APIResponseConfigurationTypeOptions } from '../types/APITypes/APIResponseConfiguration'

export const getInputTypeByConfiguration = (apiTypeName: APIResponseConfigurationTypeOptions) => {
  if (apiTypeName === 'SECRET') {
    return 'password'
  }

  if (apiTypeName === 'ENUM') {
    return 'select'
  }

  return 'text'
}
