import { GenericObject } from '../types/GenericObject'
import { TrackingServiceInterface } from '../types/TrackingServiceInterface'
import GoogleTrackingProvider from './tracking/GoogleTrackingProvider'

type AllowedTrackingProviders = GoogleTrackingProvider
type TrackingProviders = AllowedTrackingProviders[]

/**
 * This class / class instance is what we will use for the actual tracking implementation.
 * It is an abstraction that can be extended with more tracking providers easily. All providers must implement
 * the according TrackingServiceInterface.
 */
class TrackingService implements TrackingServiceInterface {
  readonly providers: TrackingProviders

  constructor(dependencies: { googleTrackingProvider: GoogleTrackingProvider }) {
    this.providers = [dependencies.googleTrackingProvider]
  }

  async init() {
    this.providers.map((provider) => provider.init())
  }

  async trackView(viewName: string, viewParameter?: GenericObject): Promise<void> {
    if (viewName) {
      await Promise.all(this.providers.map((provider) => provider.trackView(viewName, viewParameter)))
    }
  }

  async trackEvent(eventName: string, eventParameter?: GenericObject): Promise<void> {
    await Promise.all(this.providers.map((provider) => provider.trackEvent(eventName, eventParameter)))
  }
}

export const trackingService = new TrackingService({
  googleTrackingProvider: new GoogleTrackingProvider(),
})
