import { ComponentType, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { LoadingAnimation } from '../components/LoadingAnimation/LoadingAnimation'
import { ErrorMessage } from '../components/Messages/Messages'
import { SUPPORT_EMAIL_ADDRESS, urls } from '../config/constants'
import { userService } from '../services/UserService'
import { AppProps } from '../types/AppProps'

function withAuthentication<T>(Component: ComponentType<T>) {
  return function inject(hocProps: T & AppProps) {
    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

    const fetchAuthenticationState = async () => {
      setError(null)

      try {
        hocProps.setIsLoading(true)
        const isLoggedIn = await userService.isAuthenticated()

        if (!isLoggedIn) {
          setIsAuthenticated(false)
          setIsLoading(false)
          hocProps.setIsLoading(false)
          return
        }

        if (!hocProps.user?.id) {
          await hocProps.fetchMe()
        }

        setIsAuthenticated(true)
        setIsLoading(false)
        hocProps.setIsLoading(false)
      } catch (e: any) {
        if (e && e.message) {
          setError(e.message + `. Please log out and back in again or reach out to us via email: ${SUPPORT_EMAIL_ADDRESS}`)
        } else {
          setError(`There was an error authenticating the user. Please log out and back in again or reach out to us via email: ${SUPPORT_EMAIL_ADDRESS}`)
        }
      }
    }

    useEffect(() => {
      fetchAuthenticationState()
    }, [])

    if (error) {
      return (
        <section>
          <ErrorMessage message={error} />
        </section>
      )
    }

    if (isLoading) {
      return <LoadingAnimation />
    }

    // Successfully authenticated or a cached authentication is stored in the browser
    if (isAuthenticated) {
      return <Component {...hocProps} />
    }

    // User is not authenticated - redirect to login view
    return <Navigate to={urls.LOGIN_VIEW} replace />
  }
}

export default withAuthentication
