import './_topNavigation.scss'
import './_reactBurgerMenu.scss'

import { Sling as HamburgerIcon } from 'hamburger-react'
import _find from 'lodash/find'
import _kebabCase from 'lodash/kebabCase'
import { useEffect, useState } from 'react'
import { slide as BurgerMenu } from 'react-burger-menu'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as LightDarkModeIcon } from '../../assets/svg/brightness.svg'
import { IS_MVP_VERSION, LOCAL_STORAGE_IS_LIGHT_MODE_KEY, urls } from '../../config/constants'
import withRedux from '../../hoc/withRedux'
import { addBodyClass, removeBodyClass } from '../../modules/bodyClass'
import { userService } from '../../services/UserService'
import { AppProps } from '../../types/AppProps'
import ToggleSwitch from '../FormElements/ToggleSwitch'
import { MaxWidthPageContainer } from '../MaxWidthPageContainer/MaxWidthPageContainer'
import { PrimaryButton } from '../PrimaryButton/PrimaryButton'
import { Logo } from '../Text/Logo/Logo'

type NavigationItem = {
  id: number
  index: number
  label: string
  section: 'PRE_LOGIN' | 'POST_LOGIN' | 'NO_SECTION' | 'MVP_POST_LOGIN'
  url: string
}

const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    id: 1,
    url: urls.HOME_VIEW,
    label: 'Home',
    index: 1,
    section: 'PRE_LOGIN',
  },
  {
    id: 2,
    url: urls.REPORT_EXAMPLES_VIEW,
    label: 'Reports',
    index: 2,
    section: 'PRE_LOGIN',
  },
  {
    id: 3,
    url: urls.PLANS_PRICING_VIEW,
    label: 'Pricing',
    index: 3,
    section: 'PRE_LOGIN',
  },

  /**
   * POST LOGIN
   */
  {
    id: 1,
    url: urls.REPORTS_OVERVIEW_VIEW,
    label: 'Reports',
    index: 1,
    section: 'POST_LOGIN',
  },
  {
    id: 2,
    url: urls.CONFIGURATION_OVERVIEW_VIEW,
    label: 'Configuration',
    index: 2,
    section: 'POST_LOGIN',
  },
  {
    id: 3,
    url: urls.CONTACT_VIEW,
    label: 'Contact',
    index: 3,
    section: 'POST_LOGIN',
  },
  {
    id: 4,
    url: urls.ADMIN_VIEW,
    label: 'Admin',
    index: 4,
    section: 'POST_LOGIN',
  },

  /**
   * MVP POST LOGIN
   */
  {
    id: 5,
    url: urls.WELCOME_VIEW,
    label: 'Welcome',
    index: 1,
    section: 'MVP_POST_LOGIN',
  },
]

const NON_TOP_NAVIGATION_ITEM: NavigationItem = {
  id: -1,
  index: -1,
  label: 'None',
  section: 'NO_SECTION',
  url: '/',
}

const BODY_CLASS_OPEN_MENU = 'has-menu-open'

const TopNavigation = ({ user, setDarkMode }: AppProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const activeMenuItem = _find(NAVIGATION_ITEMS, { url: location.pathname }) || NON_TOP_NAVIGATION_ITEM
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const showGetInTouchButton = !location?.pathname.includes('/onboarding') // only show when not in onboarding
  const isLightModeActive = localStorage.getItem(LOCAL_STORAGE_IS_LIGHT_MODE_KEY) === 'true' || false

  const PRE_LOGIN_ITEMS = NAVIGATION_ITEMS.filter(({ section }) => section === 'PRE_LOGIN')
  const POST_LOGIN_ITEMS = NAVIGATION_ITEMS.filter(({ section }) => {
    if (IS_MVP_VERSION) {
      return section === 'MVP_POST_LOGIN'
    }

    return section === 'POST_LOGIN'
  })

  // we have to check the active menu item on every mount
  useEffect(() => {
    onComponentLoad()
  }, [activeMenuItem.index, user])

  useEffect(() => {
    setDarkMode(!isLightModeActive)
  }, [])

  const onLightDarkModeSwitch = (isActive: boolean) => {
    if (isActive) {
      document.body.classList.add('light-mode')
      localStorage.setItem(LOCAL_STORAGE_IS_LIGHT_MODE_KEY, 'true')
      setDarkMode(false)
    } else {
      document.body.classList.remove('light-mode')
      localStorage.removeItem(LOCAL_STORAGE_IS_LIGHT_MODE_KEY)
      setDarkMode(true)
    }
  }

  const onComponentLoad = async () => {
    await setIsLoading(true)

    const isLoggedIn = await userService.isAuthenticated()

    setIsAuthenticated(isLoggedIn)
    setIsLoading(false)
  }

  const onGetInTouchButtonClick = () => {
    navigate(urls.ONBOARDING_COMPANY_SIZE_VIEW)
  }

  const onBurgerIconClick = () => {
    const showMenu = !isOpenBurgerMenu
    setIsOpenBurgerMenu(showMenu)

    if (showMenu) {
      addBodyClass(BODY_CLASS_OPEN_MENU)
    } else {
      removeBodyClass(BODY_CLASS_OPEN_MENU)
    }
  }

  const onMenuItemClick = () => {
    setIsOpenBurgerMenu(false)
    removeBodyClass(BODY_CLASS_OPEN_MENU)
  }

  const activeBurgerCssClass = isOpenBurgerMenu ? 'is-active' : ''

  const renderMenuItem = ({ id, label, url }: { id: number; label: string; url: string }) => {
    return (
      <li key={`top-nav-link-${_kebabCase(label)}-${id}`}>
        <NavLink
          className={({ isActive }) => (isActive ? 'top-nav-link top-nav-link--active' : 'top-nav-link')}
          to={url}
          onClick={onMenuItemClick}
          title={label}
        >
          {label}
        </NavLink>
      </li>
    )
  }

  const renderLightModeSwitch = () => (
    <div className="top-light-mode top-light-mode--desktop">
      <ToggleSwitch id="dark-light-mode" icon={LightDarkModeIcon} onSwitch={onLightDarkModeSwitch} isActive={isLightModeActive} />
    </div>
  )

  const renderPreLoginMenu = () => {
    if (isLoading) {
      return null
    }

    return (
      <>
        <ul className="top-nav-links">{PRE_LOGIN_ITEMS.map(renderMenuItem)}</ul>

        <div className="top-nav-buttons">
          {renderLightModeSwitch()}

          <NavLink className={({ isActive }) => (isActive ? 'top-nav-link top-nav-link--active' : 'top-nav-link')} to={urls.LOGIN_VIEW} title="Login">
            Login
          </NavLink>

          {showGetInTouchButton && (
            <PrimaryButton variation="tertiary" className="top-nav-buttons__cta" onClick={onGetInTouchButtonClick}>
              Get in touch
            </PrimaryButton>
          )}
        </div>
      </>
    )
  }

  const renderPostLoginMenu = () => {
    if (isLoading) {
      return null
    }

    return (
      <>
        <ul className="top-nav-links">{POST_LOGIN_ITEMS.map(renderMenuItem)}</ul>

        <nav className="top-nav-buttons" aria-labelledby="small-navigation">
          {renderLightModeSwitch()}

          <NavLink className={({ isActive }) => (isActive ? 'top-nav-link top-nav-link--active' : 'top-nav-link')} to={urls.LOGOUT_VIEW} title="Login">
            Logout
          </NavLink>
        </nav>
      </>
    )
  }

  return (
    <>
      <BurgerMenu
        isOpen={isOpenBurgerMenu}
        right
        disableCloseOnEsc
        customBurgerIcon={false}
        onClose={onBurgerIconClick}
        outerContainerId="app"
        pageWrapId="main"
      >
        {isAuthenticated && POST_LOGIN_ITEMS.map(renderMenuItem)}
        {!isAuthenticated && PRE_LOGIN_ITEMS.map(renderMenuItem)}

        <div className="top-light-mode">
          <ToggleSwitch id="dark-light-mode" icon={LightDarkModeIcon} onSwitch={onLightDarkModeSwitch} isActive={isLightModeActive} />
        </div>
      </BurgerMenu>

      <nav className={`top-nav ${activeBurgerCssClass}`} role="navigation" aria-label="main navigation" aria-labelledby="primary-navigation">
        <MaxWidthPageContainer className="top-nav__content">
          <div className="top-nav__logo">
            <Logo />
          </div>

          <div className="top-nav__menu">
            {isAuthenticated && renderPostLoginMenu()}
            {!isAuthenticated && renderPreLoginMenu()}

            <div className="hamburger-wrapper">
              <HamburgerIcon toggled={isOpenBurgerMenu} toggle={onBurgerIconClick} />
            </div>
          </div>
        </MaxWidthPageContainer>
      </nav>
    </>
  )
}

export default withRedux(TopNavigation)
