// We must not reorder the imports here, because the Prism imports for components / plugins must be imported AFTER
// the Prism library itself
/* eslint-disable simple-import-sort/imports */
import './_code-block.scss'

import classNames from 'classnames'
import Prism from 'prismjs'
import React, { ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import withRedux from '../../hoc/withRedux'
import { makeCDNLink } from '../../modules/makeCDNLink'
import { AppProps } from '../../types/AppProps'

import 'prismjs/plugins/toolbar/prism-toolbar.min'
import 'prismjs/plugins/toolbar/prism-toolbar.min.css'
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min'
import 'prismjs/components/prism-jsx' // attention: the tsx langauge will break on production somehow
import 'prismjs/components/prism-json'

type CodeBlockProps = {
  code: ReactNode | string
  language: 'json' | 'jsx' | 'markup'
} & AppProps &
  HTMLElement

const allowedStylesheets = {
  COY: makeCDNLink('css/prismjs/prism-coy.min.css'),
  DARK: makeCDNLink('css/prismjs/prism-dark.min.css'),
  DEFAULT: makeCDNLink('css/prismjs/prism.min.css'),
  FUNKY: makeCDNLink('css/prismjs/prism-funky.min.css'),
  OKAIDIA: makeCDNLink('css/prismjs/prism-okaidia.min.css'),
  SOLARIZED_LIGHT: makeCDNLink('css/prismjs/prism-solarizedlight.min.css'),
  TOMORROW: makeCDNLink('css/prismjs/prism-tomorrow.min.css'),
  TWILIGHT: makeCDNLink('css/prismjs/prism-twilight.min.css'),
}

const STYLESHEET_DOM_ID = 'code-block-style'

const CodeBlock = ({ code, language, settings: { isDarkMode }, className }: CodeBlockProps) => {
  const [theme, setTheme] = useState(allowedStylesheets.TOMORROW)

  useEffect(() => {
    const newTheme = isDarkMode ? allowedStylesheets.TOMORROW : allowedStylesheets.DEFAULT
    setTheme(newTheme)

    const stylesheetLinkTag = document.getElementById(STYLESHEET_DOM_ID) as HTMLLinkElement

    // initially, the link tag might not be rendered by react-helmet-async yet
    if (stylesheetLinkTag) {
      stylesheetLinkTag.href = newTheme
    }

    Prism.highlightAll()
  }, [isDarkMode])

  return (
    <>
      <Helmet>
        <link id={STYLESHEET_DOM_ID} rel="stylesheet" href={theme} />
      </Helmet>

      <div className={classNames('code-block', className)} data-prismjs-copy-timeout="500">
        <pre className="code-block__code copy-to-clipboard">
          <code className={`language-${language}`} spellCheck={false} data-prismjs-copy="Copy to clipboard">
            {code}
          </code>
        </pre>
      </div>
    </>
  )
}

export default withRedux(CodeBlock)
