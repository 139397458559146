import { ONBOARDING_VIEWS, urls } from '../config/constants'

// eslint-disable-next-line
type ValueOfOnboardingViews = typeof ONBOARDING_VIEWS[number]

export const getOnboardingViewIndex = (viewName: ValueOfOnboardingViews) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return ONBOARDING_VIEWS.indexOf(viewName)
}

export const getNextOnboardingViewUrl = (currentViewName: ValueOfOnboardingViews) => {
  const currentViewIndex = getOnboardingViewIndex(currentViewName)
  const nextViewIndex = currentViewIndex + 1

  if (nextViewIndex >= ONBOARDING_VIEWS.length) {
    throw new Error('Reached onboarding end!')
  }

  const nextViewName = ONBOARDING_VIEWS[nextViewIndex]
  return urls[nextViewName]
}

export const getOnboardingViewProgress = (currentViewName: ValueOfOnboardingViews) => {
  const step = getOnboardingViewIndex(currentViewName) + 1

  return `STEP ${step} / ${ONBOARDING_VIEWS.length}`
}

export const getOnboardingViewUrlByViewName = (viewName: keyof typeof urls) => {
  return urls[viewName]
}
