import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'

const ESSENTIAL_COOKIES = [
  {
    id: 1,
    name: 'amplify-signin-with-hostedUI',
    functionality: 'Redirect to AWS for Login or not',
    provider: 'Amazon, Inc',
    dataCollected: '-',
    storagePeriod: '1 year',
    processedOutsideEEA: 'n/a',
    legalBasis: 'Art. 6(1) (b), (f) GDPR',
  },
  {
    id: 2,
    name: 'CognitoIdentityServiceProvider.*',
    functionality: 'Log In',
    provider: 'Amazon, Inc',
    dataCollected: 'Email verified, Email, Cognito Username',
    storagePeriod: '1 day',
    processedOutsideEEA: 'n/a',
    legalBasis: 'Art. 6(1) (b), (f) GDPR',
  },
  {
    id: 3,
    name: 'CognitoIdentityServiceProvider.*.LastAuthUser',
    functionality: 'Log In',
    provider: 'Amazon, Inc',
    dataCollected: 'Email',
    storagePeriod: '1 day',
    processedOutsideEEA: 'n/a',
    legalBasis: 'Art. 6(1) (b), (f) GDPR',
  },
  {
    id: 4,
    name: 'CognitoIdentityServiceProvider.*.clockDrift',
    functionality: 'Log In',
    provider: 'Amazon, Inc',
    dataCollected: '-',
    storagePeriod: '1 day',
    processedOutsideEEA: 'n/a',
    legalBasis: 'Art. 6(1) (b), (f) GDPR',
  },
  {
    id: 5,
    name: 'CognitoIdentityServiceProvider.*.refreshToken',
    functionality: 'Log In',
    provider: 'Amazon, Inc',
    dataCollected: 'Refresh Token',
    storagePeriod: '1 day',
    processedOutsideEEA: 'n/a',
    legalBasis: 'Art. 6(1) (b), (f) GDPR',
  },
  {
    id: 6,
    name: 'CognitoIdentityServiceProvider.*.accessToken',
    functionality: 'Log In',
    provider: 'Amazon, Inc',
    dataCollected: 'Access Token',
    storagePeriod: '1 day',
    processedOutsideEEA: 'n/a',
    legalBasis: 'Art. 6(1) (b), (f) GDPR',
  },
  {
    id: 7,
    name: 'CognitoIdentityServiceProvider.*.idToken',
    functionality: 'Log In',
    provider: 'Amazon, Inc',
    dataCollected: 'ID Token',
    storagePeriod: '1 day',
    processedOutsideEEA: 'n/a',
    legalBasis: 'Art. 6(1) (b), (f) GDPR',
  },
  {
    id: 8,
    name: 'tableau_locale',
    functionality: 'Session Cookie',
    provider: 'Tableau Software, LLC',
    dataCollected: 'Language',
    storagePeriod: 'Session',
    processedOutsideEEA: 'n/a',
    legalBasis: 'Art. 6(1) (b), (f) GDPR',
  },
  {
    id: 9,
    name: 'tableau_public_negotiated_locale',
    functionality: 'Session Cookie',
    provider: 'Tableau Software, LLC',
    dataCollected: 'Language',
    storagePeriod: 'Session',
    processedOutsideEEA: 'n/a',
    legalBasis: 'Art. 6(1) (b), (f) GDPR',
  },
]

const CookiePolicyView = () => (
  <ElementContainer>
    <h2 className="title mb-6">Cookie Policy</h2>

    <p className="is-size-7 mb-2">Last amended: February 17th, 2022</p>
    <p className="mb-2">
      This cookie policy applies to the following websites:{' '}
      <a href="https://datalyse.me" className="inline-link">
        datalyse.me
      </a>
      ,{' '}
      <a href="https://klarly.com" className="inline-link">
        klarly.com
      </a>{' '}
    </p>

    <p className="mb-2">
      The controller responsible for all the above-mentioned websites within the meaning of data protection law is Dataplanet GmbH (hereinafter referred to as
      “Klarly“), Neue Grünstraße 19 C, 10179 Berlin, hello@klarly.com.
    </p>
    <p className="mb-2">
      We have prepared this cookie policy to explain how Klarly uses cookies on its websites, what cookies they are, and how you can manage your cookie
      preferences.
    </p>

    <h3 className="subtitle mt-6">1. What are cookies?</h3>
    <p className="mb-2">
      A cookie is a small data file that is stored on your computer or smartphone when you visit a website. Cookies often contain personal information,
      so-called identifiers, such as unique cookie identifiers, language or shopping basket settings. This means that website users are distinguishable from
      each other. However, we cannot identify you by name from the information contained in the cookies.
    </p>
    <p className="mb-2">
      There are also other technologies that can be used to recognise website users (e.g. pixel tags, localStorage, HTML5). The collective term ‘cookies’ also
      covers these comparable tracking technologies here.
    </p>
    <p className="mb-2">
      We use cookies for different purposes. Some cookies are essential to ensure the security and operation of the website. Other cookies help us to understand
      how our visitors and customers use the website or to better market our websites on the internet (e.g. via social media).
    </p>
    <p className="mb-2">
      There are also so-called first-party and third-party cookies. First-party cookies are those placed by Klarly directly on the user’s device. Third-party
      cookies are cookies that originate from third parties, e.g. our service providers or advertising partners.
    </p>

    <h3 className="subtitle mt-6">2. What cookies does Klarly use?</h3>
    <p className="mb-2">
      We use cookies that are necessary for our websites to function. With your permission, we also use optional cookies for analysis purposes and to
      personalise content and ads on our websites.
    </p>
    <p className="mb-2">
      Please note that any changes you make to the settings only affect the respective browser. If you use different browsers or change to a different device,
      you will have to change the settings again and Klarly will ask for your permission to place certain cookies.
    </p>
    <p className="mb-2">Below you will find an overview of the cookies used on our websites:</p>

    <h3 className="subtitle mt-6">2.1 Essential cookies</h3>
    <p className="mb-2">
      These cookies are essential for the operation of our websites (e.g. displaying the correct font, adopting your browser’s language settings).
    </p>

    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Cookie name</th>
            <th>Function</th>
            <th>Provider</th>
            <th>Data collected</th>
            <th>Storage period</th>
            <th>Processing outside the EEA* and safeguards?</th>
            <th>Legal basis</th>
          </tr>
        </thead>
        <tbody>
          {ESSENTIAL_COOKIES.map((entry) => (
            <tr key={`essential-entry-${entry.id}`}>
              <td>
                <code>{entry.name}</code>
              </td>
              <td>{entry.functionality}</td>
              <td>{entry.provider}</td>
              <td>{entry.dataCollected}</td>
              <td>{entry.storagePeriod}</td>
              <td>{entry.processedOutsideEEA}</td>
              <td>{entry.legalBasis}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <p className="is-size-7">* EEA: European Economic Area</p>

    <h3 className="subtitle mt-6">2.2 Analysis cookies</h3>
    <p className="mb-2">
      Analysis cookies help us to understand how people use our websites. This allows us to improve the quality and content of our websites.
    </p>
    <p className="mb-2">
      We use service providers from third countries (e.g. US), which do not have a level of data protection comparable to that of the EU. Due to the law there,
      there is a risk that cookie IDs, IP addresses and similar data may be collected and analysed by the authorities of the third country and that the rights
      of data subjects under EU law may not be fully enforced.
    </p>
    <p className="mb-2">
      Your consent also extends to the transfer of data to third countries. You can withdraw your consent at any time by adjusting your cookie settings.
    </p>

    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Cookie name</th>
            <th>Function</th>
            <th>Provider</th>
            <th>Data collected</th>
            <th>Storage period</th>
            <th>Processing outside the EEA* and safeguards?</th>
            <th>Legal basis</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <code>_ga</code>
            </td>
            <td>Google Analytics</td>
            <td>Google, Inc. Google, Ireland Limited</td>
            <td>Anonymised IP address</td>
            <td>24 months</td>
            <td>Yes, US Safeguards: none, consent pursuant to Art. 49(1) (a) GDPR.</td>
            <td>Art. 6(1) (b), (f) GDPR</td>
          </tr>
          <tr>
            <td>
              <code>_gat</code>
            </td>
            <td>Google Analytics</td>
            <td>Google, Inc. Google, Ireland Limited</td>
            <td>Anonymised IP address</td>
            <td>1 day</td>
            <td>Yes, US Safeguards: none, consent pursuant to Art. 49(1) (a) GDPR.</td>
            <td>Art. 6(1) (b), (f) GDPR</td>
          </tr>
          <tr>
            <td>
              <code>_gid</code>
            </td>
            <td>Google Analytics</td>
            <td>Google, Inc. Google, Ireland Limited</td>
            <td>Anonymised IP address</td>
            <td>1 day</td>
            <td>Yes, US Safeguards: none, consent pursuant to Art. 49(1) (a) GDPR.</td>
            <td>Art. 6(1) (b), (f) GDPR</td>
          </tr>
          <tr>
            <td>
              <code>_gac_303795644</code>
            </td>
            <td>Google Analytics</td>
            <td>Google, Inc. Google, Ireland Limited</td>
            <td>Anonymised IP address</td>
            <td>90 days</td>
            <td>Yes, US Safeguards: none, consent pursuant to Art. 49(1) (a) GDPR.</td>
            <td>Art. 6(1) (b), (f) GDPR</td>
          </tr>
          <tr>
            <td>
              <code>_gtm_auth</code>
            </td>
            <td>Google Tag Manager</td>
            <td>Google, Inc. Google, Ireland Limited</td>
            <td>-</td>
            <td>Session</td>
            <td>n/a</td>
            <td>Art. 6(1) (b), (f) GDPR</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ElementContainer>
)

export default CookiePolicyView
