import './_footer.scss'

import { Link } from 'react-router-dom'

import { urls } from '../../config/constants'
import { CopyrightText } from '../Text/CopyrightText/CopyrightText'
import { Logo } from '../Text/Logo/Logo'
import { PageSubtitle } from '../Text/PageSubtitle/PageSubtitle'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-grid">
        <div className="footer-category">
          <Logo />
          <PageSubtitle className="has-text-left">No code data platform</PageSubtitle>
        </div>

        <div className="ft-category">
          <h5 className="ft-category__title">REPORTS</h5>
          <Link className="ft-category__link" to={urls.REPORT_EXAMPLES_VIEW}>
            Reports
          </Link>
        </div>

        <div className="ft-category">
          <h5 className="ft-category__title">PRICING</h5>
          <Link className="ft-category__link" to={urls.PLANS_PRICING_VIEW}>
            Pricing
          </Link>
        </div>

        <div className="ft-category">
          <h5 className="ft-category__title">CONTACT</h5>
          <Link className="ft-category__link" to={urls.HOME_VIEW}>
            About Us
          </Link>
          <Link className="ft-category__link" to={urls.IMPRINT_VIEW}>
            Imprint
          </Link>
          <Link className="ft-category__link" to={urls.COOKIE_POLICY_VIEW}>
            Cookie Policy
          </Link>
          <Link className="ft-category__link" to={urls.REQUEST_PASSWORD_RESET_VIEW}>
            Password Reset
          </Link>
        </div>

        <div className="footer-grid__single-row">
          <CopyrightText>&copy; Copyright {`${new Date().getFullYear()}`} Klarly. All rights reserved.</CopyrightText>
        </div>
      </div>
    </footer>
  )
}

export default Footer
