import './_klarly-input.scss'

import classNames from 'classnames'
import _get from 'lodash/get'
import React from 'react'
import { DeepMap, FieldError, FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form'

import { ReactComponent as BinDeleteSvg } from '../../../assets/svg/bin-delete.svg'
import { KlarlyInput, KlarlyInputProps } from './KlarlyInput'

type KlarlyFormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>
  rules?: RegisterOptions
  register: UseFormRegister<TFormValues>
  errors?: Partial<DeepMap<TFormValues, FieldError>>
  onDeleteClick?: () => void
} & Omit<KlarlyInputProps, 'name'>

export const KlarlyFormInput = <TFormValues extends Record<string, unknown>>({
  className,
  errors,
  id,
  label,
  name,
  onDeleteClick,
  register,
  rules,
  ...props
}: KlarlyFormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = _get(errors, name)
  const hasError = !!(errors && errorMessages)
  const hasDelete = !!onDeleteClick

  return (
    <div className={classNames('klarly-input', className)} aria-live="polite">
      <label className="klarly-input__label" htmlFor={id}>
        {label}
      </label>

      <div className={classNames('klarly-delete-input', { 'klarly-input--with-delete': hasDelete })}>
        {/*@ts-ignore*/}
        <KlarlyInput name={name} id={id} label={label} aria-invalid={hasError} className="klarly-input__input" {...props} {...register(name, rules)} />
        {hasDelete && <BinDeleteSvg className="klarly-delete-input__icon" onClick={onDeleteClick} />}
      </div>

      {hasError && <div className="error-msg">{JSON.stringify(errorMessages)}</div>}
    </div>
  )
}
