import { ReduxAction, UserReceivedAction } from '../../../types/redux/ReduxAction'
import { UserState } from '../../../types/redux/UserState'
import { AMPLIFY_USER_SIGNED_OUT } from '../amplify/actions'
import { USER_RECEIVED } from './actions'

export const makeDefaultUserState = (): UserState => ({
  createdAt: '',
  email: '',
  externalId: '',
  firstName: '',
  id: null,
  isAdmin: false,
  isDeleted: false,
  isFullAccess: false,
  lastName: '',
  project: null,
  updatedAt: '',
})

export default function data(state = makeDefaultUserState(), action: ReduxAction): UserState {
  const { type } = action

  switch (type) {
    case USER_RECEIVED: {
      const { payload } = action as UserReceivedAction

      return {
        ...state,
        createdAt: payload.createdAt,
        email: payload.email,
        externalId: payload.externalId,
        firstName: payload.firstName,
        id: payload.id,
        isDeleted: payload.isDeleted,
        isFullAccess: payload.isFullAccess,
        lastName: payload.lastName,
        project: payload.project,
        updatedAt: payload.updatedAt,
        isAdmin: payload.isAdmin || false,
      }
    }

    case AMPLIFY_USER_SIGNED_OUT: {
      return makeDefaultUserState()
    }

    default:
      return state
  }
}
