import './_maxWidthPageContainer.scss'

import { FunctionComponent, HTMLAttributes } from 'react'

import joinCssClassNames from '../../modules/joinCssClassNames'

export const MaxWidthPageContainer: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...rest }) => {
  const classNames = joinCssClassNames('max-width-container', className)

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  )
}
