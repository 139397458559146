import './_loadingAnimation.scss'

import joinCssClassNames from '../../modules/joinCssClassNames'

type OwnProps = {
  small?: boolean
}

export const LoadingAnimation = ({ small }: OwnProps) => (
  <div className={joinCssClassNames('loading', small ? 'loading--small' : undefined)}>
    <h3 className="h3">Loading</h3>
    <span className="loading__dot" />
    <span className="loading__dot loading__dot--2nd" />
    <span className="loading__dot loading__dot--3nd" />
  </div>
)
