import { APIResponseUser } from '../types/APITypes/APIResponseUser'
import { UserState } from '../types/redux/UserState'

class User {
  _userData: UserState

  constructor(response: APIResponseUser) {
    this._userData = response.data
  }

  get id() {
    return this._userData.id
  }

  get email() {
    return this._userData.email
  }

  get externalId() {
    return this._userData.externalId
  }

  get firstName() {
    return this._userData.firstName
  }

  get lastName() {
    return this._userData.lastName
  }

  get isAdmin() {
    return this._userData.isAdmin
  }

  get isFullAccess() {
    return this._userData.isFullAccess
  }

  get isDeleted() {
    return this._userData.isDeleted
  }

  get project() {
    if (this._userData.project) {
      return this._userData.project
    }

    return null
  }

  get createdAt() {
    return this._userData.createdAt
  }

  get updatedAt() {
    return this._userData.updatedAt
  }
}

export default User
