import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as BusinessModelIcon1 } from '../../../assets/svg/business-model-1.svg'
import { ReactComponent as BusinessModelIcon2 } from '../../../assets/svg/business-model-2.svg'
import { ReactComponent as BusinessModelIcon3 } from '../../../assets/svg/business-model-3.svg'
import { ReactComponent as BusinessModelIcon4 } from '../../../assets/svg/business-model-4.svg'
import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import { OnboardingTitle } from '../../../components/Onboarding/OnboardingTitle/OnboardingTitle'
import OnboardingBackButton from '../../../components/OnboardingBackButton/OnboardingBackButton'
import OnboardingButton from '../../../components/OnboardingButton/OnboardingButton'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { CopyText12Pt } from '../../../components/Text/CopyText12Pt/CopyText12Pt'
import { ONBOARDING_BUSINESS_MODEL_VIEW, ONBOARDING_SHOP_SOFTWARE_VIEW } from '../../../config/constants'
import { getNextOnboardingViewUrl, getOnboardingViewProgress, getOnboardingViewUrlByViewName } from '../../../modules/getNextOnboardingViewUrl'
import { clearOnboardingData, getOnboardingDataByViewName, setOnboardingDataByViewName } from '../../../modules/onboardingDataHandler'
import { OnboardingData } from '../../../types/OnboardingData'

const OPTIONS: OnboardingData[] = [
  { id: 1, label: 'Subscriptions', value: 1 },
  { id: 2, label: 'eCommerce', value: 2 },
  { id: 3, label: 'Blog', value: 3 },
  { id: 4, label: 'Other', value: 4 },
]

const ICONS = [BusinessModelIcon1, BusinessModelIcon2, BusinessModelIcon3, BusinessModelIcon4]

const OnboardingBusinessModelView = () => {
  const navigate = useNavigate()
  const viewName = ONBOARDING_BUSINESS_MODEL_VIEW
  const [activeOption, setActiveOption] = useState<null | OnboardingData>(() => getOnboardingDataByViewName(viewName) as OnboardingData)

  const goToShopSoftwareSelection = () => {
    const nextOnboardingUrl = getOnboardingViewUrlByViewName(ONBOARDING_SHOP_SOFTWARE_VIEW)
    return navigate(nextOnboardingUrl)
  }

  const onOptionClick = (option: OnboardingData) => {
    setOnboardingDataByViewName(viewName, option)
    setActiveOption(option)

    // eCommerce
    if (option.value === 2) {
      goToShopSoftwareSelection()
    } else {
      // we have to remove the eCommerce entry in case there was one set previously
      clearOnboardingData(ONBOARDING_SHOP_SOFTWARE_VIEW)
      goToNextStep()
    }
  }

  const goToNextStep = () => {
    // special case here, because we can either go to shop software (see onOptionClick) or to the contact form
    // hence, we assume (if "eCommerce" was not selected) there hat we skip the shop software view
    const nextOnboardingUrl = getNextOnboardingViewUrl(ONBOARDING_SHOP_SOFTWARE_VIEW)
    navigate(nextOnboardingUrl)
  }

  return (
    <ElementContainer>
      <div className="ob-progress">
        <CopyText12Pt>{getOnboardingViewProgress(viewName)}</CopyText12Pt>
      </div>

      <OnboardingTitle>What is your current business model?</OnboardingTitle>

      <section className="ob-options ob-options--four">
        {OPTIONS.map((option) => (
          <OnboardingButton
            key={`option-id-${option.id}`}
            onClick={() => onOptionClick(option)}
            option={option}
            isActive={option.id === (activeOption && activeOption.id)}
            icon={ICONS[option.value - 1]}
          />
        ))}
      </section>

      <div className="ob-cta-line">
        <OnboardingBackButton />
      </div>
    </ElementContainer>
  )
}

export default OnboardingBusinessModelView
