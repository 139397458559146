import * as Amplify from 'aws-amplify'
import { AmplifyConfig } from 'aws-amplify/lib-esm/Common/types/types'

import { API_GATEWAY_NAME } from '../config/constants'
import { isTestOrLocalHostEnvironment } from '../modules/environmentUtils'
import { getFullDomainName } from '../modules/getFullDomainName'
import { AllowedDevEnvironments } from '../types/AllowedDevEnvironments'

const getAPIEndpoint = () => {
  const { hostname } = document.location

  if (hostname === 'localhost') {
    return 'http://localhost:3001'
  }

  if (hostname === 'test.klarly.com') {
    return 'https://api-test.klarly.com'
  }

  return 'https://api.klarly.com'
}

const getConfiguration = (env: AllowedDevEnvironments = 'test'): AmplifyConfig => {
  const isTestEnv = env === 'test'
  const authenticationFlowType = 'USER_PASSWORD_AUTH'
  const region = 'eu-central-1'
  const cookieStorage = {
    domain: getFullDomainName(),
    expires: 365,
    path: '/',
    secure: env !== 'test',
  }
  const endpoint = getAPIEndpoint()

  return {
    API: {
      endpoints: [
        {
          name: API_GATEWAY_NAME,
          endpoint,
          region: 'eu-central-1',
        },
      ],
    },
    Auth: {
      authenticationFlowType,
      region,
      userPoolId: isTestEnv ? 'eu-central-1_ziwOGIQj6' : 'eu-central-1_9toz38epv',
      userPoolWebClientId: isTestEnv ? '3gpoarm277638jm2465qh2gu0j' : '1bi90892g6lptj6ji5gdflrik6',
      cookieStorage,
    },
  }
}

const env: AllowedDevEnvironments = isTestOrLocalHostEnvironment() ? 'test' : 'production'
Amplify.Amplify.configure(getConfiguration(env))
Amplify.API.configure(getConfiguration(env))
Amplify.Auth.configure(getConfiguration(env))

export const { API, Auth } = Amplify
