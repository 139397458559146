import './CookieConsent.scss'

import { SyntheticEvent, useEffect, useState } from 'react'

import { COOKIE_CONSENT_STORE, KEY_COOKIE_CONSENT_ANALYTICS, KEY_COOKIE_CONSENT_MANDATORY, urls } from '../../config/constants'
import { trackingService } from '../../services/TrackingService'
import ToggleSwitch from '../FormElements/ToggleSwitch'
import { PrimaryButton } from '../PrimaryButton/PrimaryButton'
import { CopyText16Pt } from '../Text/CopyText16Pt/CopyText16Pt'
import InlineLink from '../Text/InlineLink/InlineLink'

const checkboxLabelStyle = {
  justifyContent: 'space-between',
}

const CookieConsent = () => {
  const [show, setShow] = useState(() => false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [hasMarketingCookiesActive, setHasMarketingCookiesActive] = useState(true)

  useEffect(() => {
    dispatchInitialConsentSettings()
  }, [])

  const dispatchConsent = async ({ consentEssential = true, consentMarketing = false } = {}) => {
    await trackingService.trackEvent('consentUpdated', { consentEssential, consentMarketing })
  }

  const dispatchInitialConsentSettings = async () => {
    const cachedSettingMandatoryCookies = COOKIE_CONSENT_STORE.getItem(KEY_COOKIE_CONSENT_MANDATORY)

    if (!cachedSettingMandatoryCookies) {
      setShow(true)

      await dispatchConsent()
      return
    }

    await dispatchConsent({
      consentMarketing: hasMarketingCookiesActive,
    })
  }

  const onAcceptSelectionClick = async () => {
    COOKIE_CONSENT_STORE.setItem(KEY_COOKIE_CONSENT_MANDATORY, 'true')
    COOKIE_CONSENT_STORE.setItem(KEY_COOKIE_CONSENT_ANALYTICS, hasMarketingCookiesActive.toString())

    setShow(false)

    await dispatchConsent({
      consentMarketing: hasMarketingCookiesActive,
    })
  }

  const onAcceptAllClick = async () => {
    COOKIE_CONSENT_STORE.setItem(KEY_COOKIE_CONSENT_MANDATORY, 'true')
    COOKIE_CONSENT_STORE.setItem(KEY_COOKIE_CONSENT_ANALYTICS, 'true')

    await dispatchConsent({
      consentMarketing: true,
      consentEssential: true,
    })

    setShow(false)
  }

  const onCookieSettingsClick = (e: SyntheticEvent) => {
    e.preventDefault()

    setIsSettingsOpen(!isSettingsOpen)
  }

  const renderCookieSettings = () => (
    <>
      <h3 className="mb-4">Manage your cookie settings</h3>
      <CopyText16Pt>We us cookies for...</CopyText16Pt>
      <CopyText16Pt className="mb-4">Learn more about how we use cookies in our Cookie Policy.</CopyText16Pt>

      <div className="cookie-settings">
        <div className="cookie-settings__item">
          <ToggleSwitch id="cookies-mandatory" label="Essential cookies" disabled isActive style={checkboxLabelStyle} />
          <CopyText16Pt className="cookie-settings__text">
            Must be enabled. These cookies are essential for the operation of our website (e.g. display of the correct font etc.).
          </CopyText16Pt>
        </div>

        <div className="cookie-settings__item">
          <ToggleSwitch
            id="cookies-analytics"
            label="Analysis cookies"
            isActive={hasMarketingCookiesActive}
            style={checkboxLabelStyle}
            onSwitch={setHasMarketingCookiesActive}
          />
          <CopyText16Pt className="cookie-settings__text">
            Must be enabled. These cookies help us analyze how our website is being used. This enables us to improve the quality and content of our website.
          </CopyText16Pt>
        </div>
      </div>

      <div className="cookie-consent__accept">
        <PrimaryButton variation="secondary" onClick={onAcceptSelectionClick}>
          Save selection
        </PrimaryButton>

        <PrimaryButton onClick={onAcceptAllClick}>Accept all</PrimaryButton>
      </div>
    </>
  )

  const renderDefaultContent = () => (
    <div className="cookie-consent-text">
      <CopyText16Pt>
        We use Cookies and similar technologies that are necessary for the functioning of our website. With your consent, we also use Cookies for analysis
        purposes. You can see the{' '}
        <InlineLink to={'#'} onClick={onCookieSettingsClick}>
          settings
        </InlineLink>{' '}
        of the Cookies as well. Find further details in our{' '}
        <InlineLink className="inline-link" to={urls.COOKIE_POLICY_VIEW}>
          Cookie Policy
        </InlineLink>
        .
      </CopyText16Pt>

      <div className="cookie-consent__accept">
        <PrimaryButton variation="secondary" onClick={onCookieSettingsClick}>
          More options
        </PrimaryButton>
        <PrimaryButton onClick={onAcceptAllClick}>Accept</PrimaryButton>
      </div>
    </div>
  )

  if (!show) {
    return null
  }

  return (
    <div className="cookie-consent__wrapper">
      <div className="cookie-consent__container">{isSettingsOpen ? renderCookieSettings() : renderDefaultContent()}</div>
    </div>
  )
}

export default CookieConsent
