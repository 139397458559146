import _snakeCase from 'lodash/snakeCase'
import { useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'

import withRedux from '../../../hoc/withRedux'
import { getMultiEntryConfigs } from '../../../modules/userConfigurationFn'
import { APIPayloadUpdateConnection } from '../../../types/APITypes/APIPayloadUpdateConnection'
import { ListExistingProviderConfigProps } from '../../../types/ListExistingProviderConfigProps'
import { KlarlyFormInput } from '../../FormElements/ReactHookForm/KlarlyFormInput'
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton'
import ProgressBar from '../../ProgressBar/ProgressBar'

const ExistingGoogleAdsConnection = ({
  connectionData,
  onDeleteConnection,
  onDeleteConnectionEntry,
  onUpdateConnection,
  status,
}: ListExistingProviderConfigProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const MULTI_ENTRIES_REACT_FORM_NAME = `${_snakeCase(connectionData.provider.name)}_multiString`

  const multiEntryConfigs = getMultiEntryConfigs(connectionData)
  const multiEntryName = multiEntryConfigs[0]?.key.name
  const multiEntryType = multiEntryConfigs[0]?.key.type
  const multiEntryKeyId = multiEntryConfigs[0]?.key.id
  const multiEntryDefaultValues = multiEntryConfigs.map((entry) => ({ [multiEntryName]: entry.value, id: entry.id || null }))

  const { register, handleSubmit, getValues, control } = useForm({
    defaultValues: {
      [MULTI_ENTRIES_REACT_FORM_NAME]: multiEntryDefaultValues,
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: MULTI_ENTRIES_REACT_FORM_NAME, // unique name for the field array
  })

  const onFormSubmit: SubmitHandler<any> = (data, event) => {
    event?.preventDefault()
  }

  const onUpdateClick = async () => {
    setIsFetching(true)

    const values = getValues(MULTI_ENTRIES_REACT_FORM_NAME).map((configEntry) => ({
      id: configEntry.id,
      value: configEntry[multiEntryName],
      type: multiEntryType,
      configurationKeyId: multiEntryKeyId,
    }))

    const payload = {
      customerConfigurationMetaId: connectionData.id,
      providerId: connectionData.provider.id,
      values,
    } as APIPayloadUpdateConnection

    await onUpdateConnection(payload)
    setIsFetching(false)
  }

  const onDeleteClick = async () => {
    setIsFetching(true)

    await onDeleteConnection(connectionData.id)
    setIsFetching(false)
  }

  const onDeleteMultiEntryClick = async (index: number) => {
    setIsFetching(true)

    const entry = getValues(MULTI_ENTRIES_REACT_FORM_NAME)[index]
    const configurationId = entry.id

    if (configurationId && onDeleteConnectionEntry) {
      const isDeleted = await onDeleteConnectionEntry(configurationId)

      if (isDeleted) {
        remove(index)
      }
    }

    setIsFetching(false)
  }

  const onAddAnotherEntryClick = () => {
    append({ id: null })
  }

  return (
    <form className="existing-connections__form" onSubmit={handleSubmit(onFormSubmit)}>
      <p className="mb-3">
        <strong>Deployment Status</strong>
      </p>

      <ProgressBar progress={status} />

      {fields.map(({ id }, index) => {
        return (
          <KlarlyFormInput
            key={`multi-entry-input-${id}`}
            id={`lbl-${id}-${multiEntryConfigs[0].id}`}
            name={`${MULTI_ENTRIES_REACT_FORM_NAME}.${index}.${multiEntryName}`}
            label={multiEntryConfigs[0].key.label}
            register={register}
            rules={{ required: 'You must enter this.' }}
            onDeleteClick={() => onDeleteMultiEntryClick(index)}
          />
        )
      })}

      <div style={{ marginTop: '1rem', marginBottom: '2rem' }}>
        <PrimaryButton tiny onClick={onAddAnotherEntryClick} disabled={isFetching}>
          Add {multiEntryConfigs[0].key.label}
        </PrimaryButton>
      </div>

      <PrimaryButton tiny onClick={onUpdateClick} className="mr-3" disabled={isFetching}>
        Update
      </PrimaryButton>
      <PrimaryButton tiny onClick={onDeleteClick} disabled={isFetching}>
        Delete Connection
      </PrimaryButton>
    </form>
  )
}

export default withRedux(ExistingGoogleAdsConnection)
