import './OnboardingContactInfoView.scss'

import _isEmpty from 'lodash/isEmpty'
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import Input from '../../../components/FormElements/Input'
import { OnboardingTitle } from '../../../components/Onboarding/OnboardingTitle/OnboardingTitle'
import OnboardingBackButton from '../../../components/OnboardingBackButton/OnboardingBackButton'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { CopyText12Pt } from '../../../components/Text/CopyText12Pt/CopyText12Pt'
import {
  ONBOARDING_BUSINESS_MODEL_VIEW,
  ONBOARDING_COMPANY_SIZE_VIEW,
  ONBOARDING_CONTACT_INFO_VIEW,
  ONBOARDING_PLATFORMS_VIEW,
  ONBOARDING_SHOP_SOFTWARE_VIEW,
  SLACK_CHANNEL_NEW_USER,
  urls,
} from '../../../config/constants'
import { getOnboardingViewProgress } from '../../../modules/getNextOnboardingViewUrl'
import { isValidEmailFormat } from '../../../modules/isValidEmailFormat'
import { getOnboardingContactData, getOnboardingDataByViewName } from '../../../modules/onboardingDataHandler'
import { apiService } from '../../../services/APIService'
import { OnboardingData } from '../../../types/OnboardingData'

/*
await apiService.createHubSpotContact({
        businessModel: getOnboardingDataByViewName(ONBOARDING_BUSINESS_MODEL_VIEW)?.label as string,
        companySize: getOnboardingDataByViewName(ONBOARDING_COMPANY_SIZE_VIEW)?.label as string,
        eCommerce: getOnboardingDataByViewName(ONBOARDING_SHOP_SOFTWARE_VIEW)?.label as string,
        email,
        firstName: firstName || '',
        lastName: lastName || '',
        platforms: selectedPlatforms.join(', '),
        source: 'homepage',
      })

  const onSlackTestClick = async () => {
    const response = await apiService.sendSlackMessage({ channel: SLACK_CHANNEL_NEW_USER, text: 'incoming message from se website' })
    console.warn('Response', response)
  }


 */

const OnboardingContactInfoView = () => {
  const navigate = useNavigate()
  const viewName = ONBOARDING_CONTACT_INFO_VIEW
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  useEffect(() => {
    const cachedData = getOnboardingContactData()

    // password will not be cached in the browser cache!
    setFirstName(cachedData?.firstName || '')
    setLastName(cachedData?.lastName || '')
    setEmail(cachedData?.email || '')
  }, [])

  const onInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    if (name === 'firstName') {
      setFirstName(value)
    }

    if (name === 'lastName') {
      setLastName(value)
    }

    if (name === 'email') {
      setEmail(value)
    }
  }

  const isContinueDisabled = () => {
    const isInvalidEmail = !isValidEmailFormat(email)

    return _isEmpty(firstName) || _isEmpty(lastName) || isInvalidEmail
  }

  const onFormSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    const selectedPlatforms = getOnboardingDataByViewName(ONBOARDING_PLATFORMS_VIEW).map((platform: OnboardingData) => platform.label)

    try {
      await apiService.createHubSpotContact({
        businessModel: getOnboardingDataByViewName(ONBOARDING_BUSINESS_MODEL_VIEW)?.label as string,
        companySize: getOnboardingDataByViewName(ONBOARDING_COMPANY_SIZE_VIEW)?.label as string,
        eCommerce: getOnboardingDataByViewName(ONBOARDING_SHOP_SOFTWARE_VIEW)?.label as string,
        email,
        firstName: firstName || '',
        lastName: lastName || '',
        platforms: selectedPlatforms.join(', '),
        source: 'homepage',
      })
    } catch (e: unknown) {
      console.warn('Contact was already created on HubSpot')
    }

    try {
      await apiService.sendSlackMessage({ channel: SLACK_CHANNEL_NEW_USER, text: `A new user signed up on klarly.com: ${firstName} ${lastName} (${email})` })
    } catch (e: unknown) {
      console.warn('Could not send notification')
    }

    navigate(urls.ONBOARDING_ACCOUNT_CONFIRMATION_VIEW)
  }

  return (
    <ElementContainer>
      <div className="ob-progress">
        <CopyText12Pt>{getOnboardingViewProgress(viewName)}</CopyText12Pt>
      </div>

      <OnboardingTitle>Let us quickly know who you are!</OnboardingTitle>

      <form className="form" onSubmit={onFormSubmit}>
        <Input id="firstName" required placeholder="Insert first name..." label="First name" value={firstName} onChange={onInputChange} />
        <Input id="lastName" required placeholder="Insert last name..." label="Last name" value={lastName} onChange={onInputChange} />
        <Input id="email" type="email" required placeholder="Insert email..." label="Email" value={email} onChange={onInputChange} />
      </form>

      <div className="ob-cta-line">
        <OnboardingBackButton />

        <PrimaryButton medium onClick={onFormSubmit} disabled={isContinueDisabled()}>
          Continue
        </PrimaryButton>
      </div>
    </ElementContainer>
  )
}

export default OnboardingContactInfoView
