import _cloneDeep from 'lodash/cloneDeep'
import _remove from 'lodash/remove'
import _uniqBy from 'lodash/uniqBy'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import DAGSugiyama from '../../../components/D3/DAGSugiyama'
import Input from '../../../components/FormElements/Input'
import { LoadingAnimation } from '../../../components/LoadingAnimation/LoadingAnimation'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { CopyText16Pt } from '../../../components/Text/CopyText16Pt/CopyText16Pt'
import ViewSubtitle from '../../../components/Text/ViewSubtitle/ViewSubtitle'
import ViewTitle from '../../../components/Text/ViewTitle/ViewTitle'
import { D3_ANIMATION_DURATION, urls } from '../../../config/constants'
import { transformAPIDashboardDataToD3 } from '../../../modules/transformAPIDashboardDataToD3'
import { APIResponseConfigurationEntry } from '../../../types/APITypes/APIResponseConfiguration'
import { D3DagNodeType } from '../../../types/D3/D3DagNodeType'
import { SugiyamaTypes } from '../../../types/D3/SugiyamaTypes'
import exampleConfigurationData from './data/configurations-examples.json'
import exampleDashboardData from './data/dashboard-example.json'

type DemoInputType = {
  [key: string]: string
}

const DemoPageView = () => {
  const navigate = useNavigate()
  const [reportData, setReportData] = useState<SugiyamaTypes>()
  const [configurationToAdd, setConfigurationToAdd] = useState<APIResponseConfigurationEntry>()
  const [demoInputData, setDemoInputData] = useState<DemoInputType>({})
  const [storedNodes, setStoredNodes] = useState<SugiyamaTypes>([])
  const [canContinue, setCanContinue] = useState(false)

  useEffect(() => {
    const dagData = transformAPIDashboardDataToD3(exampleDashboardData.data)

    setReportData(dagData)

    /**
     * Pre-fill some data
     */
    const idsToPrefill = [1, 4]

    const alreadyStoredNodes = idsToPrefill.reduce<SugiyamaTypes>((previousValue, currentValue) => {
      previousValue.push(dagData[currentValue])
      return previousValue
    }, [])

    console.warn('ALREADY STORED', alreadyStoredNodes)

    const alreadyStoredDemoInputData = idsToPrefill.reduce<DemoInputType>(
      (previousValue, currentValue) => ({
        ...previousValue,
        [dagData[currentValue].id]: `Example input ${dagData[currentValue].id}`,
      }),
      {}
    )

    setDemoInputData(alreadyStoredDemoInputData)
    setStoredNodes(alreadyStoredNodes)
  }, [])

  const onNodeClick = (pointerEvent: PointerEvent, nodeData: D3DagNodeType) => {
    const configurationToAdd = exampleConfigurationData.data?.find((entry) => entry.name === nodeData.data.id)

    if (configurationToAdd) {
      // @ts-ignore
      setConfigurationToAdd(configurationToAdd)
    } else {
      setConfigurationToAdd(undefined)
    }
  }

  const onRenderInputChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = changeEvent

    setDemoInputData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onSaveConfigurationEntryClick = (name: string) => {
    const reportDataEntry = reportData?.find((entry) => entry.id === name)
    const storedNodesStateClone = _cloneDeep(storedNodes)

    if (!reportDataEntry) {
      return false
    }

    if (demoInputData[name].length > 0) {
      storedNodesStateClone.push(reportDataEntry)
      const uniqueStoredNodes = _uniqBy(storedNodesStateClone, 'id')
      setStoredNodes(uniqueStoredNodes)
    } else {
      _remove(storedNodesStateClone, (storedNodesEntry) => storedNodesEntry.id === name)
      setStoredNodes(storedNodesStateClone)
    }

    // + 1, because the final report node is not an actual node and must be added manually
    if (storedNodesStateClone.length + 1 === reportData?.length) {
      setTimeout(() => {
        setCanContinue(true)

        if (reportData) {
          setStoredNodes((prevState) => {
            const newState = _cloneDeep(prevState)
            newState.push(reportData[0])

            return newState
          })
        }
      }, D3_ANIMATION_DURATION)
    } else {
      setCanContinue(false)
    }
  }

  const renderInputs = (providerName: string) => {
    return (
      <Input
        key={`demo-input-type-${providerName}`}
        id={providerName}
        name={providerName}
        value={demoInputData && demoInputData[providerName]}
        label={`${providerName} Example Input`}
        onChange={onRenderInputChange}
        placeholder="Enter any example data, please"
        required
      />
    )
  }

  const isSaveEntryButtonDisabled = (name: string) => !demoInputData[name] || demoInputData[name].length === 0

  const onContinueClick = () => {
    navigate(urls.DEMO_PAGE_LOADING_VIEW)
  }

  return (
    <>
      <ViewTitle>Welcome to Klarly</ViewTitle>

      <ViewSubtitle>Demo page: Management Report</ViewSubtitle>

      <CopyText16Pt>
        Please click on the nodes below and fill out every node for your report result. Entering any random data / text is fine for this demo purpose 🙂
      </CopyText16Pt>

      {!reportData && <LoadingAnimation small />}
      {reportData && (
        <>
          <div className="config-wrapper">
            <DAGSugiyama d3Data={reportData} onNodeClick={onNodeClick} storedNodes={storedNodes} />

            <div className="config-wrapper__configs">
              {configurationToAdd && (
                <>
                  {renderInputs(configurationToAdd.name)}{' '}
                  <div style={{ marginTop: '1rem' }}>
                    <PrimaryButton
                      small
                      onClick={() => onSaveConfigurationEntryClick(configurationToAdd?.name)}
                      disabled={isSaveEntryButtonDisabled(configurationToAdd?.name)}
                    >
                      Save entry
                    </PrimaryButton>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="config-wrapper__button-line">
            <PrimaryButton small disabled={!canContinue} onClick={onContinueClick}>
              Continue
            </PrimaryButton>
          </div>
        </>
      )}
    </>
  )
}

export default DemoPageView
