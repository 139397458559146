import { isTestOrLocalHostEnvironment } from '../modules/environmentUtils'
import { makeCDNLink } from '../modules/makeCDNLink'
import { ExampleReport } from '../types/ExampleReport'

export const SUPPORT_EMAIL_ADDRESS = 'icansee@klarly.com'
export const IS_MVP_VERSION = false

export const API_GATEWAY_NAME = 'APIGateway'
export const COOKIE_CONSENT_STORE = localStorage
export const ENDPOINT_API_DATA_WAREHOUSE = 'https://lla6tna219.execute-api.eu-central-1.amazonaws.com/api'
export const KEY_COOKIE_CONSENT_ANALYTICS = 'KLARLY_CONSENT_ANALYTICS'
export const KEY_COOKIE_CONSENT_MANDATORY = 'KLARLY_CONSENT_MANDATORY'
export const LOCAL_TEST_HOSTNAMES = ['localhost', 'test.datalyse.me', 'test.klarly.com']
export const MIN_LENGTH_PASSWORD = 6
export const ONBOARDING_BUSINESS_MODEL_VIEW = 'ONBOARDING_BUSINESS_MODEL_VIEW'
export const ONBOARDING_COMPANY_SIZE_VIEW = 'ONBOARDING_COMPANY_SIZE_VIEW'
export const ONBOARDING_CONTACT_CONFIRMATION_MVP_VIEW = 'ONBOARDING_CONTACT_CONFIRMATION_MVP_VIEW'
export const ONBOARDING_CONTACT_INFO_VIEW = 'ONBOARDING_CONTACT_INFO_VIEW'
export const ONBOARDING_CONTACT_INFO_WITHOUT_PROJECT_MVP_VIEW = 'ONBOARDING_CONTACT_INFO_WITHOUT_PROJECT_MVP_VIEW'
export const ONBOARDING_HUBSPOT_WIDGET_VIEW = 'ONBOARDING_HUBSPOT_WIDGET_VIEW'
export const ONBOARDING_PLATFORMS_VIEW = 'ONBOARDING_PLATFORMS_VIEW'
export const ONBOARDING_SHOP_SOFTWARE_VIEW = 'ONBOARDING_SHOP_SOFTWARE_VIEW'
export const ONBOARDING_ACCOUNT_CONFIRMATION_VIEW = 'ONBOARDING_ACCOUNT_CONFIRMATION_VIEW'
export const GENERIC_ERROR_MESSAGE = 'An unknown error occurred. Please try again or reach out to the support team.'

export const LOCAL_STORAGE_PW_RESET_DESTINATION_KEY = 'PW_RESET_DESTINATION'
export const LOCAL_STORAGE_PW_RESET_EMAIL_KEY = 'PW_RESET_EMAIL'
export const LOCAL_STORAGE_IS_LIGHT_MODE_KEY = 'IS_LIGHT_MODE'
// @see https://api.slack.com/apps/A04NUEKSUUD/incoming-webhooks?
export const SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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'
export const SLACK_CHANNEL_NEW_USER = 'website-notifications'
export const D3_ANIMATION_DURATION = 1000

const DEFAULT_ONBOARDING_VIEWS = [
  ONBOARDING_COMPANY_SIZE_VIEW,
  ONBOARDING_PLATFORMS_VIEW,
  ONBOARDING_BUSINESS_MODEL_VIEW,
  ONBOARDING_SHOP_SOFTWARE_VIEW,
  ONBOARDING_CONTACT_INFO_VIEW,
  ONBOARDING_ACCOUNT_CONFIRMATION_VIEW,
] as const

// Order is important!
export const ONBOARDING_VIEWS = DEFAULT_ONBOARDING_VIEWS

// we have to enforce the typing here, which cannot be implied sadly
export const urls = {
  // all the following view names MUST match the variable
  ADMIN_VIEW: '/app/admin',
  CONFIGURATION_OVERVIEW_VIEW: '/app/configuration',
  CONFIRM_NEW_PASSWORD_VIEW: '/reset-password/confirm',
  CONTACT_VIEW: '/app/contact',
  COOKIE_POLICY_VIEW: '/cookie-policy',
  DEMO_PAGE_LOADING_VIEW: '/demo/loading',
  DEMO_PAGE_REPORT_VIEW: '/demo/report',
  DEMO_PAGE_VIEW: '/demo',
  DOCUMENTATION_TABLEAU: '/doc/report-setup',
  EXAMPLE_REPORT_VIEW: '/report/:id',
  HOME_VIEW: '/',
  IMPRINT_VIEW: '/imprint',
  LOGIN_VIEW: '/login',
  LOGOUT_VIEW: '/logout',
  ONBOARDING_ACCOUNT_CONFIRMATION_VIEW: '/onboarding/user/confirm',
  ONBOARDING_BUSINESS_MODEL_VIEW: '/onboarding/business-model',
  ONBOARDING_COMPANY_SIZE_VIEW: '/onboarding/company-size',
  ONBOARDING_CONTACT_CONFIRMATION_MVP_VIEW: '/onboarding/contact/confirm',
  ONBOARDING_CONTACT_INFO_VIEW: '/onboarding/contact',
  ONBOARDING_PLATFORMS_VIEW: '/onboarding/platforms',
  ONBOARDING_SHOP_SOFTWARE_VIEW: '/onboarding/shop-software',
  PLANS_PRICING_VIEW: '/plans-pricing-1',
  REPORTS_OVERVIEW_VIEW: '/app/reports',
  REPORT_CONFIGURATION_VIEW: '/app/dashboard/configuration/:id',
  REPORT_DETAIL_VIEW: '/app/dashboard/:id',
  REPORT_DETAIL_VIEW_WITHOUT_ID: '/app/dashboard',
  REPORT_EXAMPLES_VIEW: '/report-examples',
  REQUEST_PASSWORD_RESET_VIEW: '/reset-password',
  WELCOME_VIEW: '/app/welcome',
}

export const pageTitles = {
  [urls.ADMIN_VIEW]: 'Admin | Klarly',
  [urls.CONFIGURATION_OVERVIEW_VIEW]: 'Reports Configuration Overview | Klarly',
  [urls.CONTACT_VIEW]: 'Contact | Klarly',
  [urls.COOKIE_POLICY_VIEW]: 'Cookie Policy | Klarly',
  [urls.DEMO_PAGE_LOADING_VIEW]: 'Demo Loading | Klarly',
  [urls.DEMO_PAGE_REPORT_VIEW]: 'Demo Report | Klarly',
  [urls.DEMO_PAGE_VIEW]: 'Demo | Klarly',
  [urls.HOME_VIEW]: 'Home | Klarly',
  [urls.IMPRINT_VIEW]: 'Imprint | Klarly',
  [urls.ONBOARDING_BUSINESS_MODEL_VIEW]: 'Onboarding Business model | Klarly',
  [urls.ONBOARDING_COMPANY_SIZE_VIEW]: 'Onboarding Company size | Klarly',
  [urls.ONBOARDING_CONTACT_CONFIRMATION_MVP_VIEW]: 'Onboarding Contact Confirmation | Klarly',
  [urls.ONBOARDING_CONTACT_INFO_VIEW]: 'Onboarding Contact info | Klarly',
  [urls.ONBOARDING_PLATFORMS_VIEW]: 'Onboarding Platforms | Klarly',
  [urls.ONBOARDING_SHOP_SOFTWARE_VIEW]: 'Onboarding ECommerce | Klarly',
  [urls.PLANS_PRICING_VIEW]: 'Plans & Pricing | Klarly',
  [urls.REPORTS_OVERVIEW_VIEW]: 'Reports Overview | Klarly',
  [urls.REPORT_CONFIGURATION_VIEW]: 'Configuration Overview | Klarly',
  [urls.REPORT_EXAMPLES_VIEW]: 'Report Examples | Klarly',
  [urls.REQUEST_PASSWORD_RESET_VIEW]: 'Reset Password | Klarly',
  [urls.WELCOME_VIEW]: 'Welcome | Klarly',
}

export const EXAMPLE_REPORTS: ExampleReport[] = [
  {
    id: 1,
    title: 'Management Cockpit',
    thumbnail: `${makeCDNLink('prelogin/example-reports/management-report-thumbnail.webp')}`,
    thumbnailWhite: `${makeCDNLink('prelogin/example-reports/management-report-thumbnail-white.webp')}`,
    tableauLink:
      'https://reporting.klarly.com/#/views/A_ManagementCockpitExample_16572183040530/Overview?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    tableauLinkWhite:
      'https://reporting.klarly.com/#/views/A_ManagementCockpitExampleW/Overview?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    description:
      '<p class="copy-text-16">Track the daily performance of your company, with the key performance indicators that drive your business. Connecting all data together to bring a simple overview.</p>',
  },
  {
    id: 2,
    title: 'Orders Deep Dive',
    thumbnail: `${makeCDNLink('prelogin/example-reports/orders-deep-dive-thumbnail.webp')}`,
    thumbnailWhite: `${makeCDNLink('prelogin/example-reports/orders-deep-dive-thumbnail-white.webp')}`,
    tableauLink:
      'https://reporting.klarly.com/#/views/B_OrdersReport/OrdersDeepDive?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    tableauLinkWhite:
      'https://reporting.klarly.com/#/views/W_B_OrdersReport/OrdersDeepDive?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    description:
      '<p class="copy-text-16">Track and understand what is contributing most to revenues. Use the filters to take a deep dive into the orders and understand what is working and what is not working.</p>',
  },
  {
    id: 3,
    title: 'Customer Lifetime Value',
    thumbnail: `${makeCDNLink('prelogin/example-reports/clv-thumbnail.webp')}`,
    thumbnailWhite: `${makeCDNLink('prelogin/example-reports/clv-thumbnail-white.webp')}`,
    tableauLink:
      'https://reporting.klarly.com/#/views/C_CustomerLifetimeValues/CLV?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    tableauLinkWhite:
      'https://reporting.klarly.com/#/views/W_C_CustomerLifetimeValues/CLV?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    description:
      '<p class="copy-text-16">Use the customer lifetime dashboard to understand how well cohorts are performing on a monthly basis. Contributions to Each month shows the value of cohort first month in Green. Followed by their monthly contributions in purple and then finally their forecasted contributions in Orange.</p><p class="copy-text-16">Use the CLV chart to track Customer Lifetime values over each month.</p>',
  },
  {
    id: 4,
    title: 'Marketing Attribution',
    thumbnail: `${makeCDNLink('prelogin/example-reports/marketing-attribution-thumbnail.webp')}`,
    thumbnailWhite: `${makeCDNLink('prelogin/example-reports/marketing-attribution-thumbnail-white.webp')}`,
    tableauLink:
      'https://reporting.klarly.com/#/views/D_MarketingAttribution_16572181843830/MarketingAttribution?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    tableauLinkWhite:
      'https://reporting.klarly.com/#/views/W_D_MarketingAttribution/MarketingAttribution?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    description:
      '<p class="copy-text-16">Marketing attribution dashboard uses custom attribution logics to help marketers understand which channels and campaigns are returning on their investments. Post an iOS 14 world, attribution has become harder. Klarly uses AI to confidently attribute orders and sales to the right campaigns.</p>',
  },
  {
    id: 5,
    title: 'Cohorts Report',
    thumbnail: `${makeCDNLink('prelogin/example-reports/cohorts-thumbnail.webp')}`,
    thumbnailWhite: `${makeCDNLink('prelogin/example-reports/cohorts-thumbnail-white.webp')}`,
    tableauLink: 'https://reporting.klarly.com/#/views/E_CustomerCohorts/Cohorts?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    tableauLinkWhite:
      'https://reporting.klarly.com/#/views/W_E_CustomerCohorts/Cohorts?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    description: '<p class="copy-text-16">Track customer cohorts and understand monthly retention rates with the cohorts dashboard.</p>',
  },
  {
    id: 6,
    title: 'Retention Forecasts',
    thumbnail: `${makeCDNLink('prelogin/example-reports/retention-thumbnail.webp')}`,
    thumbnailWhite: `${makeCDNLink('prelogin/example-reports/retention-thumbnail-white.webp')}`,
    tableauLink:
      'https://reporting.klarly.com/#/views/F_ForecastCurves/ForecastCurve?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    tableauLinkWhite:
      'https://reporting.klarly.com/#/views/W_F_ForecastCurves/ForecastCurve?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    description:
      '<p class="copy-text-16">The Forecast curve dashboard allows subscription based businesses to understand which subscriptions have the better retention and where the biggest drops are coming from.</p>',
  },
  {
    id: 7,
    title: 'Audiences',
    thumbnail: `${makeCDNLink('prelogin/example-reports/google-analytics-thumbnail.webp')}`,
    thumbnailWhite: `${makeCDNLink('prelogin/example-reports/google-analytics-thumbnail-white.webp')}`,
    tableauLink:
      'https://reporting.klarly.com/#/views/G_GoogleAnalyticsAudienceDataExample/UserAffinityTypes?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    tableauLinkWhite:
      'https://reporting.klarly.com/#/views/W_G_GoogleAnalyticsAudienceDataExample/UserAffinityTypes?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    description: '<p class="copy-text-16">Understanding your website users and their attributes is easy to see with the google audiences dashboard.</p>',
  },
  {
    id: 8,
    title: 'Funnel Analysis',
    thumbnail: `${makeCDNLink('prelogin/example-reports/funnel-analysis-thumbnail.webp')}`,
    thumbnailWhite: `${makeCDNLink('prelogin/example-reports/funnel-analysis-thumbnail-white.webp')}`,
    tableauLink:
      'https://reporting.klarly.com/#/views/H_FunnelAnalysis_16572181486560/DownloadFunnel?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    tableauLinkWhite:
      'https://reporting.klarly.com/#/views/W_H_FunnelAnalysis/DownloadFunnel?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
    description: '<p class="copy-text-16">Use the funnel report to track the downloads to purchase and the conversion rates between each step.</p>',
  },
]

export const debugSettings = {
  ENABLE_REDUX_LOGGER: isTestOrLocalHostEnvironment(),
  ENABLE_TRACKING_LOGGER: !isTestOrLocalHostEnvironment(),
}

export const POST_LOGIN_LANDING_VIEW = urls.WELCOME_VIEW
