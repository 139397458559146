import { Dispatch } from 'react'

import { apiService } from '../../../services/APIService'
import { ConfigurationsReceivedAction } from '../../../types/redux/ReduxAction'

export const CONFIGURATIONS_RECEIVED_ACTION = 'configuration/RECEIVED'

type AllowedConfigurationsDispatchTypes = ConfigurationsReceivedAction

type ActionCreatorsType = {
  fetchConfigurations: () => (action: Dispatch<AllowedConfigurationsDispatchTypes>) => Promise<void>
}

export const configurationsActionCreators: ActionCreatorsType = {
  fetchConfigurations: () => async (dispatch) => {
    try {
      const payload = await apiService.fetchConfigurations()
      dispatch({
        type: CONFIGURATIONS_RECEIVED_ACTION,
        payload,
      })
    } catch (e: any) {
      console.warn('Error fetching configurations', e)
    }
  },
}
