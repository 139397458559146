import { ConfigurationsState } from '../../../types/redux/ConfigurationsState'
import { ConfigurationsReceivedAction, ReduxAction } from '../../../types/redux/ReduxAction'
import { CONFIGURATIONS_RECEIVED_ACTION } from './actions'

export const makeDefaultState = (): ConfigurationsState => ({
  data: null,
})

export default function data(state = makeDefaultState(), action: ReduxAction): ConfigurationsState {
  switch (action.type) {
    case CONFIGURATIONS_RECEIVED_ACTION: {
      const { payload } = action as ConfigurationsReceivedAction

      return payload
    }

    default:
      return state
  }
}
