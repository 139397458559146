import './_passwordReset.scss'

import _isEmpty from 'lodash/isEmpty'
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import Input from '../../../components/FormElements/Input'
import { ErrorMessage } from '../../../components/Messages/Messages'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { CopyText16Pt } from '../../../components/Text/CopyText16Pt/CopyText16Pt'
import { LOCAL_STORAGE_PW_RESET_DESTINATION_KEY, LOCAL_STORAGE_PW_RESET_EMAIL_KEY, MIN_LENGTH_PASSWORD, urls } from '../../../config/constants'
import { userService } from '../../../services/UserService'

const ConfirmNewPasswordView = () => {
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>(null)
  const [email, setEmail] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const pwResetCodeDestination = localStorage.getItem(LOCAL_STORAGE_PW_RESET_DESTINATION_KEY)
  const pwResetEmail = localStorage.getItem(LOCAL_STORAGE_PW_RESET_EMAIL_KEY)

  useEffect(() => {
    if (pwResetEmail) {
      setEmail(pwResetEmail)
    }
  }, [])

  const onInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    if (name === 'code') {
      setCode(value)
    }

    if (name === 'password') {
      setNewPassword(value)
    }
  }

  const onSubmitButtonClick = async (e: SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      await userService.confirmNewPassword({ email, newPassword, code })
      await userService.signIn({ username: email, password: newPassword })
      setIsLoading(false)
      localStorage.removeItem(LOCAL_STORAGE_PW_RESET_EMAIL_KEY)
      localStorage.removeItem(LOCAL_STORAGE_PW_RESET_DESTINATION_KEY)
      return navigate(urls.REPORTS_OVERVIEW_VIEW)
    } catch (e: any) {
      if (e && e.message) {
        setError(e.message)
      } else {
        setError('There was an error confirming your new password. Please try again.')
      }

      setIsLoading(false)
    }
  }

  const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      await onSubmitButtonClick(event)
    }
  }

  const isSubmitButtonDisabled = () => isLoading || _isEmpty(code) || _isEmpty(newPassword) || newPassword.length < MIN_LENGTH_PASSWORD

  return (
    <ElementContainer>
      <div className="reset-pw-title">
        <h3 className="reset-pw-title__main">Change your password</h3>
        <CopyText16Pt>{`Please enter your new password and the code that was delivered to ${
          pwResetCodeDestination ? pwResetCodeDestination : 'your email address.'
        }`}</CopyText16Pt>
      </div>

      <fieldset className="login-fieldset">
        {!pwResetEmail && (
          <div className="login-fieldset__element">
            <Input id="email" value={email} required placeholder="Your email address..." label="Email" onChange={onInputChange} />
          </div>
        )}

        <div className="login-fieldset__element">
          <Input id="code" value={code} required placeholder="Code from email..." label="Confirmation Code" onChange={onInputChange} />
        </div>

        <div className="login-fieldset__element">
          <Input
            id="password"
            type="password"
            value={newPassword}
            required
            placeholder="New password..."
            label={`New Password (min ${MIN_LENGTH_PASSWORD} char)`}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
        </div>

        {error && <ErrorMessage message={error} />}

        <div className="button-line button-line--single">
          <PrimaryButton medium onClick={onSubmitButtonClick} disabled={isSubmitButtonDisabled()}>
            Submit
          </PrimaryButton>
        </div>
      </fieldset>
    </ElementContainer>
  )
}

export default ConfirmNewPasswordView
