import './_page-not-found-view.scss'

import { CopyText16Pt } from '../../components/Text/CopyText16Pt/CopyText16Pt'
import ViewTitle from '../../components/Text/ViewTitle/ViewTitle'

const PageNotFoundView = () => (
  <>
    <ViewTitle>Aw jeez. That page has gone missing.</ViewTitle>

    <div className="illu-404__wrapper">
      <div className="illu-404">
        <div className="illu-404__number">4</div>
        <div className="illu-404__avatar">
          <div className="illu-404__circle"></div>
          <div className="illu-404__clip">
            <div className="illu-404__paper">
              <div className="illu-404__face">
                <div className="illu-404__eyes">
                  <div className="illu-404__eye illu-404__eye--left"></div>
                  <div className="illu-404__eye illu-404__eye--right"></div>
                </div>
                <div className="illu-404__cheeks illu-404__cheeks--left"></div>
                <div className="illu-404__cheeks illu-404__cheeks--right"></div>
                <div className="illu-404__mouth"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="illu-404__number">4</div>
      </div>
    </div>

    <CopyText16Pt>The page you are looking for does not exist.</CopyText16Pt>
    <CopyText16Pt>
      How you got there is a mystery. But you can click on our logo to go back to the homepage. We&apos;re sorry for the inconveniences!
    </CopyText16Pt>
  </>
)

export default PageNotFoundView
