import { combineReducers } from 'redux'

import { ReduxAction } from '../../types/redux/ReduxAction'
import reduxModules from '../modules'

const reducers = reduxModules.reduce((acc: Record<string, any>, { key, reducer }) => {
  acc[key] = reducer
  return acc
}, {})

const appReducer = combineReducers(reducers)

export default function rootReducer(state: any, action: ReduxAction) {
  return appReducer(state, action)
}
