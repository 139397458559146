import './_view-subtitle.scss'

import { FunctionComponent, HTMLAttributes } from 'react'

import joinCssClassNames from '../../../modules/joinCssClassNames'

const ViewSubtitle: FunctionComponent<HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...rest }) => {
  return (
    <h4 className={joinCssClassNames('h4 view-subtitle', className)} {...rest}>
      {children}
    </h4>
  )
}

export default ViewSubtitle
