import './_plansPricingView.scss'

import { useNavigate } from 'react-router-dom'

import { HeroSection } from '../../../components/HeroSection/HeroSection'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { HeroTitle } from '../../../components/Text/HeroTitle/HeroTitle'
import { PageSubtitle } from '../../../components/Text/PageSubtitle/PageSubtitle'
import { urls } from '../../../config/constants'

type Plan = {
  id: number
  title: string
  ctaText: string
  note?: string
  list: Array<string>
}

type PlanList = Array<Plan>

const PLANS: PlanList = [
  {
    id: 1,
    title: 'Startup program',
    ctaText: '1-month free access',
    note: 'Startup Program; no strings attached',
    list: [
      'No tech or IT team needed',
      'Deployed & maintained cloud data-warehouse',
      'Data refreshed daily',
      'Marketing, Tracking, Web & App data automatically integrated',
      'Full reporting suite',
      'Whole BI team for the cost of an intern',
      'Over 300 data connectors & counting',
      'GDPR compliant and SSL secure',
      'White glove onboarding',
      'Trustable, proven formulas for CLV, deferred revenue & attribution',
      'Response time within 72 hours',
      'from 899€ per month',
    ],
  },
  {
    id: 2,
    title: 'Enterprise version',
    ctaText: 'From 3999€ per month',
    list: [
      'Dedicated account manager',
      'SQL access to cloud data-warehouse',
      'Data refreshed hourly',
      'Customisable dashboards',
      'response time within 24 hours',
      'from 3999€',
    ],
  },
]

const PlansPricingView = () => {
  const navigate = useNavigate()

  const onCTAButtonClick = () => {
    navigate(urls.ONBOARDING_COMPANY_SIZE_VIEW)
  }

  return (
    <>
      <HeroSection>
        <HeroTitle>Pricing</HeroTitle>
        <PageSubtitle centered>From small to large businesses Klarly has all your data & reporting needs covered.</PageSubtitle>

        <section className="pricing">
          {PLANS.map(({ id, title, list, ctaText, note }) => (
            <div className={`card pricing-card ${id === 1 ? 'pricing-card--highlight' : ''}`} key={`pricing-card-${id}`}>
              <h3 className="pricing-features__title">{title}</h3>
              <ul>
                {list.map((feature, idx) => (
                  <li key={`pricing-card-${id}-feature-${idx}`}>{feature}</li>
                ))}
              </ul>

              <PrimaryButton
                className={`pricing-features__cta ${id === 1 ? 'pricing-features__cta--startup' : ''} ${note ? 'mb-4' : ''}`}
                variation={`${id === 1 ? 'secondary' : 'primary'}`}
                onClick={onCTAButtonClick}
              >
                {ctaText}
              </PrimaryButton>

              {note && <p className="pricing-features__note">{note}</p>}
            </div>
          ))}
        </section>
      </HeroSection>
    </>
  )
}

export default PlansPricingView
