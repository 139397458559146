import './_homePageView.scss'

import { useNavigate } from 'react-router-dom'

import { ReactComponent as FacebookSVG } from '../../../assets/svg/data-sources-logo/facebook.svg'
import { ReactComponent as GoogleAdsSVG } from '../../../assets/svg/data-sources-logo/google-ads.svg'
import { ReactComponent as AnalyticsSVG } from '../../../assets/svg/data-sources-logo/google-analytics.svg'
import { ReactComponent as GooglePlaySVG } from '../../../assets/svg/data-sources-logo/google-play.svg'
import { ReactComponent as InstagramSVG } from '../../../assets/svg/data-sources-logo/instagram.svg'
import { ReactComponent as StripeSVG } from '../../../assets/svg/data-sources-logo/stripe.svg'
import { GetInTouch } from '../../../components/GetInTouch/GetInTouch'
import { HeroSection } from '../../../components/HeroSection/HeroSection'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { CopyText12Pt } from '../../../components/Text/CopyText12Pt/CopyText12Pt'
import { CopyText16Pt } from '../../../components/Text/CopyText16Pt/CopyText16Pt'
import { HeroTitle } from '../../../components/Text/HeroTitle/HeroTitle'
import { PageSubtitle } from '../../../components/Text/PageSubtitle/PageSubtitle'
import { PageTitle } from '../../../components/Text/PageTitle/PageTitle'
import { urls } from '../../../config/constants'
import withRedux from '../../../hoc/withRedux'
import { makeCDNLink } from '../../../modules/makeCDNLink'
import { AppProps } from '../../../types/AppProps'

const COMPANY_LIST = [
  {
    id: 1,
    name: 'MindDoc',
    image: `${makeCDNLink('prelogin/trusted-logos/minddoc.webp')}`,
    imageInverted: `${makeCDNLink('prelogin/trusted-logos/inverted/minddoc.webp')}`,
  },
  {
    id: 2,
    name: 'Mindshine',
    image: `${makeCDNLink('prelogin/trusted-logos/mindshine.webp')}`,
    imageInverted: `${makeCDNLink('prelogin/trusted-logos/inverted/mindshine.webp')}`,
  },
  {
    id: 3,
    name: 'Bulldog Mindset',
    image: `${makeCDNLink('prelogin/trusted-logos/bulldog-mindset.webp')}`,
    imageInverted: `${makeCDNLink('prelogin/trusted-logos/inverted/bulldog-mindset.webp')}`,
  },
  {
    id: 4,
    name: 'Hemden.de',
    image: `${makeCDNLink('prelogin/trusted-logos/hemden.webp')}`,
    imageInverted: `${makeCDNLink('prelogin/trusted-logos/inverted/hemden.webp')}`,
  },
  {
    id: 5,
    name: 'Contract Hero',
    image: `${makeCDNLink('prelogin/trusted-logos/contract-hero.webp')}`,
    imageInverted: `${makeCDNLink('prelogin/trusted-logos/inverted/contract-hero.webp')}`,
  },
  {
    id: 6,
    name: 'torq partners',
    image: `${makeCDNLink('prelogin/trusted-logos/torq-partners.webp')}`,
    imageInverted: `${makeCDNLink('prelogin/trusted-logos/inverted/torq-partners.webp')}`,
  },
  {
    id: 7,
    name: 'Skill Yoga',
    image: `${makeCDNLink('prelogin/trusted-logos/skillyoga.webp')}`,
    imageInverted: `${makeCDNLink('prelogin/trusted-logos/inverted/skillyoga.webp')}`,
  },
]

const ITCV = [
  {
    id: 1,
    title: 'Integrate',
    description: 'All data sources integrated and stored in a cloud data-warehouse.',
    image: `${makeCDNLink('prelogin/home/section-integrate.webp')}`,
  },
  {
    id: 2,
    title: 'Transform & Combine',
    description:
      'Data is cleaned & organised into easy to understand schemas and tables. Data is combined together to answer simple to complex business questions.',
    image: `${makeCDNLink('prelogin/home/section-transform-combine.webp')}`,
  },
  {
    id: 3,
    title: 'Visualise',
    description: 'Visualise data with tables & graphs with a portfolio of dashboards & reports',
    image: `${makeCDNLink('prelogin/home/section-visualise.webp')}`,
  },
]

const DATA_SOURCES = [
  {
    id: 1,
    title: 'Instagram',
    SVGIcon: InstagramSVG,
  },
  {
    id: 2,
    title: 'Google Ads',
    SVGIcon: GoogleAdsSVG,
  },
  {
    id: 3,
    title: 'Google Play',
    SVGIcon: GooglePlaySVG,
  },
  {
    id: 4,
    title: 'Facebook',
    SVGIcon: FacebookSVG,
  },
  {
    id: 5,
    title: 'Google Analytics',
    SVGIcon: AnalyticsSVG,
  },
  {
    id: 6,
    title: 'Stripe',
    SVGIcon: StripeSVG,
  },
]

const HomePageView = ({ settings: { isDarkMode } }: AppProps) => {
  const navigate = useNavigate()

  const onGetInTouchButtonClick = () => {
    navigate(urls.ONBOARDING_COMPANY_SIZE_VIEW)
  }

  return (
    <>
      <HeroSection>
        <HeroTitle>No code full-stack data platform</HeroTitle>
        <PageSubtitle centered>Klarly empowers non-technical teams to become data-driven.</PageSubtitle>
        <PrimaryButton className="mb-3" onClick={onGetInTouchButtonClick}>
          Get in touch
        </PrimaryButton>
        <CopyText12Pt className="mb-6">*No credit card required</CopyText12Pt>
        <img className="hero__image" src={makeCDNLink('prelogin/home/hero.webp')} alt="Klarly" />
      </HeroSection>

      <section>
        <PageTitle className="page-title mb-5 page-title--centered">Companies that trust Klarly</PageTitle>

        <div className="company-list">
          {COMPANY_LIST.map((company) => (
            <div
              className={`company-list__item ${company.name === 'Bulldog Mindset' && 'company-list__item--bulldog'}`}
              key={`company-list-entry${company.id}`}
            >
              <img src={isDarkMode ? company.imageInverted : company.image} alt={company.name} />
            </div>
          ))}
        </div>
      </section>

      <section className="itcv">
        {ITCV.map(({ id, title, image, description }) => (
          <div className="itcv-item" key={`itcv-item-${id}`}>
            <div className="itcv-item__info">
              <PageTitle>{title}</PageTitle>
              <CopyText16Pt>{description}</CopyText16Pt>
            </div>

            <div className="itcv-item__image-wrapper">
              <img className="itcv-item__image" src={image} alt="Klarly" />
            </div>
          </div>
        ))}
      </section>

      <section className="ds has-text-centered">
        <PageTitle centered className="mb-5">
          Over 300+ data connections and counting.
        </PageTitle>
        <PageSubtitle centered>Centralise all data from marketing to offline costs.</PageSubtitle>

        <div className="ds-list">
          {DATA_SOURCES.map(({ id, SVGIcon }) => {
            return (
              <div key={`ds-item-${id}`} className="ds-list-item">
                <div className="ds-list-item__svg__wrapper">
                  <SVGIcon className="ds-list-item__svg" />
                </div>
              </div>
            )
          })}
        </div>
      </section>

      <section className="automation">
        <PageTitle centered className="mb-5">
          Klarly automates the entire data pipeline
        </PageTitle>
        <PageSubtitle centered>
          Deploy Klarly with No Code and No tech team. Klarly integrates & stores all data sources, and delivers ready-to-consume dashboards & reports.
        </PageSubtitle>
      </section>

      <section className="quote">
        <h3 className="quote__text">&ldquo;Klarly is our automated Business Intelligence team.&rdquo;</h3>
        <img className="quote__image" src={makeCDNLink('prelogin/home/testimonial.webp')} alt="Quote from someone using Klarly" />
        <PageSubtitle centered>Stephan Hauner, Mindshine GmbH</PageSubtitle>
      </section>

      <GetInTouch onButtonClick={onGetInTouchButtonClick} />
    </>
  )
}

export default withRedux(HomePageView)
