import { Helmet } from 'react-helmet-async'

import CodeBlock from '../../../components/CodeBlock/CodeBlock'
import { CopyText16Pt } from '../../../components/Text/CopyText16Pt/CopyText16Pt'
import ViewSubtitle from '../../../components/Text/ViewSubtitle/ViewSubtitle'
import ViewTitle from '../../../components/Text/ViewTitle/ViewTitle'

const API_CALL_EXAMPLE = 'https://viz.klarly.com/?key=your-api-key'

const exampleJson = `{
  "status": 200,
  "data": "<klarly-viz id=\\"klarly-viz\\" src=\\"https://reporting.klarly.com/your-report-example\\" hide-tabs height=\\"950\\" />"
}`

const exampleReact = `
const ReportComponent = () => {
  const [klarlyResponse, setKlarlyResponse] = useState()

  useEffect(() => {
    fetchKlarlyAPIReport()
  }, [])

  const fetchKlarlyAPIReport = async () => {
    try {
      const response = await fetch('${API_CALL_EXAMPLE}')
      const { data } = await response.json()
      setKlarlyResponse(data)
    } catch (e) {
      throw new Error('There was an error receiving the report.')
    }
  }

  return (
    <section className="klarly-report">
      <h3>My Report</h3>
      {klarlyResponse && <div dangerouslySetInnerHTML={{ __html: klarlyResponse }} />}
    </section>
  )
}
`

const UserTableauDocumentation = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <ViewTitle>Documentation</ViewTitle>
      <ViewSubtitle>How to include your Klarly Report</ViewSubtitle>

      <ViewSubtitle>1. Step: include the JavaScript</ViewSubtitle>
      <CopyText16Pt>
        First of all, you will need to include a certain JavaScript file, that will handle the rendering of the given report. The file is hosted safely on our
        servers and does not contain any harmful code. You have to include the JavaScript file directly in the <pre>&lt;head&gt;</pre> of your application.
      </CopyText16Pt>

      <CopyText16Pt>
        You must ensure, that the script is fully loaded <strong>before</strong> you render the report. Otherwise, it will not work.
      </CopyText16Pt>

      <CodeBlock code={'<script type="module" src="https://static.klarly.com/third-party-js/klarly-viz.min.js"></script>'} language="markup" />

      <ViewSubtitle>2. Step: API call</ViewSubtitle>
      <CopyText16Pt className="mb-2">
        <strong>Endpoint:</strong>
      </CopyText16Pt>
      <CodeBlock code={API_CALL_EXAMPLE} language="markup" className="mb-4" />

      <CopyText16Pt>
        After you have included the <pre>&lt;script&gt;</pre> tag in your website, you have to make an API call to our servers. You have to append your API Key
        as a query parameter to the URL. That API call will return a prepared HTML block for you. That way, it is easy for you to include your reports on any
        website you want. Please mind the response. Example:
      </CopyText16Pt>

      <CodeBlock code={exampleJson} language="json" />

      <ViewSubtitle>3. Render the report</ViewSubtitle>
      <CopyText16Pt>
        Looking at the API response, you can find the <pre>data</pre> attribute in the servers response. Depending on your project, you can simply render the
        content into your application. All the information needed for the report is already part of the API response.
      </CopyText16Pt>

      <CopyText16Pt>Here are some links providing you help for rendering a string into your application:</CopyText16Pt>

      <ul>
        <li>
          Vanilla JavaScript:{' '}
          <a className="link" href="https://grrr.tech/posts/create-dom-node-from-html-string" target="_blank" rel="noopener noreferrer">
            Blog post on grrr.tech
          </a>
        </li>
        <li>
          React:{' '}
          <a className="link" href="https://reactjs.org/docs/dom-elements.html" target="_blank" rel="noopener noreferrer">
            reactjs.org docs
          </a>
        </li>
        <li>
          React:{' '}
          <a className="link" href="https://www.npmjs.com/package/html-react-parser" target="_blank" rel="noopener noreferrer">
            html-react-parser package
          </a>
        </li>
        <li>
          Vue.js:{' '}
          <a className="link" href="https://vuejs.org/api/built-in-directives.html#v-html" target="_blank" rel="noopener noreferrer">
            v-html directive
          </a>
        </li>
        <li>
          AngularJS:{' '}
          <a className="link" href="https://docs.angularjs.org/api/ng/directive/ngBindHtml" target="_blank" rel="noopener noreferrer">
            ngBindHtml docs
          </a>
        </li>
      </ul>

      <ViewSubtitle>Example with React.js</ViewSubtitle>
      <CodeBlock code={exampleReact} language="jsx" />
    </>
  )
}

export default UserTableauDocumentation
