import './_copyrightText.scss'

import { FunctionComponent, HTMLAttributes } from 'react'

import joinCssClassNames from '../../../modules/joinCssClassNames'

export const CopyrightText: FunctionComponent<HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...rest }) => (
  <p className={joinCssClassNames('copyright-text', className)} {...rest}>
    {children}
  </p>
)
