import ViewTitle from '../../../components/Text/ViewTitle/ViewTitle'
import withAuthentication from '../../../hoc/withAuthentication'
import withRedux from '../../../hoc/withRedux'

const ContactView = () => {
  return (
    <section className="contact">
      <ViewTitle>Contact View</ViewTitle>
    </section>
  )
}

export default withRedux(withAuthentication(ContactView))
