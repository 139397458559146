import _snakeCase from 'lodash/snakeCase'
import { useEffect } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'

import { ReactComponent as BinDeleteSvg } from '../../../assets/svg/bin-delete.svg'
import { ApiPayloadNewConnection } from '../../../types/APITypes/APIPayloadNewConnection'
import { NewProviderConnectionProps } from '../../../types/NewProviderConnectionProps'
import { KlarlyFormInput } from '../../FormElements/ReactHookForm/KlarlyFormInput'
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton'

const NewGoogleAdsConnection = ({ onSave, provider }: NewProviderConnectionProps) => {
  const MULTI_ENTRIES_REACT_FORM_NAME = `${_snakeCase(provider.name)}_multiString`

  const multiEntryConfigs = provider.setup.filter((config) => config.type.name === 'MULTIPLE_ENTRIES')
  const multiEntryName = multiEntryConfigs[0]?.name

  const { register, handleSubmit, getValues, control, reset } = useForm({
    defaultValues: {
      [MULTI_ENTRIES_REACT_FORM_NAME]: [{ [multiEntryName]: '', id: null }],
    },
  })

  useEffect(() => {
    // we must reset the fields, otherwise, if this component is loaded with another provider,
    // the field values of the previously filled fields are still stored
    reset()
  }, [provider])

  const { fields, append, remove } = useFieldArray({
    control,
    name: MULTI_ENTRIES_REACT_FORM_NAME, // unique name for the field array
  })

  const onFormSubmit: SubmitHandler<any> = (data, event) => {
    event?.preventDefault()
  }

  const onDeleteMultiEntryClick = (index: number) => remove(index)

  const onAddAnotherEntryClick = () => {
    append({ id: null })
  }

  const onSaveClick = () => {
    const multiEntryKeyId = multiEntryConfigs[0]?.id
    const multiEntryType = multiEntryConfigs[0]?.type

    const values = getValues(MULTI_ENTRIES_REACT_FORM_NAME).map((entry) => ({
      configurationKeyId: multiEntryKeyId,
      type: multiEntryType,
      value: entry[multiEntryName],
    }))

    const payload: ApiPayloadNewConnection = {
      providerId: provider.id,
      // @ts-ignore
      values,
    }

    onSave(payload)
  }

  return (
    <form className="existing-connections__form" onSubmit={handleSubmit(onFormSubmit)}>
      <p className="mb-3">
        <strong>{provider.name}</strong>
      </p>

      {fields.map(({ id }, index) => (
        <KlarlyFormInput
          key={`form-data-${id}`}
          id={`lbl-${id}-${multiEntryConfigs[0].id}`}
          name={`${MULTI_ENTRIES_REACT_FORM_NAME}.${index}.${multiEntryName}`}
          label={multiEntryConfigs[0].label}
          register={register}
          rules={{ required: 'You must enter this.' }}
        />
      ))}

      <div style={{ marginTop: '1rem', marginBottom: '2rem' }}>
        <PrimaryButton tiny onClick={onAddAnotherEntryClick}>
          Add {multiEntryConfigs[0].label}
        </PrimaryButton>
      </div>

      <PrimaryButton tiny onClick={onSaveClick}>
        Save new Connection
      </PrimaryButton>
    </form>
  )
}

export default NewGoogleAdsConnection
