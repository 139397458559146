import './_logo.scss'

import { FC, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

import { urls } from '../../../config/constants'
import joinCssClassNames from '../../../modules/joinCssClassNames'

export const Logo: FC<HTMLAttributes<HTMLHeadingElement>> = ({ className, ...rest }) => {
  const classNames = joinCssClassNames('logo', className)

  return (
    <Link to={urls.HOME_VIEW} className="logo__link" title="Klarly Website">
      <h1 className={classNames} {...rest}>
        Klarly
      </h1>
    </Link>
  )
}
