import './_loginView.scss'

import { ChangeEvent, SyntheticEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import Input from '../../../components/FormElements/Input'
import { ErrorMessage } from '../../../components/Messages/Messages'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { GENERIC_ERROR_MESSAGE, POST_LOGIN_LANDING_VIEW, urls } from '../../../config/constants'
import { userService } from '../../../services/UserService'

export const LoginView = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const onInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    if (name === 'email') {
      setEmail(value)
    }

    if (name === 'password') {
      setPassword(value)
    }
  }

  const onClearButtonClick = (e: SyntheticEvent) => {
    e.preventDefault()

    setEmail('')
    setPassword('')
    setError(null)
  }

  const onLoginButtonClick = async (e: SyntheticEvent) => {
    e.preventDefault()

    setIsLoading(true)
    setError(null)

    try {
      await userService.signIn({ password, username: email })
      navigate(POST_LOGIN_LANDING_VIEW)
    } catch (e: any) {
      if (e && e.message) {
        setError(e.message)
      } else {
        setError(GENERIC_ERROR_MESSAGE)
      }
    }

    setIsLoading(false)
  }

  const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      await onLoginButtonClick(event)
    }
  }

  return (
    <ElementContainer>
      <h3 className="title has-text-centered">Welcome back!</h3>

      <fieldset className="login-fieldset">
        <div className="login-fieldset__element">
          <Input id="email" type="email" value={email} required placeholder="Enter email..." label="Email" onChange={onInputChange} />
        </div>

        <div className="login-fieldset__element">
          <Input
            id="password"
            type="password"
            value={password}
            required
            placeholder="Enter password..."
            label="Password"
            onChange={onInputChange}
            onKeyPress={onKeyDown}
          />
        </div>

        {error && <ErrorMessage message={error} />}

        <div className="button-line">
          <PrimaryButton medium onClick={onLoginButtonClick} disabled={isLoading}>
            Login
          </PrimaryButton>

          <PrimaryButton medium variation="secondary" onClick={onClearButtonClick} disabled={isLoading}>
            Clear
          </PrimaryButton>
        </div>

        <div className="pw-reset pw-reset__wrapper">
          <Link to={urls.ONBOARDING_COMPANY_SIZE_VIEW}>Sign up for free</Link>
          <div className="pw-reset__divider">|</div>
          <Link to={urls.REQUEST_PASSWORD_RESET_VIEW}>Forgot your Password?</Link>
        </div>
      </fieldset>
    </ElementContainer>
  )
}
