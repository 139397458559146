import './_primaryButton.scss'

import { ButtonHTMLAttributes, FunctionComponent } from 'react'

import joinCssClassNames from '../../modules/joinCssClassNames'

type OwnProps = {
  variation?: 'primary' | 'secondary' | 'tertiary'
  small?: boolean
  medium?: boolean
  tiny?: boolean
}

type Props = ButtonHTMLAttributes<HTMLButtonElement> & OwnProps

export const PrimaryButton: FunctionComponent<Props> = ({
  medium = false,
  small = false,
  tiny = false,
  className,
  variation = 'primary',
  children,
  ...rest
}) => {
  const variationClass = variation ? `button--${variation}` : ''
  const smallClass = small ? 'button--small' : ''
  const mediumClass = medium ? 'button--medium' : ''
  const tinyClass = tiny ? 'button--tiny' : ''
  const classNames = joinCssClassNames('button', variationClass, smallClass, mediumClass, tinyClass, className)

  return (
    <button className={classNames} {...rest}>
      {children}
    </button>
  )
}
