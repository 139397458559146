import './_copyText16Pt.scss'

import classNames from 'classnames'
import { FunctionComponent, HTMLAttributes } from 'react'

export const CopyText16Pt: FunctionComponent<HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...rest }) => (
  <p className={classNames('copy-text-16', className)} {...rest}>
    {children}
  </p>
)
