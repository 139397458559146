import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import HubSpotCalendarWidget from '../../../components/HubSpotCalendarWidget/HubSpotCalendarWidget'
import { OnboardingSubtitle } from '../../../components/Onboarding/OnboardingSubtitle/OnboardingSubtitle'
import { OnboardingTitle } from '../../../components/Onboarding/OnboardingTitle/OnboardingTitle'
import { CopyText12Pt } from '../../../components/Text/CopyText12Pt/CopyText12Pt'
import { getOnboardingContactData } from '../../../modules/onboardingDataHandler'

const OnboardingContactConfirmationMVPView = () => {
  const { firstName, lastName, email } = getOnboardingContactData()

  return (
    <ElementContainer>
      <div className="ob-progress">
        <CopyText12Pt>Step</CopyText12Pt>
      </div>

      <OnboardingTitle>Thank you for getting in touch ✌️</OnboardingTitle>
      <OnboardingSubtitle>
        We will reach out to you in the next 24 hours. If you know when you would like to chat with us, then feel free to book an appointment right away.
      </OnboardingSubtitle>

      <HubSpotCalendarWidget email={email || 'unknown'} firstName={firstName || 'unknown'} lastName={lastName || 'unknown'} />
    </ElementContainer>
  )
}

export default OnboardingContactConfirmationMVPView
