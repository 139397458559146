import _remove from 'lodash/remove'
import _some from 'lodash/some'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as PlatformIcon1 } from '../../../assets/svg/platform-1.svg'
import { ReactComponent as PlatformIcon2 } from '../../../assets/svg/platform-2.svg'
import { ReactComponent as PlatformIcon3 } from '../../../assets/svg/platform-3.svg'
import { ElementContainer } from '../../../components/ElementContainter/ElementContainer'
import { OnboardingTitle } from '../../../components/Onboarding/OnboardingTitle/OnboardingTitle'
import OnboardingBackButton from '../../../components/OnboardingBackButton/OnboardingBackButton'
import OnboardingButton from '../../../components/OnboardingButton/OnboardingButton'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { CopyText12Pt } from '../../../components/Text/CopyText12Pt/CopyText12Pt'
import { ONBOARDING_PLATFORMS_VIEW } from '../../../config/constants'
import { getNextOnboardingViewUrl, getOnboardingViewProgress } from '../../../modules/getNextOnboardingViewUrl'
import { getOnboardingDataByViewName, setOnboardingDataByViewName } from '../../../modules/onboardingDataHandler'
import { OnboardingData } from '../../../types/OnboardingData'

const OPTIONS: OnboardingData[] = [
  { id: 1, label: 'Web', value: 1 },
  { id: 2, label: 'iOS', value: 2 },
  { id: 3, label: 'Android', value: 3 },
]

const ICONS = [PlatformIcon1, PlatformIcon2, PlatformIcon3]

const OnboardingPlatformsView = () => {
  const navigate = useNavigate()
  const viewName = ONBOARDING_PLATFORMS_VIEW
  const [activeOptions, setActiveOptions] = useState<[] | OnboardingData[]>(getOnboardingDataByViewName(viewName) || [])

  const onOptionClick = (option: OnboardingData) => {
    const isActiveOption = _some(activeOptions, { id: option.id })
    const newActiveOptions: OnboardingData[] = activeOptions

    // remove the option
    if (isActiveOption) {
      _remove(newActiveOptions, { id: option.id })
    } else {
      newActiveOptions.push(option)
    }

    // we have to use the spread operator here, otherwise the state updates won't be reflected immediately
    setActiveOptions([...newActiveOptions])
    setOnboardingDataByViewName(viewName, newActiveOptions)
  }

  const goToNextStep = () => {
    const nextOnboardingUrl = getNextOnboardingViewUrl(viewName)
    navigate(nextOnboardingUrl)
  }

  const isContinueDisabled = () => {
    return activeOptions.length === 0
  }

  return (
    <ElementContainer>
      <div className="ob-progress">
        <CopyText12Pt>{getOnboardingViewProgress(viewName)}</CopyText12Pt>
      </div>

      <OnboardingTitle>Select the platforms you are active on.</OnboardingTitle>

      <section className="ob-options">
        {OPTIONS.map((option) => (
          <OnboardingButton
            key={`option-id-${option.id}`}
            onClick={() => onOptionClick(option)}
            option={option}
            isActive={_some(activeOptions, { id: option.id })}
            icon={ICONS[option.value - 1]}
          />
        ))}
      </section>

      <div className="ob-cta-line">
        <OnboardingBackButton />

        <PrimaryButton disabled={isContinueDisabled()} medium onClick={goToNextStep}>
          Continue
        </PrimaryButton>
      </div>
    </ElementContainer>
  )
}

export default OnboardingPlatformsView
