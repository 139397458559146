import './_dashboards-overview.scss'

import { Link } from 'react-router-dom'

import withRedux from '../../hoc/withRedux'
import { AppProps } from '../../types/AppProps'
import { CopyText16Pt } from '../Text/CopyText16Pt/CopyText16Pt'

type OwnProps = {
  showViewLink?: boolean
  showConfigurationLink?: boolean
  useConfigLinkForThumbnail?: boolean
} & AppProps

const DashboardsOverview = ({ user, showConfigurationLink = true, showViewLink = true, useConfigLinkForThumbnail = false }: OwnProps) => {
  const { project } = user
  const showLinkBar = showViewLink || showConfigurationLink

  if (!project) {
    return <CopyText16Pt>There are no projects available for you at the moment.</CopyText16Pt>
  }

  return (
    <section className="dashboard-list">
      {project.dashboards.map((dashboard) => {
        const linkConfiguration = `/app/dashboard/configuration/${dashboard.externalId}`
        const linkThumbnail = useConfigLinkForThumbnail ? linkConfiguration : `/app/dashboard/${dashboard.externalId}`

        return (
          <div className="dashboard-item" key={`dashboard-link-${dashboard.externalId}`}>
            <Link to={linkThumbnail} className="dashboard-item__wrapper" style={{ backgroundImage: `url(${dashboard.image})` }}>
              <article className="dashboard-item__title-wrapper">
                <h4 className="dashboard-item__title">{dashboard.name}</h4>
              </article>
            </Link>

            {showLinkBar && (
              <div className="dashboard-item__links">
                {showViewLink && (
                  <Link className="dashboard-item__links__link" to={linkThumbnail}>
                    View
                  </Link>
                )}

                {showConfigurationLink && (
                  <Link className="dashboard-item__links__link" to={linkConfiguration}>
                    Configure
                  </Link>
                )}
              </div>
            )}
          </div>
        )
      })}
    </section>
  )
}

export default withRedux(DashboardsOverview)
