// @see https://pgarciacamou.medium.com/cant-perform-a-react-state-update-on-an-unmounted-component-a5b012f87f62

import { useCallback, useEffect, useRef } from 'react'

const useMountedState = () => {
  const mountedRef = useRef(false)
  const get = useCallback(() => mountedRef.current, [])

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  return get
}

export default useMountedState
