export class APIServiceError {
  readonly name: string

  readonly key: string

  readonly message: string

  readonly stack: unknown

  readonly statusCode: number

  constructor(key: string, message: string, statusCode?: number) {
    this.name = this.constructor.name
    this.key = key
    this.message = message
    this.stack = new Error(message).stack
    this.statusCode = statusCode || 400
  }
}
