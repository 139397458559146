import { useEffect, useState } from 'react'

import { LoadingAnimation } from '../../../components/LoadingAnimation/LoadingAnimation'
import TableauReport from '../../../components/TableauReport/TableauReport'
import { CopyText16Pt } from '../../../components/Text/CopyText16Pt/CopyText16Pt'
import ViewSubtitle from '../../../components/Text/ViewSubtitle/ViewSubtitle'
import ViewTitle from '../../../components/Text/ViewTitle/ViewTitle'
import { EXAMPLE_REPORTS } from '../../../config/constants'
import withRedux from '../../../hoc/withRedux'
import { apiService } from '../../../services/APIService'
import { AppProps } from '../../../types/AppProps'

const DemoPageReportView = ({ settings: { isDarkMode } }: AppProps) => {
  const [isFetching, setIsFetching] = useState(true)
  const [tableauToken, setTableauToken] = useState('')
  const report = EXAMPLE_REPORTS[0] // use example management report for now

  useEffect(() => {
    fetchTableauToken()
  }, [])

  const fetchTableauToken = async () => {
    setIsFetching(true)
    const { data: token } = await apiService.fetchTableauFreeToken()
    setTableauToken(token)
    setIsFetching(false)
  }

  return (
    <>
      <ViewTitle>Have a look at your report</ViewTitle>

      {!isFetching && (
        <>
          <div className="tableau-wrapper">
            <TableauReport link={isDarkMode ? report.tableauLink : report.tableauLinkWhite} token={tableauToken} />
          </div>

          <CopyText16Pt>
            <code>Some explanations for the report goes here</code>
          </CopyText16Pt>
        </>
      )}
    </>
  )
}

export default withRedux(DemoPageReportView)
