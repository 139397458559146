import { connect } from 'react-redux'

import User from '../../modules/User'
import { ConfigurationsState } from '../../types/redux/ConfigurationsState'
import { SettingsState } from '../../types/redux/SettingsState'
import { UserConfigurationState } from '../../types/redux/UserConfigurationState'
import { configurationsActionCreators, reducer as configurationReducer } from './configurations'
import { reducer as settingsReducer, settingsActionCreators } from './settings'
import { reducer as userReducer, userActionCreators } from './user'
import { reducer as userConfigurationReducer, userConfigurationActionCreators } from './userConfiguration'

const reduxModules = [
  {
    key: 'configurations',
    reducer: configurationReducer,
  },
  {
    key: 'settings',
    reducer: settingsReducer,
  },
  {
    key: 'user',
    reducer: userReducer,
  },
  {
    key: 'userConfiguration',
    reducer: userConfigurationReducer,
  },
]

export interface RootState {
  configurations: ConfigurationsState
  settings: SettingsState
  user: User
  userConfiguration: UserConfigurationState
}

const mapStateToProps = (props: RootState) => ({
  configurations: props.configurations,
  settings: props.settings,
  user: props.user,
  userConfiguration: props.userConfiguration,
})

export function connectReduxModules() {
  return connect(mapStateToProps, {
    ...configurationsActionCreators,
    ...settingsActionCreators,
    ...userActionCreators,
    ...userConfigurationActionCreators,
  })
}

export default reduxModules
