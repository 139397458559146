import User from '../../../modules/User'
import { apiService } from '../../../services/APIService'

export const USER_RECEIVED = 'user/USER_RECEIVED'

const onUserReceived = (payload: User) => ({ type: USER_RECEIVED, payload })

export const userActionCreators = {
  fetchMe: () => async (dispatch: any) => {
    try {
      const user = await apiService.fetchMe()
      dispatch(onUserReceived(user))
      return user
    } catch (e) {
      throw new Error('User is not authorized')
    }
  },
  onUserReceived,
}
